<template>
  <div>
    <b-nav fill :vertical="vertical">
      <b-nav-item
        v-for="item in quantity"
        :key="item"
        :class="
          vertical
            ? 'vertical-height'
            : item == 1
            ? 'text-left horizontal-height'
            : item == quantity
            ? 'text-right horizontal-height'
            : 'horizontal-height'
        "
        link-classes="p-0"
        disabled
      >
        <b-row>
          <b-col :class="vertical ? 'col-2 px-0 text-right' : ''">
            <b-avatar
              :class="[
                vertical
                  ? getStep >= item
                    ? `bg-${
                        content[item - 1].title == 'Anulado'
                          ? content[item - 1].status + ' text-white'
                          : 'stepper text-dark'
                      } `
                    : `bg-${
                        content[item - 1].status != 'primary'
                          ? content[item - 1].status
                          : 'primary'
                      }`
                  : getStep >= item
                  ? 'bg-primary bottom-avatar'
                  : 'bg-stepper text-dark bottom-avatar',
              ]"
            >
              <font-awesome-icon
                :class="[
                  vertical
                    ? getStep >= item && content[item - 1].title != 'Anulado'
                      ? 'd-none'
                      : 'd-block'
                    : getStep <= item
                    ? 'd-none'
                    : 'd-block',
                ]"
                size="lg"
                :icon="[
                  'fas',
                  vertical
                    ? content[item - 1].status == 'primary'
                      ? 'check'
                      : content[item - 1].status == 'danger'
                      ? 'xmark-large'
                      : 'exclamation'
                    : 'check',
                ]"
              ></font-awesome-icon>
              <h5
                class="mb-0"
                :class="[
                  vertical
                    ? getStep >= item && content[item - 1].title != 'Anulado'
                      ? 'd-block'
                      : 'd-none'
                    : getStep <= item
                    ? 'd-block'
                    : 'd-none',
                ]"
              >
                {{ vertical ? quantity + (1 - item) : item }}
              </h5>
            </b-avatar>
            <div
              :class="[
                vertical
                  ? item != quantity
                    ? 'vertical-line'
                    : ''
                  : item == 1
                  ? 'left-line'
                  : item == quantity
                  ? 'right-line'
                  : 'separator-line',
              ]"
            ></div>
          </b-col>
          <b-col :class="[vertical ? 'd-block' : 'd-none']" class="pr-0">
            <slot name="content">
              <h5 class="text-dark">{{ content[item - 1].title }}</h5>
              <p class="text-muted">{{ content[item - 1].date }}</p>
            </slot>
          </b-col>
        </b-row>
      </b-nav-item>
    </b-nav>
    <slot name="subtitle" :step="getStep"> </slot>
    <b-card :class="vertical ? 'd-none' : 'd-block'">
      <slot name="panel" :step="getStep"> </slot>
    </b-card>

  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Stepper",
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: Number,
      default: 4,
      required: true,
    },
    content: {
      type: Array,
      default() {
        return [
          {
            title: "Paso Ultimo",
            date: "Fecha estimada: 06-03-2022",
          },
          {
            title: "Paso Penultimo",
            date: "02-03-2022",
          },
          {
            title: "Paso Segundo",
            date: "01-03-2022",
          },
          {
            title: "Paso Primero",
            date: "02-03-2022",
          },
        ];
      },
    },
  },
  data() {
    return {
      isAdmin: true,
    };
  },
  computed: {
    ...mapGetters(["getStep"]),
  },
  mounted() {
    this.$store.commit("restoreStep");
  },
  
  methods: {
    ...mapMutations(["decrementStep", "isFinalStep"]),
    incrementStep() {
      this.$store.commit("incrementStep", this.getStep);
    },
  },
};
</script>
