<template>
  <b-list-group-item
    button
    @click="showRequest"
  >
    <b-row>
      <b-col>
        <h6 class="mb-0">{{ name }}</h6>
      </b-col>
      <b-col class="text-center">
        <p class="text-muted mb-0">Solicitado el {{ createdAt }}</p>
      </b-col>
      <b-col class="text-right">
        <b-badge v-if="isFinished" variant="success" pill>Finalizada</b-badge>
        <b-badge v-else pill>{{ readyDocuments }} / {{ totalDocuments }} documentos</b-badge>
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
export default {
  name: "AdminCardRequest",
  props: {
    requestId: { type: Number, required: true },
    totalDocuments: { type: Number, required: true },
    readyDocuments: { type: Number, required: true },
    name: { type: String, required: true },
    status: { type: String, required: true },
    createdAt: { type: String, required: true },
    payment: { type: Object },
    user: { type: Object },
    property: { type: Object }, 
    simple_documents: { type: Array }
  },
  computed: {
    isFinished() {
      return this.status === 'finalizado' ? true : false;
    }
  },
  methods: {
    showRequest() {
      this.$store.commit("setSelectedAdminRequest", {
        id: this.requestId,
        name: this.name,
        totalDocuments: this.totalDocuments,
        readyDocuments: this.readyDocuments,
        status: this.status,
        createdAt: this.createdAt,
        payment: this.payment,
        user: this.user,
        property: this.property,
        simple_documents: this.simple_documents, 
      });
      this.$router.push({
        name: "AdminRequestDetail", 
      });
    },
  },
};
</script>
