<template>
  <b-form @submit="submitForm">
    <!-- <b-card class="mb-3">
      <b-media>
        <template #aside>
          <b-avatar :size="avatarSize" :text="getInitialsName" />
        </template>
        <h6 class="mt-0">Tu foto de perfil</h6>
        <b-button variant="outline-dark">Actualizar</b-button>
      </b-media>
    </b-card> -->
    <b-card class="mb-3">
      <b-row>
        <b-col sm="12" lg="12">
          <b-form-group
            id="input-group-1"
            label="Nombre"
            label-for="name-input"
            class="font-weight-bold"
          >
            <b-form-input
              id="name-input"
              v-model="form.name"
              type="text"
              required
              size="lg"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" lg="12">
          <b-form-group
            id="input-group-2"
            label="Apellido"
            label-for="lastName-input"
            class="font-weight-bold"
          >
            <b-form-input
              id="lastName-input"
              v-model="form.lastName"
              required
              size="lg"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" lg="12">
          <b-form-group
            id="input-group-3"
            label="Correo electrónico"
            label-for="email-input"
            class="font-weight-bold"
          >
            <b-form-input
              id="email-input"
              v-model="form.email"
              type="email"
              required
              size="lg"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" lg="12">
          <b-form-group
            id="input-group-4"
            label="Número de teléfono"
            label-for="phone-input"
            class="font-weight-bold"
          >
            <b-form-input
              id="phone-input"
              v-model="form.phone"
              required
              size="lg"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button variant="primary" size="lg" type="submit"
          >Guardar cambios</b-button
        >
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapGetters } from "vuex";

import Config from "@/../config.json";

import axios from "axios";
import login from "@/services/login.service";

export default {
  name: "FormProfile",
  data() {
    return {
      avatarSize: "72px",
      form: {
        name: null,
        lastName: null,
        email: null,
        phone: null,
      },
    };
  },
  computed: {
    ...mapGetters(["getInitialsName"]),
  },
  async beforeMount() {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);

    const { data } = await axios.post(
      Config.VUE_APP_URL_FIND_USER_BY_ID,
      {
        id: this.$store.getters.getUserData.id,
      },
      {
        headers: {
          Authorization: `Bearer ${mytoken}`
        }
      }
    );

    this.form.name = data.content?.content?.name;
    this.form.lastName = data.content?.content?.lastName;
    this.form.email = data.content?.content?.email;
    this.form.phone = data.content?.content?.phone;
  },

  methods: {
    async submitForm(event) {
      try {
        event.preventDefault();

        let mytoken = localStorage.getItem("token");
        login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
        console.log("mytoken: " + mytoken);

        await axios.patch(
          Config.VUE_APP_URL_UPDATE_PROFILE,
          {
            id: this.$store.getters.getUserData.id,
            name: this.form.name,
            lastName: this.form.lastName,
            phone: this.form.phone,
          },
          {
            headers: {
              Authorization: `Bearer ${mytoken}`
            }
          }
        );

        this.$store.commit("setUserData", {
          id: this.$store.getters.getUserData.id,
          firstName: this.form.name,
          lastName: this.form.lastName,
          role: this.$store.getters.getUserData.role,
          phone: this.form.phone,
          email: this.$store.getters.getUserData.email,
        });

        this.$bvToast.toast(`Tu perfil ha sido actualizado exitosamente.`, {
          title: "¡Listo!",
          solid: true,
          variant: "success",
          autoHideDelay: 5000,
        });
      } catch (error) {
        this.$bvToast.toast(
          `No ha sido posible actualizar tu perfil. Por favor inténtalo más tarde.`,
          {
            title: "Hay un problema",
            solid: true,
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
      }
    },
  },
};
</script>
