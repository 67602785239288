<template>
  <b-container>
    <b-row align-h="center">
      <b-col cols="8" md="6" lg="5" class="text-center">
        <img
          :src="require(`../../assets/img-empty.svg`)"
          class="d-block mx-auto mb-4 img-fluid"
          alt=""
        />
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col class="text-center" cols="10" lg="9">
        <h4>¡Oops!</h4>
        <p class="text-muted my-3">
          No pudimos encontrar resultados para la propiedad que buscabas.
          Inténtalo nuevamente
        </p>
        <b-button variant="primary" size="lg" @click="goToRolSearch">
          Volver al buscador
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EmptyRolSearch",
  computed: {
    ...mapGetters(["setStep"]),
  },
  methods: {
    goToRolSearch() {
      this.$store.commit("setStep", 2);
    },
    goToPropertiesList() {
      this.$router.push({
        path: "/corredor/propiedades",
      });
    },
  },
};
</script>
