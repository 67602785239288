<template>
  <b-modal
    id="modal-upload"
    title="Sube un documento"
    centered
    header-class="justify-content-center"
    footer-border-variant="white"
  >
    <b-form class="px-4">
      <b-form-group id="file-name" label="Nombre archivo" label-for="file-name">
        <b-form-input
          id="file-name"
          v-model="name"
          type="text"
          placeholder="Ingresa un nombre"
          size="lg"
        />
      </b-form-group>
      <b-form-group
        id="folders-input"
        label="Asignar carpeta"
        label-for="folders-input"
      >
        <b-form-select
          id="folders-input"
          v-model="selected"
          :options="foldersOptions"
          size="lg"
        />
      </b-form-group>
      <b-form-group label="Sube tu archivo" class="form-file-custom">
        <b-form-file
          id="file"
          ref="file"
          v-model="file1"
          type="file"
          placeholder="Selecciona un archivo"
          drop-placeholder="Coloca tu archivo aquí..."
          size="lg"
          browse-text="Buscar"
        />
        <b-form-text
          >El tamaño máximo del archivo es de 10 mb. Sólo podrás previsualizar
          archivos en formato PDF.</b-form-text
        >
      </b-form-group>
    </b-form>
    <template #modal-footer>
      <b-button variant="outline-primary" size="lg" @click="cancel()">
        Cancelar
      </b-button>
      <b-button variant="primary" size="lg" @click="submitFile()">
        Guardar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";
import Config from "@/../config.json";
import login from "@/services/login.service";

export default {
  name: "ModalUpload",
  data() {
    return {
      name: "",
      file1: null,
      file: null,
      selected: null,
      foldersOptions: [
        { text: "Selecciona una carpeta", value: null },
        "Propietarios",
        "Legales",
        "Postventa",
        "Comerciales",
        "Otros",
      ],
    };
  },
  methods: {
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    },
    async submitFile() {
      let mytoken = localStorage.getItem("token");
      login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
      console.log("mytoken: " + mytoken);
      if (
        this.file1 !== undefined &&
        this.selected !== null &&
        this.name !== ""
      ) {
        this.$store.commit("setLoading");
        this.$bvModal.hide("modal-upload");

        this.file1 = this.$refs.file.files[0];
        const property = this.$store.getters.getSelectedProperty;
        const fileType = this.file1.type;
        const dataFile = await this.readFileAsync(this.file1);

        axios
          .post(Config.VUE_APP_URL_SAVE_DOCUMENT, {
            property_id: property.id,
            file: { base64: dataFile, type: fileType },
            name: this.name,
            folder: this.selected.toLowerCase(),
          },
          {
            headers: {
              Authorization: `Bearer ${mytoken}`
            }
          }
          )
          .then(() => {
            this.$store.dispatch("fetchDocuments", { propertyId: property.id });
            this.$store.commit("setLoading");

            this.$bvToast.toast("Se guardó el documento con éxito.", {
              title: "¡Listo!",
              solid: true,
              variant: "success",
            });
          })
          .catch((e) => {
            this.$store.commit("setLoading");
            this.$bvToast.toast("No pudimos almacenar tu documento.", {
              title: "¡Hubo un problema!",
              solid: true,
              variant: "danger",
            });
          });
        this.name = "";
        this.selected = null;
        this.file1 = null;
      } else {
        this.$bvToast.toast(
          "No pudimos almacenar tu documento. Prueba rellenando los campos.",
          {
            title: "¡Hubo un problema!",
            solid: true,
            variant: "danger",
          }
        );
      }
    },
    cancel() {
      this.$bvModal.hide("modal-upload");
      this.name = "";
      this.selected = null;
      this.file1 = null;
    },
  },
};
</script>
