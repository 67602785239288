<template>
  <b-container class="mt-5">
    <b-row class="justify-content-center">
      <img src="../../assets/logo-iprop-green.svg" alt="" class="mb-4">
    </b-row>
    <b-row class="justify-content-center">
      <b-col md="8" lg="7" xl="6">
        <b-card body-class="px-5 py-4">
          <title-back back="inicio de sesión" />
          <h2>Recupera tu contraseña</h2>
          <p class="text-muted">Para recuperar tu contraseña, primero ingresa el correo asociado a tu cuenta para continuar.</p>
          <form-recover-password/>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TitleBack from '@/components/misc/TitleBack.vue';
import FormRecoverPassword from '@/components/forms/FormRecoverPassword.vue'

export default {
  name: "RecoverPassword",
  components: {
    TitleBack,
    FormRecoverPassword
  }
}
</script>