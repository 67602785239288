<template>
  <div>
    <h6 class="mb-2">{{ title }}</h6>
    <div class="d-flex">
      <div
        class="progress-bar"
        :class="[`bg-${variant}`]"
        :style="{
          height: `${height}px`,
          width: (items * 100) / quantity + '%',
        }"
      ></div>
      <p class="ml-2" v-if="percentage">
        {{ items }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    percentage: { type: Boolean, required: false },
    height: { type: Number, required: false },
    title: { type: String, required: true },
    variant: { type: String, required: true },
    items: { type: Number, required: true },
    quantity: { type: Number, required: true },
  },
};
</script>
