<template>
  <b-card header-class="bg-white py-3" body-class="px-0" class="mb-4">
    <template #header>
      <header-card
        title="Información Municipal"
        content="Información municipal de tu propiedad"
        icon="landmark-dome"
      />
    </template>
    <div class="title px-3">
      <h5>Características</h5>
    </div>
    <b-list-group class="list-custom-report">
      <list-custom-item label="Destino" :result="siiBdComputed.destino" />
      <list-custom-item
        label="Construcción"
        :result="siiBd.tipo_construccion"
      />
      <list-custom-item
        label="Año construcción"
        :result="siiBd.ano_construccion"
      />
      <list-custom-item
        label="Superficie Terreno"
        :result="siiBdComputed.supTerreno"
      />
      <list-custom-item
        label="Superficie construída"
        :result="siiBd.superficie_construccion + ' m2'"
      />
    </b-list-group>
  </b-card>
</template>

<script>
import HeaderCard from "./HeaderCard.vue";
import ListCustomItem from "./ListCustomItem.vue";

export default {
  name: "MunicipalInfo",
  components: {
    HeaderCard,
    ListCustomItem,
  },
  props: {
    siiBd: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    siiBdComputed() {
      return {
        destino: this.siiBd.codigo_destino == "H" ? "Habitacional" : "",
        supTerreno:
          this.siiBd.superficie_terreno !== 0
            ? `${this.siiBd.superficie_terreno} m2`
            : "No aplica",
      };
    },
  },
};
</script>
