<template>
  <div class="container-fluid p-4">
    <iframe
      :src="infoval"
      frameborder="0"
      style="width: 100%; height: 80vh;"
    ></iframe>
    <div v-if="isTokenValid" class="text-center mt-4">
      <b-button variant="primary" @click="consultarOtraPropiedad">
        Consultar otra propiedad
      </b-button>
    </div>
  </div>
</template>

<script>
import jwtDecode from 'jwt-decode';
import login from "@/services/login.service";
import propertyService from "@/services/property.service"; // Asegúrate de importar tu servicio correctamente

export default {
  name: "InfoCheck",
  data() {
    return {
      infoval: null,
      isTokenValid: false // Inicializa isTokenValid en false
    };
  },
  async beforeMount() {
    const urlParams = new URLSearchParams(window.location.search);
    let mytoken = urlParams.get('token');
    let jsonParam = urlParams.get('json');

    // Validar el token
    if (mytoken) {
      try {
        const decodedToken = jwtDecode(mytoken);
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp >= currentTime) {
          this.isTokenValid = true;
        } else {
          console.log("Token ha expirado");
        }
      } catch (error) {
        console.log("Token inválido");
      }
    } else {
      console.log("No se ha proporcionado un token");
    }

    if (this.isTokenValid) {
      // Convertir jsonParam a un objeto
      let jsonObject;
      try {
        jsonObject = JSON.parse(jsonParam);
      } catch (error) {
        console.log("jsonParam no es un JSON válido");
        return;
      }

      // Extraer rol y comuna
      const { rol, comuna } = jsonObject;

      if (!rol || !comuna) {
        console.log("El JSON no contiene 'rol' o 'comuna'");
        return;
      }

      this.infoval = `https://infovalweb.carpetainmobiliaria.com/property-finder/${mytoken}/${rol}/${comuna}/${jsonParam}`;
      return;
    }

    let mytoken2 = localStorage.getItem("token");
    login.checkToken(mytoken2);
    console.log("mytoken2: " + mytoken2);
    // Si el token no es válido o no viene, ejecuta la lógica existente
    let mybody = {
      token: mytoken2
    };

    try {
      this.$store.commit("setLoading");
      let response = await propertyService.getCreditsStatus(mybody);
      this.$store.commit("setLoading");
      console.log("response", response);

      if (response.informes_disponibles > 0 || response.disponibles_plan > 0) {
        this.infoval = `https://infoval.carpetainmobiliaria.com/property-finder/${mytoken2}`;
      } else {
        this.infoval = "https://infoval.carpetainmobiliaria.com";
      }

      console.log(this.infoval);
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    consultarOtraPropiedad() {
      window.location.href = '/corredor/infocheck';
    }
  }
};
</script>

<style scoped>
/* Puedes agregar estilos adicionales aquí si es necesario */
</style>
