var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasRequests)?_c('div',[_c('h2',{staticClass:"mt-4"},[_vm._v("Solicitudes")]),_c('b-tabs',{staticClass:"mt-3 nav-tab-custom"},[_c('b-tab',{attrs:{"title":"Recibidas","active":""}},[_c('b-list-group',{staticClass:"mt-3"},_vm._l((_vm.receivedRequest),function(req){return _c('admin-card-request',{key:req.id,attrs:{"request-id":req['id'],"total-documents":req.totalDocuments,"ready-documents":req.readyDocuments,"name":req.name,"status":req.status,"created-at":req.createdAt,"payment":req.payment,"user":req.user,"property":req.property,"simple_documents":req.simple_documents}})}),1)],1),_c('b-tab',{attrs:{"title":"Terminadas"}},[_c('b-list-group',{staticClass:"mt-3"},_vm._l((_vm.finishedRequest),function(req){return _c('admin-card-request',{key:req.id,attrs:{"request-id":req['id'],"total-documents":req.totalDocuments,"ready-documents":req.readyDocuments,"name":req.name,"status":req.status,"created-at":req.createdAt,"payment":req.payment,"user":req.user,"property":req.property,"simple_documents":req.simple_documents}})}),1)],1)],1)],1):_c('empty-page',{attrs:{"img":"empty.svg","buttons":[
    {
      caption: 'Solicitar documentos',
      variant: 'primary',
      isModal: false,
      route: 'CreateRequest',
    },
  ]},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',{staticClass:"mb-5 text-left text-md-center pl-2"},[_vm._v("Solicitudes")])]},proxy:true},{key:"subtitle",fn:function(){return [_c('p',{staticClass:"mt-4 col-9 col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto"},[_vm._v(" No hay documentos solicitados de momento, prueba creando una solicitud ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }