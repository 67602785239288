<template>
  <b-container>
    <b-row>
      <div id="map" :style="{ minHeight: minHeight }"></div>
    </b-row>
  </b-container>
</template>

<script>
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

import reportService from "@/services/report.service";

import { mapGetters } from "vuex";

export default {
  name: "MiniMap",
  props: {
    showMarkers: {
      type: Boolean,
      default: true,
    },
    minHeight: {
      type: String,
      default: "500px",
    },
    zoom: {
      type: Number,
      default: 14,
    },
    mainMakerRotation: {
      type: Number,
      default: 0,
    },
    propertyInfo: {
      type: Object,
      default: () => ({
        geometry: { coordinates: [-70.64827, -33.45694] },
        // geometry: null,
      }),
    },
    neighborsToMap: {
      type: Object,
      default() {
        return { type: "FeatureCollection", features: [] };
      },
    },
    neighborsColor: {
      type: String,
      default: "#FFB100",
    },
    showTooltips: {
      type: Boolean,
      default: true,
    },
    isMainMarkerDragable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resumeProperty: null,
      selectedProperty: null,
      reportStatus: null,
    };
  },

  computed: {
    ...mapGetters([
      "getResumeProperty",
      "getReportStatus",
      "getSelectedProperty",
    ]),
  },

  async mounted() {
    this.resumeProperty = this.getResumeProperty;
    this.selectedProperty = this.getSelectedProperty;

    const { data } = await reportService.getReportStatus(
      this.selectedProperty.id
    );
    this.reportStatus = data;

    const mapboxToken =
      "pk.eyJ1Ijoiam9obnBpdHQiLCJhIjoiY2xoNmNkMzJrMDBuaTNybnU3MjdsZXR4aSJ9.jbLX3CSFov-doev26Lc2Aw";

    mapboxgl.accessToken = mapboxToken;

    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/johnpitt/clh6kpvmq01il01qj9nmf5vze",
      center: [
        this.propertyInfo.geometry.coordinates[0],
        this.propertyInfo.geometry.coordinates[1],
      ],
      zoom: this.zoom,
    });

    let geocoder = new MapboxGeocoder({
      accessToken: mapboxToken,
      mapboxgl: mapboxgl,
      collapsed: true,
      placeholder: "Buscar",
      autocomplete: true,
      marker: true,
    });

    //map.addControl(geocoder);
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.FullscreenControl());

    if (this.showMarkers) {
      map.on("load", () => {
        const marker = new mapboxgl.Marker({
          draggable: this.isMainMarkerDragable,
          color: "#ff0000",
          rotation: this.mainMakerRotation,
        })
          .setLngLat([
            this.propertyInfo?.geometry?.coordinates[0],
            this.propertyInfo?.geometry?.coordinates[1],
          ])
          .addTo(map);

        if (this.showTooltips) {
          const popup = new mapboxgl.Popup({
            closeButton: true,
            closeOnClick: true,
          }).setHTML(`
            <div class="container">
              <div class="row mt-1 mb-2">
                <div class="col">
                  <h6>Propiedad:</h6>
                </div>
              </div>
              <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Dirección:</span>
                    <span class="text-muted">
                      ${
                        this.propertyInfo?.properties?.address &&
                        typeof this.propertyInfo?.properties?.address ===
                          "string"
                          ? this.propertyInfo?.properties?.address
                              ?.split(" ")
                              ?.map(
                                (word) =>
                                  word[0]?.toUpperCase() +
                                  word?.toLowerCase()?.substring(1)
                              )
                              ?.join(" ")
                          : "Sin información"
                      }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Comuna:</span>
                    <span class="text-muted">
                      ${
                        this.propertyInfo?.properties?.commune &&
                        typeof this.propertyInfo?.properties?.commune ===
                          "string"
                          ? this.propertyInfo?.properties?.commune
                              ?.split(" ")
                              ?.map(
                                (word) =>
                                  word[0]?.toUpperCase() +
                                  word?.toLowerCase()?.substring(1)
                              )
                              ?.join(" ")
                          : "Sin información"
                      }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Rol:</span>
                    <span class="text-muted">
                      ${
                        this.propertyInfo?.properties?.role &&
                        typeof this.propertyInfo?.properties?.role === "string"
                          ? this.propertyInfo?.properties?.role
                              ?.split(" ")
                              ?.map(
                                (word) =>
                                  word[0]?.toUpperCase() +
                                  word?.toLowerCase().substring(1)
                              )
                              ?.join(" ")
                          : "Sin información"
                      }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Propietario:</span>
                    <span class="text-muted">
                      ${
                        this.reportStatus?.content?.report?.data
                          ?.simpleRealEstate?.propietario &&
                        typeof this.reportStatus?.content?.report?.data
                          ?.simpleRealEstate?.propietario === "string"
                          ? this.reportStatus?.content?.report?.data?.simpleRealEstate?.propietario
                              ?.split(" ")
                              ?.map(
                                (word) =>
                                  word[0]?.toUpperCase() +
                                  word?.toLowerCase()?.substring(1)
                              )
                              ?.join(" ")
                          : "Sin información"
                      }</span>
                  </div>
                </div>
            </div>
            `);

          const markerDiv = marker.getElement();

          markerDiv.addEventListener("click", () => {
            marker.setPopup(popup);
            marker.togglePopup();
          });
        }

        if (this.isMainMarkerDragable) {
          const onDragEnd = () => {
            const lngLat = marker.getLngLat();

            if (this.resumeProperty?.geojson?.coordinates) {
              this.resumeProperty.geojson.coordinates = [
                lngLat.lng,
                lngLat.lat,
              ];

              this.$store.commit("setResumeProperty", this.resumeProperty);
            }
          };

          marker.on("dragend", onDragEnd);
        }

        for (const neighbor of this.neighborsToMap.features) {
          const marker = new mapboxgl.Marker({
            draggable: false,
            color: this.neighborsColor,
          })
            .setLngLat([
              neighbor.geometry.coordinates[0],
              neighbor.geometry.coordinates[1],
            ])
            .addTo(map);

          let formattedUf = null;
          const thousands = 1000;

          typeof neighbor?.properties?.uf === "number" &&
          neighbor?.properties?.uf >= thousands
            ? (formattedUf = (neighbor?.properties?.uf / thousands).toFixed(3))
            : (formattedUf = neighbor?.properties?.uf);

          const address = neighbor?.properties?.direccion ?? "";
          const departament = neighbor?.properties?.depto ?? "";

          const formattedAddress =
            departament === "" ? address : `${address}, ${departament}`;

          if (this.showTooltips) {
            const popup = new mapboxgl.Popup({
              closeButton: true,
              closeOnClick: true,
            }).setHTML(`
              <div class="container">
                <div class="row mt-1">
                  <div class="col">
                    <h6>Propiedad cercana y/o similar:</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Comuna:</span>
                    <span class="text-muted">
                      ${
                        neighbor?.properties?.comuna ?? "Sin información"
                      }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Rol:</span>
                    <span class="text-muted">
                      ${neighbor?.properties?.rol ?? "Sin información"}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Dirección:</span>
                    <span class="text-muted">
                      ${formattedAddress ?? "Sin información"}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Propietario:</span>
                    <span class="text-muted">
                      ${
                        neighbor?.properties?.comprador ?? "Sin información"
                      }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Año construcción:</span>
                    <span class="text-muted">
                    ${
                      neighbor?.properties?.anoConstruccion ?? "Sin información"
                    }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Superficie Útil:</span>
                    <span class="text-muted">
                    ${
                      neighbor?.properties?.supCons
                        ? neighbor?.properties?.supCons + " m²"
                        : "Sin información"
                    }
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Superficie Terreno:</span>
                    <span class="text-muted">
                    ${
                      neighbor?.properties?.sutTTe
                        ? neighbor?.properties?.sutTTe + " m²"
                        : "Sin información"
                    }
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Año Última Transferencia:</span>
                    <span class="text-muted">
                      ${
                        typeof neighbor?.properties?.fecha === "string"
                          ? neighbor?.properties?.fecha
                              ?.split("T")[0]
                              ?.split("-")
                              ?.reverse()
                              ?.join("-")
                          : "Sin información"
                      }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Precio Transf:</span>
                    <span class="text-muted">
                      ${formattedUf ? formattedUf + " UF" : "Sin información"}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Precio UF/m2:</span>
                    <span class="text-muted">
                      ${
                        neighbor?.properties?.ufM2U
                          ? neighbor?.properties?.ufM2U + " UF"
                          : "Sin información"
                      }
                      </span>
                  </div>
                </div>
              </div>
            `);
            const markerDiv = marker.getElement();

            markerDiv.addEventListener("click", () => {
              marker.setPopup(popup);
              marker.togglePopup();
            });
            // markerDiv.addEventListener("mouseenter", () => {
            //   // marker.setPopup(popup);
            //   // marker.togglePopup();
            // });
            // markerDiv.addEventListener("mouseleave", () => {
            //   // marker.togglePopup();
            // });
          }
        }
      });
    }
  },
};
</script>

<style>
#map {
  top: 0%;
  bottom: 0%;
  left: 0%;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;

  border-radius: 0.5rem;
  border: 1px solid #dee2e6;
}

.mapboxgl-popup {
  min-width: 320px;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.mapboxgl-marker {
  cursor: pointer;
}
</style>
