import axios from 'axios';
import Config from "@/../config.json";
import login from "@/services/login.service";

const updatePassword = {
  async newPassword(values) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
    
    try {
      const data = await axios.patch(
        Config.VUE_APP_URL_UPDATE_PASSWORD,
        values,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default updatePassword;
