<template>
  <b-button 
    size="lg"
    class="d-lg-none"
    variant="outline-secondary"
    block
    @click="scrollToTop()"
  >
    <font-awesome-icon :icon="['fas', 'arrow-to-top']" class="mr-2" />
    {{ text }}
  </b-button>
</template>

<script>
export default {
  name: "TopButton",
  props: {
    text: {
      type: String,
      default: 'Volver'
    }
  },
  methods: { 
    scrollToTop() {
      window.scrollTo(0,0);
    }
  }
}
</script>