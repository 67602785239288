<template>
  <div class="mb-2">
    <b-breadcrumb class="mb-2">
      <b-breadcrumb-item
        v-for="(item, key) in items"
        :key="key"
        :to="item.to"
        :active="item.active"
      >
        {{ item.text }}
      </b-breadcrumb-item>
    </b-breadcrumb>
    <a v-if="!items" href="#" @click="navigateTo()">
      <font-awesome-icon icon="fa-solid fa-arrow-left" /> Volver a
      {{ back }}
    </a>
    <slot name="title"></slot>
  </div>
</template>
<script>
export default {
  name: "TitleBack",
  props: {
    title: { type: String, default: "Default title" },
    back: { type: String, default: "Default back location" },
    route: { type: [String, Number], default: -1 },
    items: { type: Array, default: null },
  },
  methods: {
    navigateTo() {
      if (typeof this.route === "number") {
        this.$router.go(this.route);
      }
      if (typeof this.route === "string") {
        this.$router.push(this.route);
      }
    },
  },
};
</script>
