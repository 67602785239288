import axios from "axios";
import Config from "@/../config.json";
import login from "@/services/login.service";

const getPropertyByRole = async (comuna, rol) => {
  let mytoken = localStorage.getItem("token");
  login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
  console.log("mytoken: " + mytoken);

  try {
    const { data } = await axios.get(
      `${Config.VUE_APP_URL_OBTAIN_SIMPLE_DOCUMENTS}?comuna=${comuna}&rol=${rol}`,
      {
        headers: {
          Authorization: `Bearer ${mytoken}`
        }
      }
    );

    return {
      success: true,
      data,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export default getPropertyByRole;

// const getPropertyByRole = {
//   async obtainSimpleDocuments(values) {
//     const data = await axios.post(
//       Config.VUE_APP_URL_OBTAIN_SIMPLE_DOCUMENTS,
//       values
//       )
//       .catch((err) => { return (err.response.data.message) })
//       ;
//     return data.data.simpleDocuments.content;
//   },

// };
// export default getPropertyByRole;
