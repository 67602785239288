<template>
  <b-container class="p-4" fluid>
    <b-row class="justify-content-center">
      <b-col class="text-center">
        <img
          :src="
            require(`../../assets/svg/certificate-requested-after-payment.svg`)
          "
          class="d-block mx-auto mb-4"
          alt=""
        />
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col class="text-center" cols="4">
        <h4>Ya estamos generando tu informe</h4>

        <p class="text-muted my-4">
          Dentro de las próximas 24 hrs, te notificaremos a tu corrreo cuando lo
          tengamos listo para ti.
        </p>
        <b-button variant="primary" size="lg" @click="goToPropertiesList"
          >Entendido</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "CertificateRequestedAfterPayment",
  methods: {
    goToPropertiesList() {
      this.$router.push({
        path: "/corredor/propiedades",
      });
    },
  },
};
</script>

<style></style>
