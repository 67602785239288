<template>
  <div class="d-flex">
    <div class="vh-100 bg-white auth-content d-flex align-items-center">
      <b-container class="mt-5">
        <b-row class="justify-content-between">
          <b-col cols="10" lg="6" class="mx-auto text-center text-lg-left">
            <img
              src="../../assets/logo-iprop-green.svg"
              alt=""
              class="d-block mb-5"
            />
            <font-awesome-icon
              class="fa-3x text-success mb-4"
              icon="fa-solid fa-check-circle"
            />
            <h2 class="mb-2">¡Listo!</h2>
            <p class="text-muted mt-3 mb-4">
              Ya verificamos tu cuenta. Ahora puedes comenzar a disfrutar de los
              beneficios de <b>iProp</b>
            </p>
            <b-button
              variant="primary"
              size="lg"
              @click="$router.push({ name: 'Login' })"
              >Ir a inicio de sesión</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="vh-100 bg-login d-none d-lg-flex align-items-center">
      <b-container class="mt-5">
        <b-row>
          <b-col lg="10" class="mx-auto">
            <h4 class="text-white mb-5">Tu propiedad en tus manos</h4>
            <h1 class="text-white">
              Desbloqueamos lo complejo del
              <span class="text-terciary">sector inmobiliario</span>
            </h1>
            <img
              src="../../assets/img-login.png"
              alt=""
              class="d-block img-fluid"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Config from "@/../config.json";

export default {
  name: "VerifiedAccount",
  async beforeMount() {
    const { uuid } = this.$route.params;

    await axios.patch(Config.VUE_APP_URL_GET_ACTIVATE_ACCOUNT, { uuid });
  },
  methods: {
    login() {
      fetch("/api/user/authenticate", {
        method: "post",
        body: JSON.stringify({ email: "realtor@email.com", password: "123" }),
      })
        .then((response) => {
          this.responseCode = response.status;
          return response.json();
        })
        .then((json) => {
          if (this.responseCode == 200) {
            let user = json.user;
            localStorage.setItem("currentUser", JSON.stringify(user));
            let loginUser = JSON.parse(localStorage.getItem("currentUser"));
            
            if (loginUser.role == "owner") {
              this.$router.push({ name: "Propietario" });
            }
            if (loginUser.role == "realstate") {
              this.$router.push({ name: "Home" });
            }
            if (loginUser.role == "realtor") {
              this.$router.push({ name: "Corredor" });
            }
            this.$store.commit("setUserData", loginUser);
            this.$store.commit("setFirstLogin", true);
          }
        });
    },
  },
};
</script>
