<template>
  <b-container class="mb-3">
    <b-row>
      <b-col cols="10">
        <p class="mb-0">{{ title }}</p>
      </b-col>

      <b-col cols="2" class="text-right align-middle">
        <b-spinner
          v-if="status === 'loading'"
          label="Spinning"
          small
          class="text-muted"
        ></b-spinner>
        <font-awesome-icon
          v-if="status === 'done'"
          icon="fa-solid fa-check"
          class="text-success"
        />
        <font-awesome-icon
          v-if="status === 'error'"
          icon="fa-solid fa-times"
          class="text-danger"
        /> </b-col
    ></b-row>
  </b-container>
</template>

<script>
export default {
  name: "StatusItem",
  props: {
    status: {
      type: String,
      default: "loading",
    },
    title: {
      type: String,
      default: "info",
    },
  },
};
</script>
