<template>
    <div class="container">
        <b-row class="mb-4">
            <b-card-group class="justify-content-center" deck>
                <b-card
                header="Detalles de la Transacción"
                header-tag="header"
                title="Resumen"
                >
                    <b-card-text v-if="transactionStatus === '1' && response === 'si'">
                        <b-icon icon="check-circle" variant="success" font-scale="2"></b-icon>
                        La transacción fue exitosa y aquí está la información de la tarjeta:
                        <b-table striped hover :items="transactionDetails" :fields="tableFields"></b-table>
                        <a @click="gotoCredits()" class="btn btn-primary">Ver créditos disponibles</a>
                    </b-card-text>
                    <b-card-text v-else-if="transactionStatus !== '1' || response !== 'si'">
                        <b-icon icon="x-circle" variant="danger" font-scale="2"></b-icon>
                        La transacción fue fallida. Por favor, envíenos un email a <a href="mailto:pagos@inciti.com">pagos@inciti.com</a> señalando que tuvo un problema con la suscripción y adjuntando los siguientes detalles:
                        <b-table striped hover :items="transactionDetails" :fields="tableFields"></b-table>
                    </b-card-text>
                    
                </b-card>
            </b-card-group>
        </b-row>
    </div>
</template>

<script>
import propertyService from "@/services/property.service";
export default {
    name: "PaymentReturnSuscription",
    data() {
        return {
            transactionStatus: null,
            transactionDetails: [],
            tableFields: [
                { key: 'key', label: 'Item' },
                { key: 'value', label: 'Detalle' }
            ],
            response: null,
            redirecting: false
        };
    },
    async beforeMount() {
        this.$store.commit("setLoading");
        let mybody = {
            "uuid": this.$route.params.uuid
        };
        propertyService.getSuscriptionStatus(mybody).then((response) => {
            console.log("response");
            console.log(response);
            this.response = response.response;
            this.transactionStatus = response.data.status;
            this.setTransactionDetails(response.data);
            this.$store.commit("setLoading");
            if (this.response === 'si') {
                setTimeout(() => {
                    this.handleContinue();
                }, 10000);
            }
        })
        .catch((error) => {
            console.log(error);
            this.$store.commit("setLoading");
        });
    },
    methods: {
        setTransactionDetails(data) {
            let details = [];
            if (data.creditCardType && data.last4CardDigits) {
                details.push(
                    { key: 'Tipo de Tarjeta', value: data.creditCardType },
                    { key: 'Últimos 4 Dígitos', value: data.last4CardDigits }
                );
            }
            this.transactionDetails = details;
        },
        handleContinue() {
            this.redirecting = true;
            let mybody = {
                "uuid": this.$route.params.uuid
            };
            propertyService.continueSuscription(mybody);
        },
        gotoCredits() {
            this.$router.push({ name: 'PaymentCredits' });
        }
    }
};
</script>
