<template>
  <b-card>
      <b-row align-v="center">
        <b-col lg="10" xl="8">
          <p>{{ title }}</p>
        </b-col>

        <b-col  
          xl="4"
          class="text-left text-xl-right"
          align-self="center"
            >
          <h3>{{ value }}</h3>
        </b-col>
      </b-row>
  </b-card>
</template>

<script>
export default {
  name: "HorMiniCard",
  props: ["title", "value"],
};
</script>
