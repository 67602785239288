<template>
  <div class="container">
    <b-row class="mt-1">
      <h4>Compra informes individuales.</h4>
    </b-row>
    <b-row>
      <b-card-text class="text-muted">Todos los valores incluyen IVA</b-card-text>
      <b-card-group class="justify-content-center" deck>
        <b-card
          header="One-shot"
          header-tag="header"
          footer="$7.990"
          footer-tag="footer"
          footer-class="font-weight-bold"
          title="1 informe"
        >
          <b-card-text>Acá puedes comprar 1 informe de valorización.</b-card-text>
          <b-button @click="buyOneShot(1, 7990)" variant="primary">Comprar</b-button>
        </b-card>
        <b-card
          header="One-shot"
          header-tag="header"
          footer="$16.350"
          footer-tag="footer"
          footer-class="font-weight-bold"
          title="3 informes"
        >
          <b-card-text>Acá puedes comprar un pack de 3 informes de valorización.</b-card-text>
          <b-button @click="buyOneShot(3, 16350)" variant="primary">Comprar</b-button>
        </b-card>
        <b-card
          header="One-shot"
          header-tag="header"
          footer="$19.950"
          footer-tag="footer"
          footer-class="font-weight-bold"
          title="5 informes"
        >
          <b-card-text>Acá puedes comprar un pack de 5 informes de valorización.</b-card-text>
          <b-button @click="buyOneShot(5, 19950)" variant="primary">Comprar</b-button>
        </b-card>
      </b-card-group>
    </b-row>
    <hr>
    <b-row class="mt-1">
      <h4>Suscríbete a nuestros planes y ahorra.</h4>
    </b-row>
    <b-row>
      <b-card-text class="text-muted">Todos los valores incluyen IVA</b-card-text>
      <b-card-group class="justify-content-center" deck>
        <b-card
          header="Plan Básico"
          header-tag="header"
          footer="$39.980/mes"
          footer-tag="footer"
          footer-class="font-weight-bold"
          title="5 informes mensuales"
        >
          <b-card-text>Acá puedes suscribirte a un plan mensual que incluye 5 informes de valorización. Informes no acumulables para el siguiente mes.</b-card-text>
          <b-button @click="suscribePlan('PlanBasico')" variant="primary">Suscríbete</b-button>
        </b-card>

        <b-card
          header="Plan Standard"
          header-tag="header"
          footer="$49.980/mes"
          footer-tag="footer"
          footer-class="font-weight-bold"
          title="15 informes mensuales"
        >
          <b-card-text>Acá puedes suscribirte a un plan mensual que incluye 15 informes de valorización. Informes no acumulables para el siguiente mes.</b-card-text>
          <b-button @click="suscribePlan('PlanStandard')" variant="primary">Suscríbete</b-button>
        </b-card>

        <b-card
          header="Plan Full"
          header-tag="header"
          footer="$124.950/mes"
          footer-tag="footer"
          footer-class="font-weight-bold"
          title="50 informes mensuales"
        >
          <b-card-text>Acá puedes suscribirte a un plan mensual que incluye 50 informes de valorización. Informes no acumulables para el siguiente mes.</b-card-text>
          <b-button @click="suscribePlan('PlanFull')" variant="primary">Suscríbete</b-button>
        </b-card>

        <b-card
          header="Plan Premium"
          header-tag="header"
          footer="$271.960/mes"
          footer-tag="footer"
          footer-class="font-weight-bold"
          title="130 informes mensuales"
        >
          <b-card-text>Acá puedes suscribirte a un plan mensual que incluye 130 informes de valorización. Informes no acumulables para el siguiente mes.</b-card-text>
          <b-button @click="suscribePlan('PlanPremium')" variant="primary">Suscríbete</b-button>
        </b-card>
      </b-card-group>
    </b-row>
    <!-- Modal para mostrar mensajes de error de suscripción -->
    <b-modal ref="subscriptionProblemModal" @ok="closeSubscriptionProblemModal">
      <template v-slot:modal-title>
        Problema con la suscripción
      </template>
      <p>{{ subscriptionStatusMessage }}</p>
      <div v-if="filteredInvoices.length">
        <p>Facturas vencidas o pendientes de pago:</p>
        <ul>
          <li v-for="invoice in filteredInvoices" :key="invoice.id">
            {{ invoice.subject }} - {{ invoice.amount }} {{ invoice.currency }} 
            <a :href="invoice.paymentLink" target="_blank">Pagar</a>
          </li>
        </ul>
      </div>
    </b-modal>

    <b-modal ref="changePlanModal" @ok="confirmChangePlan">
      <template v-slot:modal-title>
        Confirmación de cambio de plan
      </template>
      <p>Vas a cambiar tu suscripción <b>desde el plan {{ currentPlan }} al plan {{ newPlan }}</b>. Se cancelará la suscripción actual inmediatamente y la nueva suscripción comenzará a contar de este momento. Favor confirma.</p>
    </b-modal>
  </div>
</template>

<script>
import propertyService from "@/services/property.service";
import login from "@/services/login.service";

export default {
  name: "Payment",
  data() {
    return {
      currentPlan: "",
      newPlan: "",
      planToSubscribe: "",
      subscriptionProblem: false,
      subscriptionStatusMessage: "",
      invoices: []
    };
  },
  computed: {
    filteredInvoices() {
      return this.invoices.filter(invoice => invoice.status === 0);
    }
  },
  methods: {
    async buyOneShot(quantity, price) {
      let mytoken = localStorage.getItem("token");
      this.$store.commit("setLoading");
      let openOrder = await propertyService.openFlowOrder({ token: mytoken, cantidad: quantity, precio: price });
      this.$store.commit("setLoading");
      if (openOrder.response === "si") {
        // redirect to external payment gateway by using url from openOrder.url
        let myurl = openOrder.url;
        window.location.href = myurl;
      }
    },
    async suscribePlan(tipo) {
      let planid = "-1";
      let newTipo = "";
      if (tipo === "PlanBasico") {
        planid = "1";
        newTipo = "Básico";
      } else if (tipo === "PlanStandard") {
        planid = "2";
        newTipo = "Standard";
      } else if (tipo === "PlanFull") {
        planid = "3";
        newTipo = "Full";
      } else if (tipo === "PlanPremium") {
        planid = "4";
        newTipo = "Premium";
      }

      let mytoken = localStorage.getItem("token");
      this.$store.commit("setLoading");
      let checkSubscription = await propertyService.checkFlowSuscription({ token: mytoken, plan: planid });
      this.$store.commit("setLoading");

      if (checkSubscription.planActual === -1 || checkSubscription.planActual === null) {
        await this.openFlowSuscription(tipo);
      } else if (checkSubscription.planActual === planid) {
        this.$bvToast.toast("Ya se encuentra suscrito a este plan", {
          title: "Información",
          variant: "info",
          solid: true,
          autoHideDelay: 5000
        });
      } else {
        if (checkSubscription.planActual === "1") {
          this.currentPlan = "Básico";
        } else if (checkSubscription.planActual === "2") {
          this.currentPlan = "Standard";
        } else if (checkSubscription.planActual === "3") {
          this.currentPlan = "Full";
        } else if (checkSubscription.planActual === "4") {
          this.currentPlan = "Premium";
        }

        this.newPlan = newTipo;
        this.planToSubscribe = tipo;
        this.$refs.changePlanModal.show();
      }
    },
    async confirmChangePlan() {
      await this.openFlowSuscription(this.planToSubscribe);
    },
    async openFlowSuscription(tipo) {
      let mytoken = localStorage.getItem("token");
      this.$store.commit("setLoading");
      let openSuscription = await propertyService.openFlowSuscription({ token: mytoken, plan: tipo });
      this.$store.commit("setLoading");
      if (openSuscription.response === "si") {
        // redirect to external payment gateway by using url from openOrder.url
        if (openSuscription.url) {
          let myurl = openSuscription.url;
          window.location.href = myurl;
        } else if (openSuscription.data) {
          this.$router.push({ name: "PaymentCredits" });
        }
      } else if (openSuscription.response === "problem") {
        this.handleSubscriptionProblem(openSuscription);
      }
    },
    handleSubscriptionProblem(openSuscription) {
      this.subscriptionProblem = true;
      this.subscriptionStatusMessage = this.getStatusMessage(openSuscription.status, openSuscription.morose);
      this.invoices = openSuscription.invoices;
      this.$refs.subscriptionProblemModal.show();
    },
    getStatusMessage(status, morose) {
      let message = "";
      if (status === 1) {
        if (morose === 1) {
          message = "Su suscripción está activa, pero tiene una o más facturas vencidas.";
        } else if (morose === 2) {
          message = "Su suscripción está activa, pero tiene una o más facturas pendientes de pago.";
        } else {
          message = "Su suscripción está activa. Desde ahora puede comenzar a valorizar, generar certificados y otros.";
        }
      } else if (status === 0) {
        message = "Su suscripción se encuentra inactiva.";
        if (morose === 1) {
          message += " Además, tiene una o más facturas vencidas.";
        } else if (morose === 2) {
          message += " Además, tiene una o más facturas pendientes de pago.";
        }
        message += " Si tiene alguna duda escribanos a contacto@carpetainmobiliaria.com";
      } else if (status === 4) {
        message = "Su suscripción se encuentra cancelada.";
        if (morose === 1) {
          message += " Además, tiene una o más facturas vencidas.";
        } else if (morose === 2) {
          message += " Además, tiene una o más facturas pendientes de pago.";
        }
        message += " Si tiene alguna duda escribanos a contacto@carpetainmobiliaria.com";
      }

      return message;
    },
    closeSubscriptionProblemModal() {
      this.subscriptionProblem = false;
      this.subscriptionStatusMessage = "";
      this.invoices = [];
    }
  },
  async beforeMount() {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
  }
};
</script>

  