<template>
  <main-report />
</template>

<script>
import MainReport from "../../components/report_property/Main.vue";

export default {
  name: "Report",
  components: {
    MainReport,
  },
};
</script>
