<template>
  <b-form @submit="submitForm">
    <h2>Solicitud de documento</h2>
    <p class="text-muted">
      Para empezar con tu solicitud, primero selecciona una de tus propiedades
      registradas.
    </p>
    <b-card v-if="getPropertiesList.length === 0" body-class="text-center">
      <b-row class="justify-content-center">
        <b-col cols="6" sm="4">
          <img :src="require(`../../assets/img-empty.svg`)" alt="Empty state" class="img-fluid d-block mb-4">
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col cols="10">
          <h4>Sin propiedades</h4>
          <p class="text-muted">No hay propiedades a la que asignarle tu solicitud, prueba agregando una propiedad</p>
          <b-button size="lg" variant="primary" @click="createProperty">
            <font-awesome-icon icon="fa-solid fa-plus" />
            <span class="ml-2" >
              Agregar propiedad</span
            >
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <div v-else>
      <b-card class="mb-3">
        <b-form-group label="Propiedad">
          <b-form-select
            v-model="selected"
            size="lg"
            :options="propertiesList"
            required
          />
        </b-form-group>
      </b-card>
      <b-row>
        <b-col class="d-flex justify-content-end">
          <b-button variant="primary" type="submit" size="lg">
            Siguiente
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FormSelectProperty",
  data() {
    return {
      selected: null,
      propertiesList: [],
    };
  },
  computed: {
    ...mapGetters(["getPropertiesList"]),
  },

  async mounted() {
    this.$store.commit("setDocumentsTotalSale", 0);
    this.$store.commit("setSimpleDocumentsSelected", []);

    await this.$store.dispatch("fetchPropertiesList");

    const list = this.getPropertiesList.map((x) => {
      return { value: x.id, text: x.name };
    });

    this.propertiesList = list;
    this.propertiesList.unshift({
      value: null,
      text: "Selecciona una propiedad",
    });
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      const property = this.getPropertiesList.find(
        (x) => x.id === this.selected
      );
      this.$store.commit("setRequestSelectedProperty", {
        id: property.id,
        name: property.name,
        role: property.rol,
        commune: property.comuna,
        region: property.region,
        address: property.direccion,
        page: property.pages,
        number: property.number,
        year: property.year,
        inform: property.informe,
        date: property.fecha_informe,
      });
      this.$store.commit("incrementStep");
    },
    createProperty() {
      this.$router.replace({
        path: "/corredor/propiedades/ingresar-propiedad",
      });
    },
  },
};
</script>
