<template>
  <b-form @submit="submitForm">
    <h2 class="mb-2">Confirmar inscripción</h2>
    <p class="text-muted">
      Para actualizar tu informe a la versión Premium, necesitamos los datos de
      inscripción de tu propiedad.
    </p>
    <b-alert v-if="hasPNY" :show="showAlert" variant="success">
      ¡Encontramos el número de tu inscripción!
    </b-alert>
    <b-alert v-else :show="showAlert" variant="danger">
      No pudimos encontrar la inscripción de tu propiedad en nuestro sistema por
      lo que no podemos generar tu ficha.
    </b-alert>
    <b-card v-if="!showInscription" class="text-center mb-3">
      <h4>Departamento 101B</h4>
      <p class="mb-1">Foja-Número-Año</p>
      <h5 class="mb-0">
        {{ getPageNumberYear.page }}-{{ getPageNumberYear.number }}-{{
          getPageNumberYear.year
        }}
      </h5>
    </b-card>
    <b-card v-if="false" body-class="px-5 py-5" class="mb-3">
      <h5 class="mb-4">Ingreso manual de inscripción</h5>
      <b-form-group id="foja" label="Foja" label-for="foja">
        <b-form-input
          id="foja"
          v-model.trim="form.page"
          type="text"
          placeholder=""
          @keypress="isNumber($event)"
          @keydown.space.prevent
        />
      </b-form-group>
      <b-form-group id="number" label="Número" label-for="number">
        <b-form-input
          id="number"
          v-model.trim="form.number"
          type="text"
          placeholder=""
          @keypress="isNumber($event)"
          @keydown.space.prevent
        />
      </b-form-group>
      <b-form-group id="year" label="Año" label-for="year">
        <b-form-input
          id="year"
          v-model.trim="form.year"
          type="text"
          placeholder=""
          @keypress="isNumber($event)"
          @keydown.space.prevent
        />
      </b-form-group>
      <p class="mb-0 small text-muted">
        Si no encontramos tu información o no la tienes, mira
        <a v-b-modal.modal-inscription href="#">aquí</a> como puedes obtenerla.
      </p>
    </b-card>
    <b-row v-if="!showInscription">
      <b-col class="d-flex justify-content-end">
        <b-button size="lg" class="mb-3" variant="primary" type="submit">
          Siguiente
        </b-button>
      </b-col>
    </b-row>
    <modal-inscription />
  </b-form>
</template>

<script>
import ModalInscription from "../modals/ModalInscription.vue";
import { mapGetters } from "vuex";

export default {
  name: "FormInscription",
  components: {
    ModalInscription,
  },
  data() {
    return {
      form: {
        page: "",
        number: "",
        year: "",
      },
      showInscription: false,
      showAlert: true,
      reportType: "premium",
    };
  },
  computed: {
    ...mapGetters(["getPageNumberYear", "getReportType"]),
    hasPNY() {
      return this.$store.getters.getPageNumberYear.hasPNY;
    },
  },
  async mounted() {
    this.showInscription = !this.$store.getters.getPageNumberYear.hasPNY;

    this.reportType = this.$store.getters.getReportType;
  },
  methods: {
    openForm() {
      this.showInscription = !this.showInscription;
      this.showAlert = !this.showAlert;
    },
    submitForm(event) {
      event.preventDefault();

      if (this.hasPNY && !this.showInscription) {
        this.$store.commit("incrementStep");
      } else {
        if (
          this.form.page !== "" &&
          this.form.number !== "" &&
          this.form.year
        ) {
          // this.$store.commit("setLoading");
          this.$store.commit("setPageNumberYear", {
            page: this.form.page,
            number: this.form.number,
            year: this.form.year,
          });
          this.$store.commit("incrementStep");
          this.form.page = "";
          this.form.number = "";
          this.form.year = "";
        } else {
          this.$bvToast.toast(
            "Los campos no pueden estar vacíos. Prueba ingresando nuevamente la foja, número y año.",
            {
              title: "¡Hubo un problema!",
              solid: true,
              variant: "danger",
            }
          );
        }
      }
    },
    isNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault();
    },
  },
};
</script>
