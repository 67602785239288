import Home from "../views/Home.vue";
import AdminDashboard from "../views/admin/AdminDashboard.vue";
import AdminPropiedades from "../views/globals/PropertiesList.vue";
import AdminPropertyDetail from "../views/globals/PropertyDetail.vue";
import AdminRequests from "../views/admin/AdminRequests.vue";
import AdminRequestDetail from "../views/admin/AdminRequestDetail.vue";
import AdminDocuments from "../views/admin/AdminDocuments.vue";
import AdminDocumentDetail from "../views/admin/AdminDocumentDetail.vue";
import AdminMap from "../views/admin/AdminMap.vue";
import guardMyRoute from "./guardMyRoute";

export default [
  {
    path: "/admin",
    name: "Admin",
    beforeEnter: guardMyRoute,
    redirect: "admin/propiedades",
    component: Home,
    meta: {
      title: "Admin - Home",
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      {
        path: "/admin",
        name: "AdminHome",
        component: AdminDashboard,
        meta: {
          title: "Admin - Dashboard",
        },
      },
      {
        path: "/admin/propiedades",
        name: "AdminPropiedades",
        component: AdminPropiedades,
        meta: {
          title: "Admin - Propiedades",
        },
      },
      {
        path: "/admin/propiedades/detalle-propiedad/:unit",
        name: "AdminPropertyDetail",
        component: AdminPropertyDetail,
        props: true,
        meta: {
          title: "Propiedad",
        },
      },
      {
        path: "/admin/solicitudes",
        name: "adminRequests",
        component: AdminRequests,
        meta: {
          title: "Solicitudes",
        },
      },
      {
        path: "/admin/solicitudes/detalle-solicitud",
        name: "AdminRequestDetail",
        props: true,
        component: AdminRequestDetail,
        meta: {
          title: "Solicitudes",
        },
      },
      {
        path: "/admin/documentos",
        name: "AdminDocuments",
        props: true,
        component: AdminDocuments,
        meta: {
          title: "Documentos",
        },
      },
      {
        path: "/admin/documentos/detalle/:doc",
        name: "AdminDocumentDetail",
        component: AdminDocumentDetail,
        props: true,
        meta: {
          title: "Documento",
        },
      },
      {
        path: "/admin/mapa-transferencias",
        name: "AdminMap",
        component: AdminMap,
        props: false,
        meta: {
          title: "Documento",
        },
      },
    ],
  },
];
