<template>
  <b-card header-class="bg-white py-3" body-class="px-0" class="mb-4">
    <template #header>
      <header-card
        title="Avalúos y contribuciones"
        content="Información sobre avalúos y contribuciones de tu propiedad"
        icon="circle-dollar"
      />
      <b-card-text>
        <div class="d-flex flex-wrap" style="column-gap: 20px">
          <a
            v-for="(file, index) in pdf"
            :key="index"
            v-b-modal.preview
            href=""
            @click.prevent="showPreview(file)"
            >{{ file.name }}</a
          >
        </div>
      </b-card-text>
    </template>
    <div class="title px-3">
      <h5 class="mb-4">Identificación</h5>
    </div>
    <b-list-group class="list-custom-report mb-4">
      <list-custom-item label="Período" :result="periodo" />
      <list-custom-item
        label="Avalúo total"
        :result="'$ ' + simpleRealEstate.avaluo.total"
      />
      <list-custom-item
        label="Avalúo exento"
        :result="'$ ' + simpleRealEstate.avaluo.exento"
      />
      <list-custom-item
        label="Avalúo afecto"
        :result="'$ ' + simpleRealEstate.avaluo.afecto"
      />
      <list-custom-item label="Fin exención DFL2" :result="exencion" />
    </b-list-group>
    <div class="title px-3">
      <h5 class="mb-4">Contribuciones Trimestrales</h5>
    </div>
    <b-list-group class="list-custom-report mb-4">
      <list-custom-item
        label="Contrib. Neta"
        :result="'$ ' + sREContrib.contrNet"
      />
      <list-custom-item
        label="Sitio Eriazo"
        :result="'$ ' + sREContrib.wasteSite"
      />
      <list-custom-item
        label="Adic, 0,024% Afecto"
        :result="'$ ' + sREContrib.adAfecto"
      />
      <list-custom-item
        label="Sobretasa 0,275%"
        :result="'$ ' + sREContrib.overRecharge"
      />
      <list-custom-item
        label="Aseo Municipal"
        :result="'$ ' + sREContrib.asMunicipal"
      />
      <list-custom-item
        label="Contrib. total"
        :result="'$ ' + sREContrib.contrTotal"
      />
    </b-list-group>
    <div class="title px-3">
      <h5 class="mb-4">Deuda contribuciones</h5>
    </div>
    <b-list-group class="list-custom-report">
      <list-custom-item label="Estado" :result="statusDebt + ' deuda'" />
      <list-custom-item label="Deuda neta" :result="debtContrib.netDebt" />
      <list-custom-item
        label="Reajuste e int."
        :result="debtContrib.readjust"
      />
      <list-custom-item label="Multas" :result="debtContrib.penaltyDebt" />
      <list-custom-item label="Deuda total" :result="debtContrib.debtTotal" />
      <list-custom-item label="Fecha Deuda" :result="debtContrib.timeDebts" />
    </b-list-group>

    <b-modal id="preview" size="lg" :title="modalName">
      <vue-pdf-embed :source="imgSrc" />
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok()">Cerrar</b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import capitalize from "@/utils/capitalize";
import HeaderCard from "./HeaderCard.vue";
import ListCustomItem from "./ListCustomItem.vue";
//import { faTreeChristmas } from "@fortawesome/pro-solid-svg-icons";

export default {
  name: "Contributions",
  components: {
    HeaderCard,
    ListCustomItem,
    VuePdfEmbed,
  },
  props: {
    simpleRealEstate: {
      type: Object,
      default() {
        return {};
      },
    },
    debtContribution: {
      type: Object,
      default() {
        return {};
      },
    },
    pdf: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      modalName: null,
      imgSrc: "",
    };
  },

  computed: {
    periodo() {
      return (
        capitalize(this.simpleRealEstate.periodo.tiempo) +
        " " +
        this.simpleRealEstate.periodo.año
      );
    },
    exencion() {
      return this.simpleRealEstate.avaluo.exencion === ""
        ? "-"
        : this.simpleRealEstate.avaluo.exencion;
    },
    status() {
      return this.debtContribution.status;
    },
    sREContrib() {
      return {
        contrNet:
          this.simpleRealEstate.contribucion.neta === ""
            ? "0"
            : this.simpleRealEstate.contribucion.neta,
        wasteSite:
          this.simpleRealEstate.contribucion.no_edificados === ""
            ? "0"
            : this.simpleRealEstate.contribucion.no_edificados,
        contrTotal:
          this.simpleRealEstate.contribucion.total === ""
            ? "0"
            : this.simpleRealEstate.contribucion.total,
        adAfecto:
          this.simpleRealEstate.contribucion.adicional_afecto === ""
            ? "0"
            : this.simpleRealEstate.contribucion.adicional_afecto,
        asMunicipal:
          this.simpleRealEstate.contribucion.aseo_municipal === ""
            ? "0"
            : this.simpleRealEstate.contribucion.aseo_municipal,
        overRecharge:
          this.simpleRealEstate.contribucion.sobretasa === ""
            ? "0"
            : this.simpleRealEstate.contribucion.sobretasa,
      };
    },
    debtContrib() {
      return {
        netDebt: this.status
          ? `$ ${this.debtContribution.penalty[0].net_debt}`
          : "$ 0",

        /*
        readjust: this.status
          ? `$ ${parseFloat(
              (
                parseFloat(this.debtContribution.penalty[0].readjustment) +
                parseFloat(this.debtContribution.penalty[0].interest)
              ).toFixed(3)
            )}`
          : "$ 0",
        */

        readjust: this.status
        ? `$ ${parseFloat(
            (
              parseFloat(this.debtContribution.penalty[0].total) -
              parseFloat(this.debtContribution.penalty[0].net_debt) -
              parseFloat(this.debtContribution.penalty[0].penalty)
            ).toFixed(3)
          )}`
        : "$ 0",

        penaltyDebt: this.status
          ? `$ ${this.debtContribution.penalty[0].penalty}`
          : "$ 0",

        debtTotal: this.status
          ? `$ ${this.debtContribution.penalty[0].total}`
          : "$ 0",

        timeDebts: this.status
          ? this.debtContribution.penalty[0].form === "Total Deuda Morosa (CLP)"
            ? this.debtContribution.penalty[0].expiration_date
            : "0"
          : "$ 0",
      };
    },
    statusDebt() {
      return this.status ? "Con " : "Sin";
    },
    statusExemption() {
      return parseInt(this.simpleRealEstate.avaluo.exencion) - 2022 < 5
        ? true
        : false;
    },
    statusWasteSite() {
      return parseInt(this.simpleRealEstate.contribucion.no_edificados) > 0
        ? true
        : false;
    },
  },
  methods: {
    showPreview(file) {
      const { name, content } = file;
      this.modalName = name;
      this.imgSrc = `data:@file/pdf;base64,${content}`;
    },
  },
};
</script>
