<template>
  <b-card class="mb-3">
    <b-row>
      <b-col sm="6" lg="7">
        <h6 class="text-muted">{{ doc.category }}</h6>
        <h6>{{ document.name }}</h6>
        <div v-if="doc.hasFile">
          <!-- <b-button  v-b-modal="'viewerFile'" variant="link" class="p-0">{{ document.file.name }}</b-button> -->
          <h6 class="text-muted">{{ document.file.name }}</h6>
        </div>
        <p v-else class="text-muted mb-0">No hay documento</p>
      </b-col>
      <b-col sm="6" lg="5" class="d-flex align-items-center justify-content-end">
        <b-button v-if="!doc.status" variant="outline-secondary" size="lg" 
          @click="downloadDocument" title='Descargar documento pdf'>
          <font-awesome-icon icon="fa-solid fa-arrow-down" class="mr-2" /> 
          Descargar
        </b-button>
        <b-alert v-else show variant="info" class="mb-0">
          <font-awesome-icon icon="fa-solid fa-timer" class="mr-2" /> 
          Esperando documento...
        </b-alert>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "RequestDocument",
  props: {
    document: {
      type: Object
    }
  },
  computed: {
    doc() {
      return {
        category: 
          this.document.folders[0] === "cbrs" ? 'Consevador' : "Municipales",
        hasFile: 
          this.document.file === null ? false : true,
        file:  
          this.$store.getters.getDocument !== undefined ? this.$store.getters.getDocument : null,
        status:
        this.document.status === "pendiente" ? true : false,
      } 
    }
  },  
  methods: {
    async downloadDocument() {
      await this.$store.dispatch('viewDocument', { documentId: this.document.file.id, source: "document" });

      const downloadLink = document.createElement("a");
      downloadLink.href = this.doc.file.content;
      downloadLink.download = this.document.name;
      downloadLink.click();
      },
    } 
  }
</script>