export default {
  data() {
    return {
      show: false
    }
  },
  mounted() {
    this.$parent.$on('toggleSidebar', () => {
      this.show = !this.show
    })
  },
  methods: {
    toggleSidebar() {
      this.$parent.$emit('toggleSidebar')
    }
  }
}
