import Vue from 'vue'
import App from './App.vue'
import store from "./store";
import router from "./router";
import '../src/sass/app.scss'
// import { makeServer } from "./mirage/server";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
//import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import { VBScrollspy, VBScrollspyPlugin } from 'bootstrap-vue'
import device from 'vue-device-detector-js'
import '@/plugins/apexcharts'
import VueSlideBar from 'vue-slide-bar'

export const eventBus = new Vue();

library.add(fas)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.directive('b-scrollspy', VBScrollspy)
Vue.use(VBScrollspyPlugin)
Vue.use(device)


Vue.config.productionTip = false

// if (process.env.NODE_ENV === "development") {
//   makeServer();
// }

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
