<template>
    <b-form @submit="submitForm">
      <h2 class="mb-2">Búsqueda en el barrio</h2>
      <p class="text-muted">Estamos buscando propiedades en el sector.</p>
      <!--
      <b-card class="mb-3">
        <div class="text-left m-sm-3">
          <b-form-group
            id="input-group-1"
            label="Nombre"
            label-for="input-1"
            class="font-weight-bold"
          >
            <b-form-input
              id="input-1"
              type="text"
              v-model="value"
              placeholder="Ej: Mi casa, Mi Hogar ..."
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </b-card>
    -->
  
      <!-- Botón para tomar fotos y agregar a la galería
      <b-button size="lg" class="mb-3" variant="primary" @click="addPhotoGallery">
        <i class="fa fa-camera"></i> Captura o sube nueva foto
      </b-button>
      -->
      <!-- Galería de imágenes -->
      <!--
      <div class="image-gallery">
        <div v-for="(photo, index) in photoGallery" :key="index" class="image-thumbnail" :style="{ zIndex: zIndexValue }">
          <img
            :src="photo"
            alt="Foto"
            @click="toggleZoom(index)"
            :class="{ 'zoomed': isZoomed && zoomedPhotoIndex === index }"
          />
          <b-button @click="removePhoto(index)" variant="danger" pill size="sm" v-if="!isZoomed">
            <i class="fa fa-trash"></i>
          </b-button>
        </div>
      </div>
      -->
      <!--
      <b-row class="mt-4">
        <b-col class="d-flex justify-content-between">
          <p class="text-muted text-superpuesto">Arrastre el marcador o busque en el mapa.</p>
        
          <b-button :disabled="photoGallery.length < 1" size="lg" class="mt-2 mb-3 button-superpuesto" variant="primary" type="submit">
            <i class="fa fa-location"></i> Relocalizar
          </b-button>
          
        </b-col>
      </b-row>
      -->
    </b-form>
  </template>
  
  <script>
  import { Camera, CameraResultType } from '@capacitor/camera';
  import { Geolocation } from '@capacitor/geolocation';
  import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
  import { mapGetters, mapMutations } from "vuex";
  import ModalRol from "../modals/ModalRol.vue";
  export default {
    name: "FormPropertyPictures",
    components: {
        ModalRol,
    },
    props: {
      pictures: String,
      propertyName: String,
    },
    data() {
      return {
        photoGallery: [], // Almacena las fotos
        isZoomed: false,     // Nuevo estado para el zoom
        zoomedPhotoIndex: 0,  // Índice de la imagen en zoom
        zIndexValue: 9000,
        showMap: false,
      };
    },
    computed: {
    ...mapGetters([
      "getterRegions",
      "getterCommunes",
      "getRegionId",
      "getResumeProperty",
      "setStep",
    ]),
    myformatedGeojson() {
      //console.log(localStorage.getItem("lat"));
      //console.log(localStorage.getItem("lon"));
      const lat = -33.4726789;
      const lon = -70.7946416;
      //console.log(lat);
      //console.log(lon);
      return {
        geometry: {
          coordinates: [
            lon,
            lat,
          ],
        },
      };
    },
    },
    methods: {
      submitForm(event) {
        event.preventDefault();
        this.zIndexValue = 0;
        console.log("submitForm1");
        //this.$emit("setPictures", this.value);
        console.log("submitForm2");
        this.printCurrentPosition();
        //this.$store.commit("incrementStep");
      },
      async addPhotoGallery() {
        await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri
            }).then((image) => {
                this.photoGallery.push(image.webPath);
        });
        /*
        // Simula la toma de una foto (puedes reemplazar esto con tu lógica real de captura de fotos)
        const newPhoto = "https://picsum.photos/200/300";
        // Agrega la foto a la galería
        this.photoGallery.push(newPhoto);
        */
      },
      removePhoto(index) {
        // Elimina una foto de la galería en función de su índice
        this.photoGallery.splice(index, 1);
      },
      async printCurrentPosition() {
        // Configurar el tiempo máximo de espera en milisegundos
        const timeoutMillis = 15000;

        try {
          // Iniciar el loader
          this.$store.commit("setLoading");
          let lat = -33.4378234;
          let lon = -70.6530768;
          try {
            // Obtener las coordenadas actuales
            const coordinates = await Geolocation.getCurrentPosition();
            console.log('Current position:', coordinates);
            console.log(coordinates.coords.latitude, coordinates.coords.longitude);

            // Guardar las coordenadas en el almacenamiento local
            lat = coordinates.coords.latitude;
            lon = coordinates.coords.longitude;
          }
          catch (error) {
            console.log('Error getting location', error);
          }
          localStorage.setItem("lat", lat);
          localStorage.setItem("lon", lon);
          console.log(localStorage.getItem("lat"));
          console.log(localStorage.getItem("lon"));

          // Construir el cuerpo de la solicitud
          const body = {
            token: '$a89fd6a$_l',
            lat: lat,
            lon: lon
          };

          // Ejecutar la acción de Vuex con tiempo de espera máximo
          await Promise.race([
            this.$store.dispatch("fetchRolSearchByCoords", body),
            new Promise((_, reject) => setTimeout(() => reject(new Error("Tiempo de espera agotado")), timeoutMillis))
          ]);

          // Detener el loader
          this.$store.commit("setLoading");

          // Establecer el paso en 3
          this.$store.commit("setStep", 3);
        } catch (error) {
          // Manejar errores, por ejemplo, mostrar un mensaje al usuario
          this.$bvToast.toast(
            "No pudimos encontrar la propiedad que buscabas, verifica la información y vuelve a intentarlo.",
            {
              title: "Hay un problema",
              variant: "danger",
              solid: true,
            }
          );
          console.error(error);

          // Detener el loader en caso de error
          this.$store.commit("setLoading");
          this.showMap = true;
        }
      },
      toggleZoom(index) {
        if (this.isZoomed && this.zoomedPhotoIndex === index) {
          // Si ya está en modo de zoom, desactívalo
          this.isZoomed = false;
        } else {
          // De lo contrario, activa el modo de zoom
          this.isZoomed = true;
          this.zoomedPhotoIndex = index;
        }
      },
    },
    beforeMount() {
      this.printCurrentPosition();
    }
  };
  </script>
  
  <style>
  .image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;
  }
  
  .image-thumbnail {
    position: relative;
  }
  
  .image-thumbnail img {
    max-width: 100px;
    max-height: 100px;
  }
  
  .image-thumbnail button {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  img {
    max-width: 100%;
    transition: transform 0.3s ease-in-out;
    z-index: 9000;
  }

  img.zoomed {
    transform: scale(2.5); /* Cambia el valor según la cantidad de zoom deseada */
    z-index: 9000;
  }

  .text-superpuesto {
    position: relative;
    z-index: 0; /* Establecer un valor de z-index menor para el texto */
  }

  .button-superpuesto {
    position: relative;
    z-index: 0; /* Establecer un valor de z-index menor para el texto */
  }
  </style>






  
