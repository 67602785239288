<template>
  <b-container class="p-4">
    <b-row class="justify-content-center">
      <b-col lg="6">
        <b-card body-class="p-5" class="mx-auto text-center">
          <b-row class="justify-content-center">
            <b-col sm="8">
              <img src="../../assets/not-found.svg" class="img-fluid mb-4" />
            </b-col>
          </b-row>
          <h2>¡Oops!</h2>
          <p class="mb-4 text-muted">
            Parece que no podemos encontrar lo que buscas, intenta nuevamente.
          </p>
          <b-button size="lg" variant="primary" @click="redirectBack()">
            Volver al inicio
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "NotFound",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },

  data() {
    return {
      prevRoute: null,
    };
  },
  methods: {
    redirectBack() {
      if (this.prevRoute.path && this.prevRoute.path !== "/") {
        this.$router.push("/corredor/propiedades");
      } else {
        this.$router.go('-1');
      }
    },
  },
};
</script>