import jwtDecode from 'jwt-decode'; // Asegúrate de tener la librería jwt-decode instalada
import store from "@/store";

export default function guardMyRoute(to, from, next) {
  console.log("GuardMyRoute invoked");
  
  // Verificar si el token está presente en la URL
  const urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get('token');
  let decoded;

  if (token) {
    console.log("Token found in URL:", token);
    // Si hay token en la URL, almacenarlo temporalmente en localStorage
    localStorage.setItem("token", token);
    try {
      decoded = jwtDecode(token);
      console.log("Token decoded from URL:", decoded);
    } catch (error) {
      console.log("Token inválido en la URL:", error);
      if (to.name !== "Login") {
        console.log("Redirigiendo a Login debido a token inválido en la URL");
        next({ name: "Login" });
      }
      return;
    }
    store.commit("setUserData", {
      id: decoded.id,
      firstName: decoded.name,
      lastName: decoded.lastName,
      role: 1,
      phone: decoded.phone,
      email: decoded.email,
    });
  } else {
    console.log("No token in URL, checking localStorage");
    // Si no hay token en la URL, utilizar el token del localStorage
    token = localStorage.getItem("token");
    if (!token) {
      console.log("No token in localStorage");
      
      if (to.name !== "Login" && to.name !== "VerificationMessage" && to.name !== "VerifiedAccount") {
        console.log("Redirigiendo a Login debido a falta de token en localStorage");
        next({ name: "Login" });
      } else {
        console.log("Ya en la página de correcta, continuando");
        next();
      }
      
      //next();
      return; // Asegurar que no continúa el flujo si falta el token
    }
    try {
      decoded = jwtDecode(token);
      console.log("Token decoded from localStorage:", decoded);
    } catch (error) {
      console.log("Token inválido en localStorage:", error);
      if (to.name !== "Login") {
        console.log("Redirigiendo a Login debido a token inválido en localStorage");
        next({ name: "Login" });
      } else {
        console.log("Ya en la página de Login, continuando");
        next();
      }
      return; // Asegurar que no continúa el flujo si el token es inválido
    }
  }

  console.log("Token valid:", token);

  if (token) {
    console.log("Token decodificado:", decoded);
    const isExpired = decoded.exp < Date.now() / 1000;

    if (isExpired) {
      console.log("Token expirado");
      localStorage.removeItem("token"); // Remover el token expirado
      store.commit('logout'); // Asegurarse de limpiar el estado de autenticación
      if (to.name !== "Login") {
        console.log("Redirigiendo a Login debido a token expirado");
        next({ name: "Login" });
      }
      return; // Salir de la función para evitar llamadas adicionales a next
    }

    console.log("Token válido");

    // Proceder con las verificaciones de rutas que requieren autenticación
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      console.log("Ruta requiere autenticación");
      if (!store.getters.getIsLoggedIn) {
        console.log("Usuario no está logeado, iniciando sesión con el token");
        store.commit('login', { token }); // Simula el login usando el token
        store.commit('setRole', 1); // Asignar el rol de "corredor" (1) por defecto
        next();
      } else if (to.path.search("corredor") > 0 && store.getters.role === 1) {
        console.log("Accediendo a ruta de corredor");
        next();
      } else if (to.path.search("corredor") < 0 && store.getters.role === 1) {
        console.log("Redirigiendo a la página de Corredor");
        next({ name: "Corredor" });
      } else if (to.path.search("admin") > 0 && store.getters.role === 4) {
        console.log("Accediendo a ruta de admin");
        next();
      } else if (to.path.search("admin") < 0 && store.getters.role === 4) {
        console.log("Redirigiendo a la página de AdminRequests");
        next({ name: "adminRequests" });
      } else {
        console.log("Ruta no coincide con roles conocidos, redirigiendo");
        next();
      }
    } else {
      console.log("Ruta no requiere autenticación, continuando");
      next();
    }

    // Manejar rutas que deben ocultarse después del inicio de sesión
    if (to.matched.some((record) => record.meta.hideForAuth)) {
      console.log("Ruta debe estar oculta después de login");
      if (store.getters.getIsLoggedIn) {
        if (store.getters.role === 1) {
          console.log("Redirigiendo a la página de Corredor");
          next({ name: "Corredor" });
        } else if (store.getters.role === 4) {
          console.log("Redirigiendo a la página de AdminRequests");
          next({ name: "adminRequests" });
        } else {
          console.log("Ruta permitida, continuando");
          next();
        }
      } else {
        console.log("Usuario no está logeado, continuando");
        next();
      }
    } else {
      console.log("Ruta no tiene restricciones de autenticación, continuando");
      next();
    }
  } else {
    console.log("No hay token disponible, verificando ruta requerida");
    //alert("No hay token disponible, verificando ruta requerida")
    if (to.matched.some(record => record.meta.requiresAuth)) {
      next({ name: "Login" });
    } else {
      next();
    }
  }
}

