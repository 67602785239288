<template>
  <b-row>
    <b-col cols="10">
      <div class="media">
        <font-awesome-icon
          class="icon-header text-secondary mr-4 d-none d-sm-block"
          :icon="['fas', icon]"
          size="xl"
        />
        <div class="media-body">
          <h5 class="mb-2">{{ title }}</h5>
          <p class="text-muted mb-0">{{ content }}</p>
        </div>
      </div>
    </b-col>
    <b-col
      v-if="status === true"
      class="d-flex justify-content-end align-items-center"
      cols="2"
    >
      <div id="warning" class="state-icon">
        <font-awesome-icon
          icon="fa-solid fa-exclamation-circle"
          class="text-warning mr-2"
          size="2xl"
        />
      </div>
      <b-popover
        target="warning"
        triggers="hover"
        placement="right"
        variant="warning"
      >
        <template #title>
          <span>!Atención!</span>
        </template>
        <div v-if="exemption">
          <b>Fin exención</b>
          <p>
            Quedan pocos años para que finalice el beneficio de contribuciones
            de esta vivienda DFL-2; luego del vencimiento lo verás reflejado con
            un aumento de cerca del doble en el monto a pagar por concepto de
            contribuciones.
          </p>
        </div>
        <div v-if="wasteSite">
          <b>Sitio Eriazo</b>
          <p>
            Actualmente esta vivienda figura como eriazo ante el Servicio de
            Impuestos Internos, teniendo que pagar sobrecostos importantes en el
            pago de contribuciones hasta que se regularice.
          </p>
        </div>
        <div v-if="debt">
          <b>Deuda contribuciones</b>
          <p>
            Existen deudas pendientes en el pago de las contribuciones de este
            inmueble. Recordamos que la deuda va generando multas e intereses en
            el tiempo, además de tener un riesgo de remate por parte de la
            Tesorería General de la República.
          </p>
        </div>
      </b-popover>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "HeaderCard",
  props: {
    content: {
      type: String,
      default: "Contenido",
    },
    title: {
      type: String,
      default: "Título",
    },
    icon: {
      type: String,
      default: "plug",
    },
    documents: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
      default: false,
    },
    exemption: {
      type: Boolean,
      default: false,
    },
    wasteSite: {
      type: Boolean,
      default: false,
    },
    debt: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
