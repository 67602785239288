/* eslint-disable */
import axios from "axios";
import Config from "@/../config.json";
import login from "@/services/login.service";

const reportService = {
  async requestReport(userId, propertyId, region, comuna, rol, type) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const petitionName = `generate:certificate:${type}`;
  
      const worksParameters = {
        region,
        comuna,
        rol,
      };
  
      const data = await axios({
        url: `${Config.VUE_APP_URL_REQUEST_CERTIFICATE}/${userId}/${propertyId}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${mytoken}`
        },
        data: {
          name: petitionName,
          worksParameters,
        },
      });
  
      return data;
    } catch (error) {
      throw error;
    }
  },
  async getReportStatus(propertyId) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const data = await axios.get(
        `${Config.VUE_APP_URL_LAST_CERTIFICATE}/${propertyId}/latest`,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
  
      return data;
    } catch (error) {
      throw error;
    }
  },
  
  // This endpoint fetches the report with scrappers and also saves the information on DB
  async getReport(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_FREE_CERTIFICATES,
        payload,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          },
          timeout: 180000 // 3 minutes timeout
        }
      );
  
      return data;
    } catch (error) {
      throw error;
    }
  },

  //This endpoint get the report data directly from our DB
  async getStoredReport(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_GET_REPORT,
        payload,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};
export default reportService;
