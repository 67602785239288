<template>
  <b-container class="p-4" fluid>
    <b-row class="justify-content-center">
      <b-col sm="4" class="text-center">
        <img
          :src="require(`../../assets/img-error.svg`)"
          class="img-fluid mb-4"
          alt=""
        />
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col class="text-center" sm="8" lg="6" xl="4">
        <h4 class="mb-2">Hubo un problema</h4>
        <p class="text-muted mb-4">
          La transacción se canceló porque se agotó el tiempo de espera.
        </p>
        <b-button variant="primary" size="lg" @click="goToPropertiesList"
          >Entendido</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "TimeoutTransaction",
  methods: {
    goToPropertiesList() {
      this.$router.push({
        path: "/corredor/propiedades",
      });
    },
  },
};
</script>

<style></style>
