import axios from 'axios';
import Config from "@/../config.json";

const register = {
  async getRegister(values) {

    const data = await axios.post( 
      Config.VUE_APP_URL_REGISTER,
      values
    ).catch((err)=>{return(err.response.data.message)})
    ;
   
    return data;
  
  },

};
export default register;
