<template>
  <b-card
    class="h-100 text-center"
    body-class="px-xl-4 py-4 d-flex flex-column justify-content-between align-items-center"
  >
    <div class="option__card-head">
      <div v-for="item in items">
        <h4 v-if="option === item.report">
          <b-badge :variant="item.variantPill" pill class="mb-4"
            >{{ item.title }}
          </b-badge>
        </h4>
      </div>
      <h2 class="mb-4">{{ price }}</h2>
      <div v-for="(item, index) in items" :key="index">
        <ul
          v-if="option === item.report"
          class="check-list-custom text-left mb-5 ml-4"
        >
          <li
            v-for="(i, innerKey) in item.options"
            :key="innerKey"
            class="mb-2"
          >
            {{ i["content"] }}
          </li>
        </ul>
      </div>
    </div>
    <b-row> 
      <div v-if="option === 'basic' || showWarningOrButton" class="col-12">
        <div v-for="(item, index) in items" :key="index">
          <!--
          <b-button
            v-if="option === item.report"
            v-b-modal="idModal"
            size="lg"
            variant="outline-secondary"
            class="mb-2"
            >Ver ejemplo
          </b-button>
          -->
        </div>
        <b-button v-if="hasPremium" size="lg" variant="secondary" block @click="setData(option)">
          Generar certificados
        </b-button>
        <b-alert v-else class="mb-0" show variant="warning">
          Lo sentimos, en esta versión no tenemos disponible el informe premium
          para esta propiedad.
        </b-alert>
      </div>
    </b-row>

    <b-modal
      v-if="option === 'basic'"
      id="basic-example"
      size="lg"
      title="Ejemplo de Informe Básico"
      scrollable
      body-class="p-0"
    >
      <img
        :src="require(`../../assets/report-basic.png`)"
        alt=""
        class="d-block img-fluid w-100"
      />
      <template #modal-footer="{ ok }">
        <b-button size="lg" @click="ok()">Entendido</b-button>
      </template>
    </b-modal>
    <b-modal
      v-if="option === 'premium'"
      id="premium-example"
      size="lg"
      title="Ejemplo de Informe Premium"
      scrollable
      body-class="p-0"
    >
      <img
        :src="require(`../../assets/report-premium.png`)"
        alt=""
        class="d-block img-fluid w-100"
      />
      <template #modal-footer="{ ok }">
        <b-button size="lg" @click="ok()">Entendido</b-button>
      </template>
    </b-modal>
    <!-- <b-modal
      v-if="option === 'full'"
      id="full-example"
      size="lg"
      title="Informe Full"
    >
      <img
        :src="require(`../../assets/report-full.png`)"
        alt=""
        class="d-block mx-auto"
      />
      <template #modal-footer="{ ok }">
        <b-button size="lg" @click="ok()">Entendido</b-button>
      </template>
    </b-modal> -->
  </b-card>
</template>

<script>
export default {
  name: "ReportPrices",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hasPremium: {
      type: Boolean,
      default: true,
    },
    option: {
      type: String,
      // default: "basic",
      default: "premium",
    },
    idModal: {
      type: String,
      // default: "basic",
      default: "premium",
    },
    price: {
      type: String,
      default: "Gratis",
    },
    showWarningOrButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        {
          report: "basic",
          title: "Básico",
          variantPill: "primary",
          options: [
            { content: "Propietarios" },
            { content: "Superficies de Construcción y Terreno" },
            { content: "Año y tipo de Construcción" },
            { content: "Avalúo Fiscal" },
            { content: "Contribuciones en detalle" },
            { content: "Deuda por Contribuciones y Multas" },
            { content: "Certificado - No expropiación Serviu" },
            { content: "Certificado - Avalúo Fiscal" },
            { content: "Certificado - Deuda Contribuciones TGR" },
          ],
        },
        {
          report: "premium",
          title: "Premium",
          variantPill: "warning",
          options: [
          { content: "Propietarios" },
            { content: "Superficies de Construcción y Terreno" },
            { content: "Año y tipo de Construcción" },
            { content: "Avalúo Fiscal" },
            { content: "Contribuciones en detalle" },
            { content: "Deuda por Contribuciones y Multas" },
            { content: "Certificado - No expropiación Serviu" },
            { content: "Certificado - Avalúo Fiscal" },
            { content: "Certificado - Deuda Contribuciones TGR" },
            { content: "Historial de Precios de la Propiedad" },
            { content: "Precios de Ventas de Propiedades similares" },
          ],
        },
        {
          report: "full",
          title: "Full",
          variantPill: "success",
          options: [
            { content: "Propietarios" },
            { content: "Información Municipal" },
            { content: "Avalúos y Contribuciones" },
            { content: "Historial de transferencias" },
            { content: "Precios de Transferencias" },
            { content: "Inscripciones de dominio" },
            { content: "GP - Hipotecas" },
          ],
        },
      ],
    };
  },
  methods: {
    showReport() {
      this.$router.push({
        name: "Report",
        params: { generated: false, type: "basic" },
      });
    },
    async setData(type) {
      if (type === "basic") {
        this.$router.push({
          path: "/corredor/propiedades/informe",
          query: { generated: false },
        });
      } else {
        this.$store.dispatch("pageNumberYear");
      }
      this.$store.commit("setReportType", type);
    },
  },
};
</script>
