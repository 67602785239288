<template>
  <div>
    <div class="text-left mt-5">
      <h2>¡Hola Juan!</h2>
      <h6 class="text-muted">Este es el reporte que tenemos para tí</h6>
    </div>
    <admin-summary></admin-summary>
    <b-row class="mt-5">
      <b-col cols="12" md="6">
        <progress-document
          title="Documentos más solicitados"
          :documents="mostRequested"
        ></progress-document>
      </b-col>
      <b-col cols="12" md="6">
        <progress-document
          title="Documentos con más atrasos"
          :documents="mostDelayed"
        ></progress-document>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AdminSummary from "@/components/misc/AdminSummary.vue";
import ProgressDocument from "@/components/misc/ProgressDocument.vue";
export default {
  name: "AdminDashboard",
  components: { AdminSummary, ProgressDocument },
  data() {
    return {
      mostRequested: [
        { title: "Certificado Recepción Final", items: 10, quantity: 10 },
        { title: "Copia Inscripción de Propiedad", items: 7, quantity: 10 },
        {
          title: "Certificado Avalúo Fiscal de la propiedad",
          items: 4,
          quantity: 10,
        },
        { title: "Certificado matrimonio", items: 2, quantity: 10 },
      ],
      mostDelayed: [
        { title: "Certificado Recepción Final", items: 5, quantity: 5 },
        { title: "Copia Inscripción de Propiedad", items: 4, quantity: 6 },
        {
          title: "Certificado Avalúo Fiscal de la propiedad",
          items: 2,
          quantity: 5,
        },
        { title: "Certificado matrimonio", items: 1, quantity: 5 },
      ],
    };
  },
};
</script>
