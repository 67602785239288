import requestService from "@/services/request.service";
/* eslint-disable */
export default {
  state: {
    adminRequestsList: null,
    selectedAdminRequest: null,
    fileToUpload: null,
  },
  mutations: {
    setAdminRequestsList(state, data) {
      state.adminRequestsList = data;
    },
    setSelectedAdminRequest(state, data) {
      state.selectedAdminRequest = data;
    },
    setFileToUpload(state, data) {
      state.fileToUpload = data;
    },
  },
  getters: {
    getAdminRequestsList(state) {
      return state.adminRequestsList;
    },
    getSelectedAdminRequest(state) {
      return state.selectedAdminRequest;
    },
    getFileToUpload(state) {
      return state.fileToUpload;
    },
  },
  actions: {
    async fetchAdminRequestsList({ commit, state }) {
      const list = await requestService.adminRequestsList();
      commit("setAdminRequestsList", list);
      this.commit("setLoading", { root: true });
    },
    async uploadFile({ commit, state, dispatch }) {
      this.commit("setLoading", { root: true });

      const response = await requestService.adminUploadFileRequest({
        user_id: state.selectedAdminRequest.user.id,
        property_id: state.fileToUpload.propertyId,
        file: {
          base64: state.fileToUpload.content,
          type: state.fileToUpload.type,
        },
        name: state.fileToUpload.name,
        folders: ["legales"],
        file_name: state.fileToUpload.name.replace(" ", "_"),
        select_document_id: state.fileToUpload.documentId,
      });

      if (response.status) {
        await dispatch("fetchAdminRequestsList");
        const request = state.adminRequestsList.find(
          (x) => x.id === state.selectedAdminRequest.id
        );
        commit("setSelectedAdminRequest", request);
      }
    },
    async finishRequest({ commit, state, dispatch }) {
      const response = await requestService.adminFinishRequest({
        request_document_id: state.selectedAdminRequest.id,
      });
      await dispatch("fetchAdminRequestsList");
      const request = state.adminRequestsList.find(
        (x) => x.id === state.selectedAdminRequest.id
      );
      commit("setSelectedAdminRequest", request);
      this.commit("setLoading", { root: true });

      return response.success;
    },
  },
};
