<template>
  <b-list-group-item class="d-flex">
    <p class="mb-0">{{ label }}</p>
    <p class="font-weight-bold text-right mb-0 text-clamp">{{ result }}</p>
  </b-list-group-item>
</template>

<script>
export default {
  name: "ListCustomItem",
  props: {
    label: {
      type: String,
      default: "Item",
    },
    result: {
      type: [Number, String],
      default: "Resultado",
    },
  },
};
</script>
