<template>
  <b-container class="mt-5">
    <b-row class="justify-content-center">
      <img src="../../assets/logo-iprop-green.svg" alt="" class="mb-4">
    </b-row>
    <b-row class="justify-content-center">
      <b-col md="8" lg="6">
        <b-card body-class="px-5 py-4 text-center">
          <h1>
            <font-awesome-icon
              icon="fa-solid fa-check-circle"
              class="text-success"
            />
          </h1>
          <h2>¡Listo!</h2>
          <p class="text-muted">
            Te hemos enviado un correo a la dirección que nos indicaste para que
            puedas crear tu nueva contraseña.
          </p>
          <b-button variant="primary" size="lg" href="/">Entendido</b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "EmailSend",
};
</script>
