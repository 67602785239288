<template>
  <b-container class="mt-5">
    <b-row class="justify-content-center">
      <img src="../../assets/logo-iprop-green.svg" alt="" class="mb-4">
    </b-row>
    <b-row class="justify-content-center">
      <b-col md="8" lg="6">
        <b-card body-class="px-5 py-4 text-center">
          <h1>
            <font-awesome-icon icon="fa-solid fa-check-circle" class="text-success" />
          </h1>
          <h2>¡Listo!</h2>
          <p class="text-muted">Tu contraseña fue creada con éxito. Prueba ahora iniciando sesión con tu cuenta.</p>
          <b-button variant="primary" size="lg" href="/">Ir a inicio de sesión</b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script> 
export default {
  name: "PasswordSuccess"
}
</script>