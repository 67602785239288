<template>
  <b-form-group
    class="form-group-password font-weight-bold position-relative"
    :label="label"
    :label-for="id"
  >
    <b-form-input
      :id="id"
      :v-model="vModel"
      :type="type"
      :required="required"
      :state="state"
      size="lg"
      @blur.native="validatePassword(id, $event)"
      @update="onChange(id, $event)"
    />
    <b-button
      variant="outline-link"
      class="btn-password"
      @click="showPassword()"
    >
      <font-awesome-icon :icon="['fas', icon]" />
    </b-button>
    <b-form-invalid-feedback>
      La contraseña debe tener al menos 8 caracteres.
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  name: "InputPassword",
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    vModel: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: null,
      type: "password",
      icon: "fa-eye",
      state: null,
    };
  },
  methods: {
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.icon = "fa-eye-slash";
      } else {
        this.type = "password";
        this.icon = "fa-eye";
      }
    },
    onChange(id, value) {
      this.$emit("onChange", { id, value });
    },
    validatePassword(_id, event) {
      const minLength = 8;

      if (event.target.value.length < minLength) {
        this.state = false;
        return;
      }

      this.state = null;
    },
  },
};
</script>
