<template>
  <div>
    <b-card class="mb-3">
      <b-row>
        <b-col md="7">
          <h6 class="text-muted">{{ category }}</h6>
          <h5>{{ name }}</h5>
          <p v-if="!hasFileRegistered && !uploadedFile" class="text-muted mb-0">
            No hay documento
          </p>
          <div v-else>
            <b-button
              v-b-modal="`viewerFile${id}`"
              variant="link"
              class="p-0"
              @click="viewDocument(id)"
              >{{ nameFile }}</b-button
            >
            <a href="#" class="ml-1">
              <font-awesome-icon
                v-if="!hasFileRegistered"
                icon="fa-solid fa-times-circle"
                class="text-danger"
                @click="clearFile"
              />
            </a>
          </div>
        </b-col>
        <b-modal
          :id="`viewerFile${id}`"
          scrollable
          no-close-on-backdrop
          no-close-on-esc
          lazy
        >
          <template #modal-header>
            <h5>{{ name }}</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              @click="closeModal(id)"
            >
              ×
            </button>
          </template>
          <vue-pdf-embed v-if="contentFile" :source="contentFile" />
          <div v-else class="text-center">
            <b-spinner variant="primary" label="Cargando..."></b-spinner>
          </div>
          <template #modal-footer>
            <b-button variant="outline-primary" @click="closeModal(id)">
              Salir
            </b-button>
          </template>
        </b-modal>
        <b-col md="5" class="d-flex align-items-center justify-content-end">
          <b-button
            v-if="!hasFileRegistered && uploadedFile"
            variant="outline-secondary"
            size="lg"
            @click="showModal"
          >
            <font-awesome-icon icon="fa-solid fa-paper-plane" class="mr-2" />
            Enviar documento
          </b-button>
          <label
            v-if="!hasFileRegistered && !uploadedFile"
            class="custom-file-upload btn btn-outline-secondary btn-lg"
          >
            <input
              ref="fileInput"
              v-modal="file1"
              type="file"
              @change="previewFiles"
            />
            <font-awesome-icon icon="fa-solid fa-arrow-up" class="mr-2" />
            Subir archivo
          </label>
          <!-- alert document send -->
          <b-alert v-if="hasFileRegistered" variant="success" class="mb-0" show>
            <font-awesome-icon icon="fa-solid fa-check" class="mr-2" />
            Documento enviado
          </b-alert>
        </b-col>
      </b-row>
    </b-card>
    <b-modal :id="modalId" :ref="reference" title="Enviar documento">
      <p>¿Estás seguro que deseas enviar este documento?</p>
      <p>
        Se le notificará al propietario por correo que su documento ya está
        listo para ser revisado.
      </p>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-button size="lg" variant="outline-primary" @click="cancelUpload">
            Cancelar
          </b-button>
          <b-button
            size="lg"
            variant="primary"
            class="ml-2"
            @click="uploadFile"
          >
            Enviar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { mapGetters } from "vuex";

export default {
  name: "AdminRequestDocument",
  components: {
    VuePdfEmbed,
  },
  props: {
    id: {
      type: Number,
      default: -1,
    },
    category: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    file: {
      type: Object,
      default: null,
    },
    propertyId: {
      type: Number,
      default: -1,
    },
    reference: {
      type: Number,
      default: -1,
    },
    isEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      file1: null,
      fileData: {
        size: 0,
        type: "",
        content: "",
      },
      document: {},
    };
  },
  computed: {
    modalId() {
      return `sendFile-${this.id}`;
    },
    hasFileRegistered() {
      return this.file.name !== null ? true : false;
    },
    uploadedFile() {
      return this.file1 !== null ? true : false;
    },
    contentFile() {
      return this.file.name !== null
        ? this.getDocument.content
        : this.fileData.content;
    },
    nameFile() {
      return this.file.name !== null
        ? this.file.name.replace(/ /g, "_")
        : this.name.replace(/ /g, "_") + "." + this.fileData.type.split("/")[1];
    },
    ...mapGetters(["getDocument"]),
  },
  methods: {
    showModal() {
      this.$refs[this.reference].show();
    },
    cancelUpload() {
      this.$refs[this.reference].hide();
    },
    async uploadFile() {
      await this.$store.commit("setFileToUpload", {
        documentId: this.id,
        propertyId: this.propertyId,
        name: this.nameFile,
        size: this.fileData.size,
        type: this.fileData.type,
        content: this.fileData.content,
      });

      await this.$nextTick();

      this.$store.dispatch("uploadFile");

      this.file1 = null;

      this.$refs[this.reference].hide();
    },
    async previewFiles(event) {
      this.file1 = event.target.files[0].name;
      const dataFile = await this.readFileAsync(event.target.files[0]);
      this.fileData.size = event.target.files[0].size;
      this.fileData.type = event.target.files[0].type;
      this.fileData.content = dataFile;
      this.$store.commit("setFileToUpload", {
        documentId: this.id,
        propertyId: this.propertyId,
        name: this.nameFile,
        size: this.fileData.size,
        type: this.fileData.type,
        content: this.fileData.content,
      });
    },
    clearFile() {
      this.file1 = null;
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    },
    closeModal(id) {
      this.$bvModal.hide(`viewerFile${id}`);
      this.$store.commit("setDocument", {});
    },
    viewDocument(id) {
      this.$store.dispatch("viewDocument", {
        documentId: id,
        source: "document",
      });
    },
  },
};
</script>
