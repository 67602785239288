<template>
  <div class="home">
    <nav-bar />
    <side-bar />
    <div class="bg-light">
      <div class="content">
        <b-container>
          <b-row>
            <b-col sm="12">
              <router-view />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../components/misc/SideBar";
import NavBar from "../components/misc/Navbar";

export default {
  name: "Home",
  components: {
    SideBar,
    NavBar,
  },
};
</script>
