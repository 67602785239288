<template>
  <b-card header-class="bg-white py-3" body-class="px-0" class="mb-4">
    <template #header>
      <header-card
        title="Historial de transferencia"
        content="Información sobre las transferencias realizadas de esta propiedad"
        icon="clock-rotate-left"
        :documents="true"
      />
    </template>
    <b-table
      responsive
      class="text-nowrap mb-0"
      :fields="fields"
      :items="transferHistory"
      show-empty
      ><template #empty>
        <p class="w-100 mt-3">
          Esta propiedad no registra transferencias en los últimos 13 años.
        </p>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import HeaderCard from "./HeaderCard.vue";

export default {
  name: "HistoryTransfer",
  components: {
    HeaderCard,
  },
  props: {
    transferHistory: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      fields: [
        {
          key: "fecha",
          label: "Fecha",
          sortable: false,
          formatter: (value) => {
            if (typeof value !== "string") return "";

            const date = new Date(value);
            return date
              .toISOString()
              .slice(0, 10)
              .split("-")
              .reverse()
              .join("-");
          },
        },
        {
          key: "precioCompra",
          label: `Precio Compra`,
          sortable: false,
          formatter: (value) => {
            return `UF ${value}`;
          },
        },

        {
          key: "foja",
          label: "Foja",
          sortable: false,
        },
        {
          key: "numero",
          label: "Número",
          sortable: false,
        },
        {
          key: "año",
          label: "Año",
          sortable: false,
        },
        {
          key: "ufm2",
          label: "UF/m²",
          sortable: false,
        },
      ],
    };
  },
};
</script>
