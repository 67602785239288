<template>
  <div>
    <h2 class="mt-5">Documentos</h2>
    <b-tabs class="nav-tab-custom">
      <b-row class="my-4" align-h="between">
        <b-col cols="10" md="6" lg="7" xl="4">
          <b-form-select
            v-model="selected"
            :options="options"
            size="lg"
            class="pl-2 text-m"
          />
        </b-col>
        <b-col cols="2" md="" class="d-flex justify-content-end"
          ><b-button
            v-b-modal="'modal-new-document'"
            variant="primary"
            size="lg"
          >
            <font-awesome-icon icon="fa-solid fa-plus" class="mr-md-2" />
            <span class="d-none d-md-inline-block">Agregar documento</span>
          </b-button></b-col
        >
        <modal-new-document></modal-new-document>
      </b-row>
      <b-tab
        v-for="(type, index) in types"
        :key="index"
        :active="index == 0"
        :title="type"
      >
        <b-table
          hover
          :items="documents"
          :fields="fields"
          table-variant="light"
          outlined
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(document)="row">
            <h6 class="m-2">{{ row.item.document }}</h6>
          </template>

          <template #cell(value)="row">
            <h6 class="text-muted m-2">{{ row.value }}</h6>
          </template>

          <template #cell(deliveryTime)="row">
            <div class="d-flex justify-content-between">
              <h6 class="text-muted m-2">
                {{ row.item.deliveryTime }}

                
              </h6>
              <font-awesome-icon
                  class="text-muted my-auto"
                  icon="fa-solid fa-angle-right"
                />
            </div>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ModalNewDocument from "@/components/modals/ModalNewDocument.vue";
export default {
  name: "AdminDocuments",
  components: { ModalNewDocument },
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "Ordenar por" },
        { value: "a", text: "Todas" },
      ],
      types: ["Municipales", "Conservador", "Otros"],
      fields: [
        { key: "document", label: "Nombre documento" },
        { key: "value", label: "Valor" },
        { key: "deliveryTime", label: "Tiempo entrega" },
      ],
      documents: [
        {
          document: "Certificado Matrimonio Propietario",
          value: "$4.990",
          deliveryTime: "4 días",
        },
        {
          document: "Certificado Recepción Final",
          value: "$19.990",
          deliveryTime: "2 días",
        },
        {
          document: "Copia Inscripción Propiedad",
          value: "$9.990",
          deliveryTime: "3 días",
        },
        { document: "GP", value: "$6.990", deliveryTime: "1 día" },
        {
          document: "Certificado Número domiciliario",
          value: "$14.990",
          deliveryTime: "1 día",
        },
        {
          document: "Certificado Afectación pública",
          value: "$20.990",
          deliveryTime: "5 días",
        },
        {
          document: "Certificado Informes previos",
          value: "$3.990",
          deliveryTime: "3 días",
        },
        {
          document: "Certificado No expropiación Serviu",
          value: "$2.990",
          deliveryTime: "4 días",
        },
      ],
    };
  },
  methods: {
    onRowSelected(item) {
      this.$router.push({
        name: "AdminDocumentDetail",
        params: { doc: item[0].document, item: item[0] },
      });
    },
  },
};
</script>

<style></style>