<template>
  <b-container fluid>
    <b-row>
      <b-col md="8" lg="8" xl="8" class="col-xxl-5">
        <h2>Mi cuenta</h2>
        <b-tabs class="nav-tab-custom" content-class="mt-3">
          <b-tab title="Perfil" active>
            <form-profile />
          </b-tab>
          <b-tab title="Contraseña">
            <form-password />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FormProfile from "../../components/forms/FormProfile.vue";
import FormPassword from "../../components/forms/FormPassword.vue";

export default {
  name: "Profile",
  components: {
    FormProfile,
    FormPassword,
  },
};
</script>
