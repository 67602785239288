<template>
  <b-card class="mb-3">
    <b-row align-h="between">
      <b-col xl="5" md="6">
        <div class="media">
          <div class="icon-circle mr-3" :class="`bg-${button.variant}`">
            <font-awesome-icon :icon="['fa-solid', icon]" class="text-white" />
          </div>
          <div class="media-body">
            <h4>{{ title }}</h4>
            <p class="text-muted mb-2">{{ subtitle }}</p>
          </div>
        </div>
      </b-col>
      <b-col xl="6" md="6">
        <b-row align-h="end">
          <b-col v-if="hasInform" xl="6">
            <b-button
              class="mb-2"
              variant="outline-secondary"
              size="lg"
              block
              :disabled="!isLastCertificateDone"
              @click="
                $router.push({
                  name: 'ReportChoices',
                })
              "
              >Generar nuevo informe</b-button
            >
          </b-col>
          <b-col v-if="showButton" xl="6">
            <b-button
              class="mb-2"
              :variant="button.variant"
              size="lg"
              block
              :disabled="!isLastCertificateDone && !showPenultimateCertificate"
              @click="conduct()"
              >{{ button.caption }}
            </b-button>
          </b-col>
        </b-row>
        <!--
        <p
          v-if="showPenultimateCertificate"
          class="text-right text-muted small mt-2"
        >
          *Último Informe generado el {{ report.date }}
        </p>
        -->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import propertyService from "@/services/property.service";
export default {
  name: "ShortCuts",
  props: {
    title: {
      type: String,
      default: "Título",
      required: true,
    },
    subtitle: {
      type: String,
      default: "Contenido",
      required: true,
    },
    icon: {
      type: String,
      default: "file-lines",
    },
    button: {
      type: Object,
      default: {},
      required: true,
    },
    report: {
      type: Object,
      default: () => ({}),
    },
    hasInform: {
      type: Boolean,
      default: false,
    },
    isLastCertificateDone: {
      type: Boolean,
      default: true,
    },
    showPenultimateCertificate: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showButton() {
      if (this.button.caption !== "Ver informe") return true;
      else if (this.showPenultimateCertificate) return true;

      return false;
    },
    ...mapGetters(["getSelectedProperty"]),
  },
  methods: {
    async conduct() {
      if (this.title === "Certificados de la propiedad") {
        if (this.hasInform) {
          this.$router.push({
            name: "Report",
            query: {
              // generated: true,
              // type: this.report.inform,
              last: false,
            },
          });
        } else {
          this.$router.push({
            name: "ReportChoices",
          });
        }
      }
      else if (this.title === "Valorización de la propiedad") {
        let mytoken = localStorage.getItem("token");
        this.$store.commit("setLoading");
        this.checkindicators = await propertyService.checkIndicators({ token: mytoken, rol: this.getSelectedProperty.role, comune: this.getSelectedProperty.commune, landtype: "0" });
        this.$store.commit("setLoading");
        if (this.checkindicators.response === 'si') {
          this.$router.push({
            name: "Appraiser",
          });
        } 
        else if (this.checkindicators.response === 'comprar'){
          this.$bvToast.toast("Sin créditos", {
            title: "No tiene informes disponibles o se agotaron los informes mensuales disponibles de su suscripción. Lo llevaremos a nuestra página de compras para que pueda adquirir informes adicionales.",
            variant: "danger",
            solid: true,
            autoHideDelay: 12000,
          });
          setTimeout(() => {
            //this.$router.go(-1);
            this.$router.push({
              name: "Payment",
            }); 
          }, 9000);
          return;
        }
        else {
          this.$bvToast.toast("Gracias", {
            title: "La información compartida disponible, no nos permite mejorar la información gratuita. Recuerde revisar si existe algún cambio de destino de la propiedad, ya que solo se valorizan propiedades con destino habitacional. Lo llevaremos al buscador gratuito para que busque nuevamente.",
            variant: "success",
            solid: true,
            autoHideDelay: 12000,
          });
          setTimeout(() => {
            //this.$router.go(-1);
            this.$router.push({
              name: "InfoCheck",
            }); 
          }, 12000);
          return;
        }
      }
      else {
        if (this.title === "Solicitud de documentos") {
          this.$store.commit("setStep", 2);
          this.$store.commit(
            "setRequestSelectedProperty",
            this.$store.getters.getSelectedProperty
          );
          this.$router.push({
            name: this.button.pathName,
          });
        } else {
          this.$router.push({
            name: this.button.pathName,
            params: { unit: this.button.unit },
          });
        }
      }
    },
  },
};
</script>
