<template>
  <b-card class="mb-3">
    <checkout-item
      v-for="(item, index) in getSimpleDocumentsSelected"
      :id="item.id"
      :key="index"
      :price="item.price_sale"
      :title="item.name"
    ></checkout-item>
    <hr />
    <b-row>
      <b-col>
        <h5 class="mb-0">Total</h5>
      </b-col>
      <b-col class="text-right">
        <h5 class="mb-0">$ {{ totalSale }}</h5>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import CheckoutItem from "../../components/misc/CheckoutItem.vue";
import { mapGetters } from "vuex";

export default {
  name: "CheckoutList",
  components: { CheckoutItem },
  computed: {
    ...mapGetters(["getSimpleDocumentsSelected", "getDocumentsTotalSale"]),
    totalSale() {
      return new Intl.NumberFormat("es-CL").format(this.getDocumentsTotalSale);
    },
  },
  // destroyed() {
  //   this.$store.commit("restoreStep");
  //   this.$store.commit("restoreFinalStep");
  // },
};
</script>
