<template>
  <div class="d-flex position-relative">
    <div class="h-100 bg-white auth-content d-flex align-items-center">
      <b-container class="mt-5">
        <b-row class="justify-content-between">
          <b-col lg="8" class="mx-auto">
            <div class="p-3">
              <img
                src="../../assets/logo-iprop-green.svg"
                alt=""
                class="mb-5"
              />
              <p class="text-secondary mb-0">Paso {{ index + 1 }} de 2</p>
              <h2 class="mb-2">{{ info[index].title }}</h2>
              <p class="text-muted mb-4">{{ info[index].content }}</p>
              <form-register v-if="index == 0"></form-register>
              <form-set-password v-else></form-set-password>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="vh-100 bg-login d-none d-lg-flex align-items-center">
      <b-container class="mt-5">
        <b-row>
          <b-col lg="10" class="mx-auto">
            <h4 class="text-white mb-5">Tu propiedad en tus manos</h4>
            <h1 class="text-white">
              Desbloqueamos lo complejo del
              <span class="text-terciary">sector inmobiliario</span>
            </h1>
            <img
              src="../../assets/img-login.png"
              alt=""
              class="d-block img-fluid"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import FormRegister from "@/components/forms/FormRegister.vue";
import FormSetPassword from "@/components/forms/FormSetPassword.vue";

export default {
  name: "UserRegister",
  components: { FormRegister, FormSetPassword },
  data() {
    return {
      info: [
        {
          title: "Crea tu cuenta",
          content:
            "Para empezar, necesitamos algunos datos para poder crear tu cuenta.",
        },
        {
          title: "Crea tu contraseña",
          content:
            "Ahora debes crear tu contraseña, debe tener un mínimo de 8 caracteres.",
        },
      ],
    };
  },
  computed: {
    index() {
      return this.$store.getters.getRegisterStep - 1;
    },
  },
};
</script>
