import Data from './fakeBD.json';

export default {
    state: {
        database: Data,
    },
    getters: {
        getProperty(state) {
            var proprietary = state.database.users[0]['firstName'] + " " + state.database.users[0]['lastName'];

            var property = state.database.properties[0]

            Object.assign(property, { "proprietary": proprietary });
            return property;

        },
        getProperties(state) {
            var plan = state.database.users[0]['plan'];

            var properties = state.database.properties;
            properties.forEach(element => {

                Object.assign(element, { "plan": plan });
            });
            return properties;
        },
        getRequest(state) {
            return state.database.request
        },
        getRequestDocuments(state) {
            let cont = 0;
            var requestsId = state.database.request
            for (let index = 0; index < requestsId.length; index++) {
                const element = requestsId[index]['property_id'];
                if (element == "1") {
                    cont = index + 1
                }

            }
            return cont
        },
        getIssuedDocuments(state) {
            let cont = 0;
            var documentsId = state.database.documents
            for (let index = 0; index < documentsId.length; index++) {
                const element = documentsId[index]['property_id'];
                if (element == "1") {
                    cont = index + 1
                }

            }
            return cont
        },
        getFolders(state) {
            let cont = 0;
            var folders = state.database.folders
            var documents = state.database.documents

            for (let i = 0; i < folders.length; i++) {
                let fold = folders[i];
                for (let j = 0; j < documents.length; j++) {
                    if (fold["id"] == documents[j]["folder_id"]) {
                        cont = j + 1
                    }
                }

                Object.assign(folders[i], { "quantity": cont });

            }
            return folders
        },
        getSubFolders(state) {
            var cont = 0;
            var newSubFolders = [];
            var folderId = "1";
            var subFolders = state.database.sub_folders


            for (let j = 0; j < subFolders.length; j++) {
                if (folderId == subFolders[j]["folder_id"]) {
                    newSubFolders.push({ "title": subFolders[j]["title"] });

                }
            }

            var documents = state.database.documents
            for (let i = 0; i < subFolders.length; i++) {
                for (let j = 0; j < documents.length; j++) {
                    if (folderId == documents[j]["subfolder_id"]) {
                        cont = j + 1
                    }
                }
                if (folderId == subFolders[i]["folder_id"]) {
                    Object.assign(newSubFolders[i], { "quantity": cont });

                }
            }


            return newSubFolders
        },
        getShared(state) {
            return state.database.shared;
        },
        getDocumentStatus(state){
            return state.database.documents[0].status
        }
    }

};