<template>
  <b-modal
    id="modal-inscription"
    title="Encontrar inscripción de mi propiedad"
    size="lg"
  >
    <b-row>
      <b-col lg="12">
        <p class="text-muted">Para poder encontrar la Inscripción de Propiedad, recomendamos obtenerla de dos formas:</p>
      </b-col>
      <b-col lg="6">
        <b-card class="text-center mb-3">
          <img :src="require(`../../assets/logo-sii.png`)" class="mb-4" alt="">
          <h6>Servicio de Impuestos Internos (SII)</h6>
          <p class="small text-muted">Ingresando al SII con tu Clave Única o Clave Tributaria podrás acceder al indice de inscripción de tu propiedad en la sección de Avalúos y Contribuciones / Antecedentes de un bien raíz.</p>
          <b-button target="_blank" href="https://www4.sii.cl/busquedarolesinternetui/#!/busquedaroles" variant="primary">Ir a SII</b-button>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card class="text-center mb-3">
          <img :src="require(`../../assets/logo-cbr.png`)" class="mb-4" alt="">
          <h6>Conservador de Bienes Raíces de Santiago.</h6>
          <p class="small text-muted">Ingresa a la página del CBRS y en la sección de Consultas en Línea / Índice de Registro de Propiedad, podrás buscar por Apellidos del Propietario y Año de Compra de inscripción.</p>
          <b-button target="_blank" href="#" variant="primary">Ir a Conservador</b-button>
        </b-card>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button variant="secondary" @click="cancel()">
        Entendido
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ModalInscription"
}
</script>