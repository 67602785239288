<template>
  <div>
    <title-back back="Documentos">
      <template #title>
        <h4 class="my-3">{{ doc }}</h4>
      </template>
    </title-back>
    <b-row>
      <b-col cols="12" md="8" lg="7" xl="6">
        <b-card class="py-3 px-4">
          <b-form @submit="onSubmit">
            <b-form-group
              id="input-group-1"
              label="Precio"
              label-for="input-1"
              class="font-weight-bold"
            >
              <b-form-input id="input-1" v-model="form.price"></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Tiempo de entrega"
              label-for="input-2"
              class="font-weight-bold"
            >
              <b-form-select
                v-model="form.deliveryTime"
                :options="options"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Habilitado"
              label-for="input-2"
              class="d-flex"
            >
              <b-form-checkbox
                v-model="form.enabled"
                switch
                class="ml-2"
              ></b-form-checkbox>
            </b-form-group>
          </b-form>
        </b-card>
        <div class="d-flex justify-content-end mt-3">
          <b-button variant="primary" size="lg">Guardar cambios</b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TitleBack from "@/components/misc/TitleBack.vue";
export default {
  components: { TitleBack },
  props: {
    doc: { type: String, required: true },
    item: { type: Object, required: true },
  },
  data() {
    return {
      form: {
        price: "$ 4.990",
        deliveryTime: "d",
        enabled: true,
      },
      options: [
        { value: null, text: "Selecciona tiempo de entrega" },
        { value: "a", text: "1 día" },
        { value: "b", text: "2 días" },
        { value: "c", text: "3 días" },
        { value: "d", text: "4 días" },
      ],
    };
  },
  created() {
    this.form.price = this.item.value;
  },
  methods: {
    onSubmit() {},
  },
};
</script>

<style></style>
