<template>
  <div>
    <MiniMapAdmin />
  </div>
</template>

<script>
import MiniMapAdmin from "@/components/misc/MiniMapAdmin.vue";

export default {
  name: "AdminMap",
  components: { MiniMapAdmin },
};
</script>

<style></style>
