<template>
  <div v-if="!getFinalStep" class="container">
    <b-row class="justify-content-center">
      <b-col xl="6" lg="8" md="8">
        <title-back back="Propiedad" :route="-2"></title-back>
        <!-- <form-inscription v-if="getStep === 1" /> -->
        <form-payment v-if="getStep === 1" />
        <form-confirmation v-if="getStep === 2" />
      </b-col>
    </b-row>
  </div>
  <!-- <div v-else class="container">
    <b-row class="justify-content-center">
      <b-col xl="5" lg="6" md="6" class="text-center">
        <img
          :src="require(`../../assets/svg/checking-boxes.svg`)"
          class="mx-auto mb-4"
          alt=""
        />
        <h4>¡Listo!</h4>
        <p>
          Hemos procesado tu pago con éxito. Ya puedes ver la nueva información
          en tu ficha.
        </p>
        <p>
          Si tienes algun problema para visualizarlo no dudes en contactarnos
        </p>
        <b-button variant="primary" size="lg" @click="restoreFlow"
          >Entendido</b-button
        >
      </b-col>
    </b-row>
  </div> -->
</template>

<script>
import TitleBack from "@/components/misc/TitleBack.vue";
import FormInscription from "@/components/forms/FormInscription.vue";
import FormPayment from "@/components/forms/FormPaymentMethod.vue";
import FormConfirmation from "@/components/forms/FormConfirmation.vue";
import { mapGetters } from "vuex";

/* eslint-disable-next-line */
import reportService from "@/services/report.service";

export default {
  name: "InscriptionPayment",
  components: {
    TitleBack,
    FormInscription,
    FormPayment,
    FormConfirmation,
  },
  data() {
    return {
      userData: null,
      selectedProperty: null,
      reportType: null,
    };
  },
  computed: {
    ...mapGetters([
      "getStep",
      "getFinalStep",
      "getSelectedProperty",
      "getUserData",
      "getReportType",
    ]),
  },

  async mounted() {
    this.$store.commit("restoreStep");
    this.$store.commit("restoreFinalStep");
  },
  methods: {
    restoreFlow() {
      this.$router.push({
        path: "/corredor/propiedades",
      });
      this.$store.commit("restoreStep");
      this.$store.commit("restoreFinalStep");
    },
  },
};
</script>
