import axios from "axios";
import Config from "@/../config.json";
import login from "@/services/login.service";

const simpleRealEstateService = {
  async getSimpleRealEstate(values) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
    
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_BIEN_RAIZ_SIMPLE,
        values,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  async getAddressByRol(values) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
    
    try {
      const data = await axios.get(
        `${Config.VUE_APP_URL_BASE}/bff/v1/iproperty-bff/address-by-rol?region=${values.region}&comuna=${values.commune}&rol=${values.role}`,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default simpleRealEstateService;
