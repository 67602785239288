<template>
  <b-row class="mt-4">
    <b-col cols="12" md="4"
      ><b-form-select
        v-model="region"
        class="mb-md-4 my-2"
        :options="regions"
      ></b-form-select>
    </b-col>
    <b-col cols="12" md="4"
      ><b-form-select
        v-model="commune"
        class="mb-md-4 my-2"
        :options="communes"
      ></b-form-select>
    </b-col>
    <b-col cols="12" md="4"
      ><b-form-select
        v-model="week"
        class="mb-md-4 my-2"
        :options="thisWeek"
      ></b-form-select>
    </b-col>
 
      <b-col cols="12" md="6" xxl="3" 
        ><mini-card class="my-2" title="Solicitudes recibidas" :value="10"></mini-card
      ></b-col>

      <b-col cols="12" md="6" xxl="3" 
        ><mini-card class="my-2" title="Solicitudes en recolección" :value="6"></mini-card
      ></b-col>

      <b-col cols="12" md="6" xxl="3" 
        ><!-- align-self="end" -->
        <mini-card class="my-2" title="Solicitudes atrasadas" :value="4"></mini-card
      ></b-col>
      <b-col cols="12" md="6" xxl="3" 
        ><mini-card class="my-2" title="Documentos vendidos" :value="2"></mini-card
      ></b-col> 
  </b-row>
</template>

<script>
import MiniCard from "../cards/MiniCard.vue";
export default {
  name: "AdminSummary",
  components: { MiniCard },
  data() {
    return {
      region: null,
      regions: [
        { value: null, text: "Todas las regiones" },
        { value: "a", text: "Ascendente", disabled: false },
        { value: "b", text: "Descendente", disabled: false },
      ],
      commune: null,
      communes: [
        { value: null, text: "Todas las comunas" },
        { value: "a", text: "Ascendente", disabled: false },
        { value: "b", text: "Descendente", disabled: false },
      ],
      week: null,
      thisWeek: [
        { value: null, text: "Esta semana" },
        { value: "a", text: "Ascendente", disabled: false },
        { value: "b", text: "Descendente", disabled: false },
      ],
    };
  },
};
</script>
