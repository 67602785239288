<template>
  <b-list-group class="mt-3 mb-4">
    <document-request-item
      v-for="doc in documents"
      :id="doc.id"
      :key="doc.id"
      :title="doc.name"
      :price="doc.price_sale"
    ></document-request-item>
    <b-card v-if="documents.length === 0">
      <b-row class="justify-content-center">
        <b-col cols="8" md="5">
          <img :src="require(`../../assets/img-empty.svg`)" alt="Empty state" class="my-4 mx-auto d-block w-100">
          <p class="text-center text-muted">No hay documentos para mostrar</p>
        </b-col>
      </b-row>
    </b-card>
  </b-list-group>
</template>

<script> 
import DocumentRequestItem from "./DocumentRequestItem.vue";
export default {
  name: "DocumentRequestList",
  components: { DocumentRequestItem }, 
  props: ["documents"],  
};
</script>
 