<template>
    <div class="container">
        <b-row class="mb-4">
            <b-card-group class="justify-content-center" deck>
                <b-card
                header="Detalles de la Transacción"
                header-tag="header"
                title="Resumen"
                >
                    <b-card-text v-if="transactionStatus === 1">
                        <b-icon icon="x-circle" variant="warning" font-scale="2"></b-icon>
                        La transacción está pendiente de pago. Por favor, revise su email y siga las instrucciones para completar el pago.
                        <b-table striped hover :items="transactionDetails" :fields="tableFields"></b-table>
                    </b-card-text>
                    <b-card-text v-else-if="transactionStatus === 2">
                        <b-icon icon="check-circle" variant="success" font-scale="2"></b-icon>
                        La transacción fue exitosa. Aquí están los detalles:
                        <b-table striped hover :items="transactionDetails" :fields="tableFields"></b-table>
                    </b-card-text>
                    <b-card-text v-else-if="transactionStatus === 3">
                        <b-icon icon="x-circle" variant="danger" font-scale="2"></b-icon>
                        La transacción fue rechazada. Por favor, envienos un email a <a href="mailto:pagos@inciti.com">pagos@inciti.com</a> señalando que tuvo un problema con el pago y adjuntando los siguientes detalles:
                        <b-table striped hover :items="transactionDetails" :fields="tableFields"></b-table>
                    </b-card-text>
                    <b-card-text v-else-if="transactionStatus === 4">
                        <b-icon icon="x-circle" variant="danger" font-scale="2"></b-icon>
                        La transacción fue anulada. Por favor, envienos un email a <a href="mailto:pagos@inciti.com">pagos@inciti.com</a> señalando que tuvo un problema con el pago y adjuntando los siguientes detalles:
                        <b-table striped hover :items="transactionDetails" :fields="tableFields"></b-table>
                    </b-card-text>
                    <b-button @click="gotoCredits()" variant="primary">Ver créditos disponibles</b-button>
                </b-card>
            </b-card-group>
        </b-row>
    </div>
</template>

<script>
import propertyService from "@/services/property.service";
export default {
    name: "PaymentReturn",
    data() {
        return {
            transactionStatus: null,
            transactionDetails: [],
            tableFields: [
                { key: 'key', label: 'Item' },
                { key: 'value', label: 'Detalle' }
            ]
        };
    },
    async beforeMount() {
        this.$store.commit("setLoading");
        let mybody = {
            "uuid": this.$route.params.uuid
        };
        propertyService.getOrderStatus(mybody).then((response) => {
            console.log("response");
            console.log(response);
            this.transactionStatus = response.data.status;
            this.setTransactionDetails(response.data);
            this.$store.commit("setLoading");
        })
        .catch((error) => {
            console.log(error);
            this.$store.commit("setLoading");
        });
    },
    methods: {
        setTransactionDetails(data) {
            let details = [
                { key: 'N° Orden Flow', value: data.flowOrder },
                { key: 'N° Orden Inciti', value: data.commerceOrder },
            ];
            if (this.transactionStatus === 1) {
                details.push(
                    { key: 'Fecha Solicitud', value: data.requestDate },
                    { key: 'Monto', value: `${data.amount} ${data.currency}` }
                );
            }
            if (this.transactionStatus === 2) {
                details.push(
                    { key: 'Fecha Pago', value: data.paymentData.date },
                    { key: 'Medio Pago', value: data.paymentData.media },
                    { key: 'Monto Pagado', value: data.paymentData.amount }
                );
            }
            this.transactionDetails = details;
        },
        gotoCredits() {
            this.$router.push({ name: 'PaymentCredits' });
        }
    }
};
</script>
