<template>
  <b-list-group-item class="p-0" :disabled="false" :href="href">
    <div
      class="py-2 pr-3 pl-4 my-1 w-100 h-100 d-flex justify-content-between align-items-center"
      @click="onClick"
    >
      <span class="menu-item-title">{{ title }}</span>
      <b-badge
        v-if="typeReport !== 'full' && href === '#mortgage'"
        variant="warning"
        pill
        >Full</b-badge
      >
      <b-badge
        v-else-if="typeReport === 'basic' && isBasic"
        variant="warning"
        pill
        >Premium</b-badge
      >
      <div v-else class="state-icon">
        <font-awesome-icon
          icon="fa-solid fa-check-circle"
          class="text-success mr-2"
          size="lg"
        />
      </div>
    </div>
  </b-list-group-item>
</template>

<script>
export default {
  name: "NavMenuItem",
  props: {
    title: {
      type: String,
      default: "Resumen propiedad",
    },
    typeReport: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: "",
    },
  },
  computed: {
    isBasic() {
      return this.href === "#resume" ||
        this.href === "#owners" ||
        this.href === "#municipal" ||
        this.href === "#contributions"
        ? false
        : true;
    },
  },
  methods: {
    onClick() {
      this.$emit("click", {
        title: this.title,
        typeReport: this.typeReport,
        href: this.href,
      });
    },
  },
};
</script>
