<template>
  <div class="mb-3">
    <b-row>
      <b-col cols="8">
        <p class="m-0 text-muted">
          {{ title }}
        </p>
      </b-col>
      <b-col cols="4">
        <p v-if="price > 0" class="m-0 text-right">
          $ {{ price }}
        </p>
        <p v-else class="m-0 text-right">
          Gratis
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "CheckoutItem",
  props: ["title", "price"],
};
</script>
