<template>
  <b-container>
    <b-row>
      <b-col md="6">
        <title-back back="Propiedad"></title-back>
        <h2>Valor de mercado de la propiedad</h2>
        <!--Si indicadores.barrio contiene el caracter +, se debe separar el string en dos y mostrar solo la primera parte-->
        <p class="text-muted mb-4" v-if="indicadores.barrio.includes('+')">
          Considerando que la propiedad se encuentra en el barrio <b>{{indicadores.barrio.replace(/_/g,' ').split('+')[0]}}</b>, en el sector <b>{{indicadores.barrio.split('+')[1].replace(/_/g,' ')}}</b>, el tipo de propiedad es: <b>{{tipoPropiedad}}</b> de <b>{{indicadores.sup_util}} m2</b> y fue construida el año <b>{{indicadores.año}}</b>, estas son las propiedades similares que <b>han tenido al menos una compraventa en el último tiempo</b> y que hemos encontrado en el barrio y en el sector específico:
        </p>
        <!--Si no contiene el caracter +, se muestra el string completo-->
        <p class="text-muted mb-4" v-else>
          Considerando que la propiedad se encuentra en el barrio <b>{{indicadores.barrio.replace(/_/g,' ')}}</b>, el tipo de propiedad es: <b>{{tipoPropiedad}}</b> de <b>{{indicadores.sup_util}} m2</b> y fue construida el año <b>{{indicadores.año}}</b>, estas son las propiedades similares que <b>han tenido al menos una compraventa en el último tiempo</b> y que hemos encontrado en el barrio:
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-table style="font-size:10px;" striped responsive small :items="indicadores.vecinos" :fields="vecinosfields"></b-table>
      </b-col>
    </b-row>
    <b-row>
      <!--
      <b-col md="6">
        <p class="text-muted mb-4">
          Acá te mostramos la ubicación de tu propiedad en el barrio y una muestra de las propiedades vecinas encontradas:
        </p>
      </b-col>
      -->
      <b-col sm="12" lg="6">
        <MiniMap2
            id="map1"
            v-if="neighborsToMap.features[0].features.length > 0"
            :min-height="minHeight"
            :property-info="propertyInfo"
            :show-tooltips="false"
            :neighborsToMap="neighborsToMap"
        />
     </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col md="6">
        <!--
        <p class="text-muted mb-1" style="font-size: 10px;">
          <b>Valorización de esta propiedad (UF):</b> Representa el rango de valores (mínimo, promedio y máximo), que nuestro predictor de inteligencia artificial entrega a tu propiedad. Para ello utiliza el valor de mercado, además del año de construcción, superficie de construcción, superficie de terreno y materialidad entre otros, y asi utilizar propiedades similares en el análisis. Puedes deslizar el control para seleccionar el precio al que deseas vender la propiedad y ver en el mapa que propiedades se vendieron por sobre (rojas) o bajo este precio (verdes), junto con la ubicación de tu propiedad (amarillo).
        </p>
        -->
        <p class="text-muted mb-1" style="font-size: 12px;">
          <b>Valorización de esta propiedad (UF):</b> Representa el rango de valores (mínimo, promedio y máximo), que nuestro predictor de inteligencia artificial entrega a tu propiedad. Para ello utiliza el valor de compraventas de propiedades del barrio junto con el valor de oferta de venta y oferta de arriendo en portales de propiedades, además del año de construcción, superficie de construcción, superficie de terreno y materialidad entre otros, y asi utilizar propiedades similares en el análisis. Puedes ver en el mapa que propiedades se vendieron por sobre este precio promedio (rojas) o bajo este precio promedio (verdes), junto con la ubicación de tu propiedad (amarillo).
        </p>
      </b-col>
      <!--
      <b-col class="mb-5 mb-lg-0" md="6" offset="0">
        <VueSlideBar
        v-model="valueuf"
        :min="minValuePropuf"
        :max="maxValuePropuf"
        :processStyle="slider.processStyle"
        :lineHeight="slider.lineHeight"
        :tooltipStyles="{ backgroundColor: 'red', borderColor: 'red' }"
        @input="callbackRange" >   
        </VueSlideBar>
      </b-col>
      -->
    </b-row>
    <b-row>
      <b-col class="mb-1 mb-lg-0" md="6" offset="0">
        <apexchart id="chart2" type="radialBar" height="350" :options="chartOptions2" :series="series2"></apexchart>
      </b-col>
    </b-row>
    <hr>
    <!--
    <b-row>
      <div id="kpis" name="kpis" ref="kpis">
        
        <b-col md="6">
          <p class="text-muted mb-1" style="font-size: 10px;">
            <b>Ratio Avalúo/Compraventa:</b> Este ratio indica que tan cerca del valor de mercado el SII evalúa las propiedades del sector. Se calcula dividiendo el avaluo de cada propiedad por su valor de compraventa (solo si tiene) y luego calculando el promedio, valor maximo y mínimo. Un valor alto sobre el promedio del vecindario indica que la compraventa subevalúa el real valor al que se podría vender. Por el contrario, un valor bajo indica que la compraventa sobrevalúa el real valor al que se podría vender. 
          </p>
        </b-col>
        <b-col class="mb-3 mb-lg-0" md="6" offset="0">
          <apexchart id="chart1" type="radialBar" height="350" :options="chartOptions1" :series="series1" @rendered="chartRendered"></apexchart>
        </b-col>
        
        <b-col md="6">
          <p class="text-muted mb-1" style="font-size: 10px;">
            <b>Valores de mercado del barrio (UF):</b> Representa el rango de valores de mercado en el barrio al que pertenece tu propiedad (min, prom, max). Buscamos todas las propiedades que han tenido compraventa en el barrio durante el último tiempo y calculamos todos los Ratios (avalúo fiscal/valor de compraventa). Luego con el promedio de los Ratios de Avalúo/Compraventa se obtiene el precio en UF de cada propiedad en la zona (Valor Propiedad = Avalúo Propiedad/Ratio Promedio). Luego calculamos el rango (max, min) y el valor promedio de las propiedades en UF en la zona.
          </p>
        </b-col>
        <b-col class="mb-1 mb-lg-0" md="6" offset="0">
          <apexchart id="chart3" type="radialBar" height="350" :options="chartOptions3" :series="series3"></apexchart>
        </b-col>
      </div>
    </b-row>
    <hr>
    -->
    <b-row v-if="ventas.propiedades.length == 0">
      <b-col md="6">
        <p class="text-muted mb-1" style="font-size: 12px;">
          <b>Oferta de propiedades en venta (UF):</b> No se han encontrado propiedades comparables en venta en portales inmobiliarios para el barrio {{indicadores.barrio}}.
        </p>
      </b-col>
    </b-row>
    <b-row v-if="ventas.propiedades.length > 0">
      <b-col md="6">
        <p class="text-muted mb-1" style="font-size: 12px;" v-if="indicadores.barrio.includes('+')">
          <b>Oferta de propiedades en venta (UF):</b> Tabla con algunas de las propiedades similares en venta en el barrio {{indicadores.barrio.split('+')[1].replace(/_/g,' ')}}.
        </p>
        <p class="text-muted mb-1" style="font-size: 12px;" v-else>
          <b>Oferta de propiedades en venta (UF):</b> Tabla con algunas de las propiedades similares en venta en el barrio {{indicadores.barrio.replace(/_/g,' ')}}.
        </p>
      </b-col>
    </b-row>
    <b-row v-if="ventas.propiedades.length > 0">
      <b-col md="6">
        <b-table style="font-size:10px;" striped responsive small :items="ventas.propiedades" :fields="ventasfields">
          <template v-slot:cell(valoruf)="data">
            {{ formatNumber(data.value) }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="ventas.propiedades.length > 0">
      <b-col md="6">
        <p class="text-muted mb-1" style="font-size: 12px;">
          El valor promedio de ofeta de venta de las propiedades es: <b>{{promventasvaloruf}} UF</b> mientras que el valor promedio del m2 es: <b>{{promventasufm2}} UF/m2</b>
        </p>
      </b-col>
    </b-row>
    <hr>
    <b-row v-if="arriendos.propiedades.length == 0">
      <b-col md="6">
        <p class="text-muted mb-1" style="font-size: 12px;">
          <b>Oferta de propiedades en arriendo (CLP):</b> No se han encontrado propiedades comparables en arriendo en portales inmobiliarios para el barrio {{indicadores.barrio}}.
        </p>
      </b-col>
    </b-row>
    <b-row v-if="arriendos.propiedades.length > 0">
      <b-col md="6">
        <p class="text-muted mb-1" style="font-size: 12px;" v-if="indicadores.barrio.includes('+')">
          <b>Oferta de propiedades en arriendo (CLP):</b> Tabla con algunas de las propiedades similares en arriendo en el barrio {{indicadores.barrio.split('+')[1].replace(/_/g,' ')}}.
        </p>
        <p class="text-muted mb-1" style="font-size: 12px;" v-else>
          <b>Oferta de propiedades en arriendo (CLP):</b> Tabla con algunas de las propiedades similares en arriendo en el barrio {{indicadores.barrio.replace(/_/g,' ')}}.
        </p>
      </b-col>
    </b-row>
    <b-row v-if="arriendos.propiedades.length > 0">
      <b-col md="6">
        <b-table style="font-size:10px;" striped responsive small :items="arriendos.propiedades" :fields="arriendosfields">
          <template v-slot:cell(valorclp)="data">
            {{ '$' + formatNumber(data.value) }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="arriendos.propiedades.length > 1">
      <b-col md="6">
        <p class="text-muted mb-1" style="font-size: 12px;">
          El valor promedio de oferta de arriendo de las propiedades es: <b>${{promarriendovalorclp}}</b>. Para el cálculo del valor de la propiedad, se consideran 10.5 meses de arriendo a una tasa de descuento del 3.9%, lo que da un valor promedio del m2 de: <b>{{promarriendoufm2}} UF/m2.</b>
        </p>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col md="6">
        <!--
        <p class="text-muted mb-4">
          Ahora con el precio que has dado a la propiedad: <b> {{valuePropuf}} UF </b>, puedes <b>generar un informe más detallado</b> de la propiedad y su entorno, que te ayudará a tomar una mejor decisión de venta. También <b>puedes solicitar un tasador</b> para que te ayude a valorizar la propiedad con mayor detalle y por último, dentro de poco, podrás <b>publicar la propiedad</b> directamente en los portales más importantes de Chile: Portalinmobiliario de MercadoLibre, TocToc y Yapo. 
        </p>

        <p class="text-muted mb-4">
          Ahora puedes <b>generar un informe más detallado</b> de la propiedad y su entorno, que te ayudará a tomar una mejor decisión de venta. También <b>puedes solicitar un tasador</b> para que te ayude a valorizar la propiedad con mayor detalle y por último, dentro de poco, podrás <b>publicar la propiedad</b> directamente en el portal de propiedades del Mercurio.
        </p>
        -->
        <p class="text-muted mb-4">
          Ahora puedes <b>generar un informe más detallado</b> de la propiedad y su entorno, que te ayudará a tomar una mejor decisión de venta. También <b>puedes solicitar un experto inmobiliario</b> para que te ayude a valorizar la propiedad con mayor detalle.
        </p>
      </b-col>
      <!--Agregar 3 botones: Uno para cada acción-->
      <b-col md="6">
        <b-row>
          <b-button :disabled="buttonDisabled" size="lg" class="mb-3" variant="danger" @click="sendToReport">
            <i class="fa fa-file-pdf"></i> Generar informe
          </b-button>
        </b-row>
        <b-row>
          <b-button size="lg" class="mb-3" variant="primary" @click="sendToAppraiser">
            <i class="fa fa-calculator"></i> Solicitar experto inmobiliario
          </b-button>
        </b-row>
        <!--
        <b-row>
          <b-button disabled size="lg" class="mb-3" variant="primary" @click="sendToPublish">
            <i class="fa fa-cloud-upload"></i> Publicar en el Mercurio
          </b-button>
        </b-row>
        -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import TitleBack from "@/components/misc/TitleBack.vue";
import axios from "axios";
import Config from "@/../config.json";
import MiniMap2 from "@/components/MiniMap2.vue";
import * as turf from '@turf/turf';
import VueSlideBar from 'vue-slide-bar'
import { eventBus } from '../../main';
import propertyService from "@/services/property.service";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import login from "@/services/login.service";

export default {
  name: "Appraiser",

  components: {
    TitleBack,
    MiniMap2,
    VueSlideBar
  },
  data() {
    return {
      imagenes: [],
      buttonDisabled: true,
      promventasufm2: 0,
      promventasvaloruf: 0,
      promarriendoclpm2: 0,
      promarriendoufm2: 0,
      promarriendovalorclp: 0,
      indicators: {},
      indicadores: {},
      vecinosfields: [
        {
          key: 'direccion',
          label: 'Dirección',
          sortable: false
        },
        {
          key: 'valoruf',
          label: 'Valor',
          sortable: true
        },
        {
          key: 'supconstruida',
          label: 'Superficie',
          sortable: true,
        },
        {
          key: 'año',
          label: 'Año Const.',
          sortable: true,
        },
        {
          key: 'ufm2',
          label: 'UF/m2',
          sortable: true,
        }
      ],
      ventasfields: [
        {
          key: 'propiedad',
          label: 'Propiedad #',
          sortable: false
        },
        {
          key: 'valoruf',
          label: 'Valor',
          sortable: true
        },
        {
          key: 'supconstruida',
          label: 'Superficie',
          sortable: true,
        },
        {
          key: 'ufm2',
          label: 'UF/m2',
          sortable: true,
        }
      ],
      arriendosfields: [
        {
          key: 'propiedad',
          label: 'Propiedad #',
          sortable: false
        },
        {
          key: 'valorclp',
          label: 'Valor',
          sortable: true
        },
        {
          key: 'supconstruida',
          label: 'Superficie',
          sortable: true,
        }
      ],
      ventas: {"propiedades":[]},
      arriendos: {"propiedades":[]},
      tipoPropiedad: "Departamento",
      minHeight: "200px",
      neighborsToMap: {},
      hasPremium: false,
      showWarningOrButton: false,
      value: 0.76, // Aquí define el valor que deseas usar para valueToPercent
      maxValue: 0.98, // Agrega maxValue y minValue aquí
      minValue: 0.21,
      promValue: 0.58,
      valueuf: 105, // Aquí define el valor que deseas usar para valueToPercent
      maxValueuf: 118, // Agrega maxValue y minValue aquí
      minValueuf: 97,
      promValueuf: 108,
      maxValuePropuf: 2000, // Agrega maxValue y minValue aquí
      minValuePropuf: 1800,
      promValuePropuf: 1800,
      series1: [this.valueToPercent(0.55,0.65,0.44)], // Llama a la función aquí
        chartOptions1: {
        colors: ["red"],
        chart: {
            height: 350,
            type: 'radialBar',
            offsetY: -10
        },
        plotOptions: {
            radialBar: {
            startAngle: -135,
            endAngle: 135,
            
            dataLabels: {
                name: {
                fontSize: '17px',
                color: 'darkblue',
                offsetY: 0
                },
                value: {
                offsetY: 85,
                fontSize: '16px',
                color: 'darkblue',
                formatter: (val) => {
                  //const valor = (val * (this.maxValue - this.minValue)) / 100 + this.minValue;
                  const valor = this.promValue;
                  return "Min: "+ this.minValue + " - Prom: "+ this.promValue + " - Max: "+ this.maxValue;
                },
                }
            }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
                gradientToColors: ["#6aa84f"],
            },
        },
        stroke: {
            dashArray: 4
        },
        labels: ["Ratio A/CV"],
        }
        ,
      series2: [this.valueToPercent(this.valueuf,118,97)], // Llama a la función aquí
        chartOptions2: {
        colors: ["red"],
        chart: {
            height: 350,
            width: 500,
            type: 'radialBar',
            offsetY: -10
        },
        plotOptions: {
            radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
                name: {
                fontSize: '17px',
                color: 'darkblue',
                offsetY: 0
                },
                value: {
                offsetY: 85,
                fontSize: '16px',
                color: 'darkblue',
                formatter: (val2) => {
                  const valor = (val2 * (this.maxValuePropuf - this.minValuePropuf)) / 100 + this.minValuePropuf;
                  return "Min: "+ this.minValuePropuf + " - Prom: " + this.promValuePropuf + " - Max: "+ this.maxValuePropuf;
                }
                }
            }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
                gradientToColors: ["#6aa84f"],
            },
        },
        stroke: {
            dashArray: 4
        },
        labels: ["Rango de valor;de tu propiedad"],
        },
      series3: [this.valueToPercent(this.valueuf,118,97)], // Llama a la función aquí
        chartOptions3: {
        colors: ["red"],
        chart: {
            height: 350,
            type: 'radialBar',
            offsetY: -10
        },
        plotOptions: {
            radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
                name: {
                fontSize: '17px',
                color: 'darkblue',
                offsetY: 0,
                },
                value: {
                offsetY: 85,
                fontSize: '16px',
                color: 'darkblue',
                formatter: (val3) => {
                  const valor = (val3 * (this.maxValueuf - this.minValueuf)) / 100 + this.minValueuf;
                  return "Min: "+ this.minValueuf + " - Prom: " + this.promValueuf + " - Max: "+ this.maxValueuf;
                }
                }
              }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
                gradientToColors: ["#6aa84f"],
            },
        },
        stroke: {
            dashArray: 4
        },
        labels: ["Rango de valores;del barrio"],
        },

      rangeValue: {},
      
      slider: {
        value: 48,
        data: [
        15,
        30,
        45,
        60,
        75
        ],
        range: [
          {
            label: '15 mins'
          },
          {
            label: '30 mins',
            isHide: true
          },
          {
            label: '45 mins'
          },
          {
            label: '1 hr',
            isHide: true
          },
          {
            label: '1 hr 15 mins'
          },
          {
            label: '1 hr 30 mins',
            isHide: true
          },
          {
            label: '2 hrs'
          }
        ]
      }
    };
  },
  computed: {
    propertyInfo() {
      console.log("propertyInfo");
      console.log(this.getSelectedProperty);
      return {
        geometry: this.getSelectedProperty.geojson,
      };
    },
    ...mapGetters(["getSelectedProperty","setBoundingBox","getInfoProperty","getImagenes","getIndicators"]),
  },
  async beforeMount() {
    this.$store.commit("setLoading");
    //this.callGetImages(this.getSelectedProperty.id);
    //this.imagenes = await propertyService.getImages(this.getSelectedProperty.id);
    
    // Verificar si las imágenes ya están cargadas en el store
    console.log("imagenes");
    console.log(this.$store.getters.getImagenes);
    //si imagenes es undefined
    if (this.$store.getters.getImagenes.length > 0) {
      console.log("imagenes2");
      this.imagenes = this.$store.getters.getImagenes;
      this.buttonDisabled = false;
    } else {
      // Llamada al servicio si no hay datos en el store
      
      propertyService.getImages(this.getSelectedProperty.id).then((response) => {
        console.log("response");
        console.log(response);
        this.imagenes = response;
        this.$store.commit("setImagenes", response); // Guardar en el store
        this.buttonDisabled = false;
      })
      .catch((error) => {
        console.log(error);
        this.buttonDisabled = false;
      });
      
    }

    // Verificar si las imágenes ya están cargadas en el store
    console.log("indicators");
    console.log(this.$store.getters.getIndicators);
    // si kpi no está en this.getIndicators
    const myindicators = this.$store.getters.getIndicators;
    if (Object.keys(myindicators).length === 0)
    {
      // Llamada al servicio si no hay datos en el store
      
      const body = {
          token: '$a89fd6a$_l',
          rol: this.getSelectedProperty.role,
          comune: this.getSelectedProperty.commune,
          landtype: "0"
      }

      try {
        await this.$store.dispatch("fetchInfoSearchByRole", body);
      } catch (error) {
        console.log(error);
      } 
      
      this.indicators = await propertyService.getIndicators({ token: '$a89fd6a$_l', rol: this.getSelectedProperty.role, comune: this.getSelectedProperty.commune, landtype: "0" });
      this.$store.commit("setIndicators", this.indicators); // Guardar en el store
    } else {
      this.indicators = myindicators;
    }
  
    if (this.indicators.kpi) {
      console.log(this.indicators);
      // Reemplaza las comillas simples por comillas dobles
      const jsonStringConComillasDobles = this.indicators.kpi.replace(/'/g, '"');

      // Parsea la cadena JSON
      this.indicadores = JSON.parse(jsonStringConComillasDobles);
      console.log(this.indicadores);
      if (this.indicadores.destino == "DEPTO") {
        this.tipoPropiedad = "Departamento";
      }
      else if (this.indicadores.destino == "CASA") {
        this.tipoPropiedad = "Casa";
      }
      else if (this.indicadores.destino == "O") {
        this.tipoPropiedad = "Oficina";
      }
      else if (this.indicadores.destino == "A") {
        this.tipoPropiedad = "Agrícola";
      }
      else if (this.indicadores.destino == "B") {
        this.tipoPropiedad = "Agroindustrial";
      }
      else if (this.indicadores.destino == "M") {
        this.tipoPropiedad = "Minería";
      }
      else if (this.indicadores.destino == "C") {
        this.tipoPropiedad = "Comercio";
      }
      else if (this.indicadores.destino == "P") {
        this.tipoPropiedad = "Administración Pública y Defensa";
      }
      else if (this.indicadores.destino == "D") {
        this.tipoPropiedad = "Deporte y Recreación";
      }
      else if (this.indicadores.destino == "Q") {
        this.tipoPropiedad = "Culto";
      }
      else if (this.indicadores.destino == "E") {
        this.tipoPropiedad = "Educación y Cultura";
      }
      else if (this.indicadores.destino == "S") {
        this.tipoPropiedad = "Salud";
      }
      else if (this.indicadores.destino == "F") {
        this.tipoPropiedad = "Forestal";
      }
      else if (this.indicadores.destino == "T") {
        this.tipoPropiedad = "Transporte y Telecomunicaciones";
      }
      else if (this.indicadores.destino == "G") {
        this.tipoPropiedad = "Hotel, Motel";
      }
      else if (this.indicadores.destino == "V") {
        this.tipoPropiedad = "Otros no considerados";
      }
      else if (this.indicadores.destino == "W") {
        this.tipoPropiedad = "Sitio Eriazo";
      }
      else if (this.indicadores.destino == "I") {
        this.tipoPropiedad = "Industria";
      }
      else if (this.indicadores.destino == "Y") {
        this.tipoPropiedad = "Gallineros, chancheras y otros";
      }
      else if (this.indicadores.destino == "L") {
        this.tipoPropiedad = "Bodega y Almacenaje";
      }
      else if (this.indicadores.destino == "Z") {
        this.tipoPropiedad = "Estacionamiento";
      }
      else {
        this.tipoPropiedad = "Otro";
      }
      this.minValue = this.indicadores.kpi_ratio.min;
      this.maxValue = this.indicadores.kpi_ratio.max;
      this.promValue = this.indicadores.kpi_ratio.mean;
      //update chart 1
      this.series1 = [this.valueToPercent(this.indicadores.kpi_ratio.mean,this.indicadores.kpi_ratio.max,this.indicadores.kpi_ratio.min)];
      //this.chartOptions1.labels = [this.indicadores.kpi_ratio.mean.toString()];

      this.minValuePropuf = this.indicadores.kpi_ufvalue.min;
      this.maxValuePropuf = this.indicadores.kpi_ufvalue.max;
      this.promValuePropuf = this.indicadores.kpi_ufvalue.mean;
      this.valueuf = this.indicadores.kpi_ufvalue.mean;
      console.log("valueuf");
      console.log(this.valueuf);
      //update chart 2
      this.series2 = [this.valueToPercent(this.indicadores.kpi_ufvalue.mean,this.indicadores.kpi_ufvalue.max,this.indicadores.kpi_ufvalue.min)];
      
      //update chart 3
      //this.chartOptions3.labels = [this.indicadores.kpi_ufvalue.mean.toString()];

      //If there are more than 15 characters, break it up using one space as a delimiter
      this.chartOptions2.labels = this.chartOptions2.labels.map(label => {
          return label.length > 15 ? label.split(";") : label;
      })

      this.chartOptions3.labels = this.chartOptions3.labels.map(label => {
          return label.length > 15 ? label.split(";") : label;
      })

      this.minValueuf = this.indicadores.kpi_marketvalue.min;
      this.maxValueuf = this.indicadores.kpi_marketvalue.max;
      this.promValueuf = this.indicadores.kpi_marketvalue.mean;
      this.series3 = [this.valueToPercent(this.indicadores.kpi_marketvalue.mean,this.indicadores.kpi_marketvalue.max,this.indicadores.kpi_marketvalue.min)];

      
    }
    else {
      this.$bvToast.toast("Gracias", {
        title: "La información compartida disponible, no nos permite mejorar la información gratuita. Recuerde revisar si existe algún cambio de destino de la propiedad, ya que solo se valorizan propiedades con destino habitacional. Lo llevaremos al buscador gratuito para que busque nuevamente.",
        variant: "success",
        solid: true,
        autoHideDelay: 20000,
      });
      setTimeout(() => {
        this.$store.commit("setLoading");
        //this.$router.go(-1);
        this.$router.push({
          name: "InfoCheck",
        }); 
      }, 12000);
      return;
    }
    
    if (this.indicators.geojson) {
      console.log(this.indicators.geojson);
      // Obtener las coordenadas del polígono en el objeto GeoJSON
      const polygonCoordinates = this.indicators.geojson.features[0].geometry.coordinates[0];
      const bbox2 = polygonCoordinates;
      // Calcular el bounding box a partir de las coordenadas del polígono
      const bbox2a = [polygonCoordinates];

      //var bbox2a = this.indicators.geojson.features[0].geometry.coordinates;

      console.log(bbox2a);
      await this.$store.commit("setBoundingBox", bbox2a);
      
      // Extraer los valores mínimos (lon1, lat1) y máximos (lon2, lat2) de las coordenadas del polígono.
      const minLon = Math.min(...bbox2.map(coord => coord[0]));
      const minLat = Math.min(...bbox2.map(coord => coord[1]));
      const maxLon = Math.max(...bbox2.map(coord => coord[0]));
      const maxLat = Math.max(...bbox2.map(coord => coord[1]));

      // Crear el nuevo arreglo de bounding box (bbox3) en el formato necesario.
      const bbox3 = [minLon, minLat, maxLon, maxLat];
      console.log(bbox3);

      this.neighborsToMap = {
          type: "FeatureCollection",
          features: [
            {
              type: "FeatureCollection",
              features: []
            }
          ]
      };

      var largo = this.indicadores.vecinos.length;
      console.log(largo);

      //this.neighborsToMap.features = [];

      for (let i = 0; i < largo; i++) {
          const vecino = this.indicadores.vecinos[i];
          this.indicadores.vecinos[i].ufm2 = parseInt(this.indicadores.vecinos[i].valoruf / this.indicadores.vecinos[i].supconstruida);
          // Asegúrate de que `vecino.lat` y `vecino.lon` sean números válidos
          if (typeof vecino.lat === 'number' && !isNaN(vecino.lat) &&
              typeof vecino.lon === 'number' && !isNaN(vecino.lon)) {
              const randomPoint = turf.randomPoint(1, {
                  bbox: bbox3, // Pasar el arreglo de bounding box extendida
              });

              // Obtén el primer y único punto generado
              const feature = randomPoint.features[0];

              // Asigna las coordenadas y el campo "uf"
              feature.properties = {
                  uf: vecino.valoruf, // Valor aleatorio entre 80 y 120
              };
              feature.geometry.coordinates = [vecino.lon, vecino.lat];
              feature.geometry.type = "Point";

              // Agrega el punto al array de features
              this.neighborsToMap.features[0].features.push(feature);
          } else {
              // Manejar el caso en el que las coordenadas no sean números válidos
              console.error("Las coordenadas de los vecinos no son números válidos.");
              // O maneja el error de alguna otra forma
          }
      }


      console.log(this.neighborsToMap);
      this.$store.commit("setLoading");

    }
    let superficie_prop = this.indicadores.sup_util;
    let arrventas = [];
    if (this.indicators.oferta_ventas) {
      let ofertaventas = this.indicators.oferta_ventas.propiedades_similares;
      console.log(ofertaventas);
      let valoresven = [];
      for (let i = 0; i < ofertaventas.length; i++) {
        for (let j = 0; j < ofertaventas[i].descriptions.length; j++) {
          console.log(ofertaventas[i].descriptions[j].label);
          //si ofertaventas[i].descriptions.label contiene "m2" o contiene "m\u00b2" o contiene "m²" entonces var supe = ofertaventas[i].descriptions.label.split(" ")[0]
          if (ofertaventas[i].descriptions[j].label.includes("m2") || ofertaventas[i].descriptions[j].label.includes("m\u00b2") || ofertaventas[i].descriptions[j].label.includes("m²")) {
            var supe = ofertaventas[i].descriptions[j].label.split(" ")[0];
            break;
          }
          else {
            //sino pasar
            continue;
          }
        }
        if (ofertaventas[i].price.currency_id == 'CLF') {
          var valoruf = ofertaventas[i].price.amount;
        }
        else {
          var valoruf = parseInt(ofertaventas[i].price.amount / 36000);
        }
        console.log(supe);
        console.log(valoruf);
        valoresven.push(valoruf);
        arrventas.push({propiedad: i+1, valoruf: valoruf, supconstruida: supe, ufm2: Math.round(valoruf/supe), permalink: ofertaventas[i].permalink, direccion: ofertaventas[i].location});
      }
      let medianaven = valoresven.sort((a, b) => a - b)[Math.floor(valoresven.length / 2)];
      //Si cualquier valoruf está por sobre el doble o bajo la mitad del valor de la mediana, obtener el indice en el array y quitar el elemento con el mismo indice desde arrventas
      for (let i = 0; i < arrventas.length; i++) {
        if (arrventas[i].valoruf > medianaven*2 || arrventas[i].valoruf < medianaven/2) {
          arrventas.splice(i, 1);
          i = i - 1;
        }
      }
      this.ventas.propiedades = arrventas;
      console.log("oferta de ventas: ");
      console.log(this.ventas);
    }

    // Crear un objeto para llevar un registro de las propiedades vistas
    let propiedadesVistas = {};

    // Filtrar arrventas para eliminar propiedades repetidas
    let arrventasFiltradas = arrventas.filter(propiedad => {
      // Usar la dirección y la superficie construida como identificador único de la propiedad
      let identificador = propiedad.valoruf + '-' + propiedad.supconstruida;

      // Verificar si la propiedad ya ha sido vista
      if (propiedadesVistas[identificador]) {
        return false; // Si ya ha sido vista, no incluirla en el array filtrado
      } else {
        propiedadesVistas[identificador] = true; // Marcar la propiedad como vista
        return true; // Incluir la propiedad en el array filtrado
      }
    });

    // si el largo de arrventas es mayor que 15, guardar en this.ventas.propiedades las 15 propiedades con la supconstruida más parecida (con menor diferencia) a this.indicadores.sup_util
    if (arrventasFiltradas.length > 15) {
      console.log(arrventasFiltradas);
      var arrventas2 = arrventasFiltradas;
      arrventas2.sort(function(a, b) {
        return Math.abs(a.supconstruida - superficie_prop) - Math.abs(b.supconstruida - superficie_prop);
      });
      console.log(arrventas2);
      var arrventas3 = arrventas2.slice(0,15);
      console.log(arrventas3);
      this.ventas.propiedades = arrventas3;

      // Calcular los promedios
      this.promventasufm2 = this.formatNumber(Math.round(this.ventas.propiedades.reduce((a, b) => a + (b['ufm2'] || 0), 0) / this.ventas.propiedades.length));
      this.promventasvaloruf = this.formatNumber(Math.round(this.ventas.propiedades.reduce((a, b) => a + (b['valoruf'] || 0), 0) / this.ventas.propiedades.length));
    }

    else {
      this.promventasufm2 = this.formatNumber(Math.round(this.ventas.propiedades.reduce((a, b) => a + (b['ufm2'] || 0), 0) / this.ventas.propiedades.length));
      this.promventasvaloruf = this.formatNumber(Math.round(this.ventas.propiedades.reduce((a, b) => a + (b['valoruf'] || 0), 0) / this.ventas.propiedades.length));
    }
    
    let arrarriendos = [];
    if (this.indicators.oferta_arriendos) {
      let ofertaarriendos = this.indicators.oferta_arriendos.propiedades_similares;
      console.log(ofertaarriendos);
      let valarriendos = [];
      for (let i = 0; i < ofertaarriendos.length; i++) {
        for (let j = 0; j < ofertaarriendos[i].descriptions.length; j++) {
          console.log(ofertaarriendos[i].descriptions[j].label);
          //si ofertaventas[i].descriptions.label contiene "m2" o contiene "m\u00b2" o contiene "m²" entonces var supe = ofertaventas[i].descriptions.label.split(" ")[0]
          if (ofertaarriendos[i].descriptions[j].label.includes("m2") || ofertaarriendos[i].descriptions[j].label.includes("m\u00b2") || ofertaarriendos[i].descriptions[j].label.includes("m²")) {
            var supe2 = ofertaarriendos[i].descriptions[j].label.split(" ")[0];
            break;
          }
          else {
            //sino pasar
            continue;
          }
        }
        if (ofertaarriendos[i].price.currency_id == 'CLP') {
          var valorclp = ofertaarriendos[i].price.amount;
        }
        else {
          var valorclp = parseInt(ofertaarriendos[i].price.amount * 36000);
        }
        console.log(supe2);
        console.log(valorclp);
        valarriendos.push(valorclp);
        arrarriendos.push({propiedad: i+1, valorclp: valorclp, supconstruida: supe2, clpm2: Math.round(valorclp/supe2), permalink: ofertaarriendos[i].permalink, direccion: ofertaarriendos[i].location});
      }
      let medianarriendo = valarriendos.sort((a, b) => a - b)[Math.floor(valarriendos.length / 2)];
      //Si cualquier valorclp está por sobre el doble o bajo la mitad del valor de la mediana, obtener el indice en el array y quitar el elemento con el mismo indice desde arrarriendos
      for (let i = 0; i < arrarriendos.length; i++) {
        if (arrarriendos[i].valorclp > medianarriendo*2 || arrarriendos[i].valorclp < medianarriendo/2) {
          arrarriendos.splice(i, 1);
          i = i - 1;
        }
      }
      this.arriendos.propiedades = arrarriendos;
      console.log("oferta de arriendos: ");
      console.log(this.arriendos);
    }

    // si el largo de arrarriendos es mayor que 15, guardar en this.arriendos.propiedades las 15 propiedades con la supconstruida más parecida (con menor diferencia) a this.indicadores.sup_util
    if (arrarriendos.length > 15) {
      console.log(arrarriendos);
      var arrarriendos2 = arrarriendos;
      arrarriendos2.sort(function(a, b) {
        return Math.abs(a.supconstruida - superficie_prop) - Math.abs(b.supconstruida - superficie_prop);
      });
      console.log(arrarriendos2);
      var arrarriendos3 = arrarriendos2.slice(0,15);
      console.log(arrarriendos3);
      this.arriendos.propiedades = arrarriendos3;
      //this.promarriendoclpm2 = this.formatNumber(Math.round(this.arriendos.propiedades.reduce((a, b) => a + (b['clpm2'] || 0), 0) / this.arriendos.propiedades.length));
      //this.promarriendovalorclp = this.formatNumber(Math.round(this.arriendos.propiedades.reduce((a, b) => a + (b['valorclp'] || 0), 0) / this.arriendos.propiedades.length));
      //this.promarriendoufm2 = parseInt((10.5*(Math.round(this.arriendos.propiedades.reduce((a, b) => a + (b['clpm2'] || 0), 0) / this.arriendos.propiedades.length)) / 37500)/0.039);
      this.promarriendoclpm2 = this.formatNumber(Math.round(arrarriendos.reduce((a, b) => a + (b['clpm2'] || 0), 0) / arrarriendos.length));
      this.promarriendovalorclp = this.formatNumber(Math.round(arrarriendos.reduce((a, b) => a + (b['valorclp'] || 0), 0) / arrarriendos.length));
      this.promarriendoufm2 = parseInt((10.5*(Math.round(arrarriendos.reduce((a, b) => a + (b['clpm2'] || 0), 0) / arrarriendos.length)) / 37500)/0.039);
    }
    
    else {
      this.promarriendoclpm2 = this.formatNumber(Math.round(this.arriendos.propiedades.reduce((a, b) => a + (b['clpm2'] || 0), 0) / this.arriendos.propiedades.length));
      console.log("this.promarriendoclpm2: ");
      console.log(this.promarriendoclpm2);
      this.promarriendovalorclp = this.formatNumber(Math.round(this.arriendos.propiedades.reduce((a, b) => a + (b['valorclp'] || 0), 0) / this.arriendos.propiedades.length));
      console.log("this.promarriendovalorclp: ");
      console.log(this.promarriendovalorclp);
      this.promarriendoufm2 = parseInt((10.5*(Math.round(this.arriendos.propiedades.reduce((a, b) => a + (b['clpm2'] || 0), 0) / this.arriendos.propiedades.length)) / 37500)/0.039);
      console.log("this.promarriendoufm2: ");
      console.log(this.promarriendoufm2);
    }
    try {
      if (this.getSelectedProperty.commune == "NUNOA") {
        this.getSelectedProperty.commune = "ÑUÑOA";
      }
      
    } catch (error) {
      console.error(error);
    }
    /*
    const { data } = await axios(
      `${Config.VUE_APP_URL_GET_ALLOWED_PROPERTIES}?comuna=${this.getSelectedProperty.commune}&rol=${this.getSelectedProperty.role}`
    );
    console.log("paso1");
    this.hasPremium = data?.content?.isAllowedCommune ?? false;
    console.log("paso2");
    this.showWarningOrButton = true;
    console.log("paso3");
    */
  },
  methods: {
    async callGetImages(propertyId) {
      try {
        // Llama al método getImages sin esperar a que termine
        this.propertyService.getImages(propertyId).then((response) => {
          // Una vez que la respuesta llega, actualiza this.buttonDisabled
          console.log(response);
          this.imagenes = response;
          this.buttonDisabled = false;
        });
      } catch (error) {
        console.error(error);
        // Manejar errores aquí si es necesario
      }
    },
    async getImages() {
      try {
        const propertyId = this.getSelectedProperty.id;
        const response = await propertyService.getImages(propertyId);
        console.log(response);
        const images = response.data; // Asumiendo que las imágenes están en 'data' de la respuesta

        // Agregar el prefijo adecuado a cada imagen y agregarlas a photoGallery
        this.photoGallery = images.map(imgBase64 => {
          const formatPrefix = imgBase64.startsWith('/9j') ? 'data:image/jpeg;base64,' : 'data:image/png;base64,';
          return formatPrefix + imgBase64;
        });

        console.log("Imágenes obtenidas:", this.photoGallery);
      } catch (error) {
        console.error("Error al obtener imágenes:", error);
      }
    },
    formatNumber(value) {
      // Función para agregar el separador de miles
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    valueToPercent(value, maxValue, minValue) {
      console.log(value);
      console.log(maxValue);
      console.log(minValue);
      return ((value - minValue) * 100) / (maxValue - minValue);
    },
    callbackRange (val) {
      this.valuePropuf = val;
      console.log(this.valuePropuf);
      this.series2 = [this.valueToPercent(this.valuePropuf,this.maxValuePropuf,this.minValuePropuf)];
      //this.chartOptions2.labels = [this.valuePropuf.toString()];
      eventBus.$emit('changeMarkers', this.valuePropuf);
      //this.$emit('changeMarkers', this.valueuf);
    },
    chartRendered(chartContext) {
      // Este método se ejecuta después de que el gráfico se ha renderizado
      const chartDataUrl = chartContext.chart.getDataURL();
      
      // Asegúrate de que chartDataUrl no sea nulo antes de usarlo
      if (chartDataUrl) {
        // Aquí puedes usar chartDataUrl para agregar la imagen al PDF
        pdf.addImage(chartDataUrl, 'JPEG', 20, 200, 100, 100);
      } else {
        console.error("La URL de los datos del gráfico es nula.");
      }
    },
    async sendToReport () {
      console.log(this.getSelectedProperty);
      console.log(this.getInfoProperty);
      // Function to load a local image
      function loadLocalImage(imagePath) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = (error) => reject(error);
          img.src = imagePath;
        });
      }

      // Function to generate a static satellite map image URL with a marker
      function generateStaticMapUrlSat(zoom, lat, lng, apiKey) {
        const mapSize = '400x300'; // Tamaño del mapa en píxeles
        const marker = `markers=color:red%7Clabel:P%7C${lat},${lng}`;
        // Agregar maptype=satellite para la vista satelital
        const apiUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=${mapSize}&maptype=satellite&${marker}&key=${apiKey}`;
        return apiUrl;
      }

      // Function to generate a static map image URL with a marker
      function generateStaticMapUrl(zoom, lat, lng, apiKey) {
        const mapSize = '400x300'; // Tamaño del mapa en píxeles
        const marker = `markers=color:red%7Clabel:P%7C${lat},${lng}`;
        const apiUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=${mapSize}&${marker}&key=${apiKey}`;
        return apiUrl;
      }

      // Function to capture the ApexChart as an image using html2canvas
      function captureChartImage(chartId) {
        const chartElement = document.getElementById(chartId);
        /*
        return html2canvas(chartElement)
          .then((canvas) => canvas.toDataURL('image/png'))
          .catch((error) => {
            console.error('Error capturing chart image:', error);
            return null;
          });
        */
        return html2canvas(chartElement, { scale: 2 })
          .then((canvas) => canvas.toDataURL('image/png'))
          .catch((error) => {
            console.error('Error capturing chart image:', error);
            return null;
          });

      }

      console.log("sendToReport");
      const pdf = new jsPDF();
      // Añadir la imagen local al PDF
      const logoImagePath = require('@/assets/logo_iprop_1.png'); // Ruta relativa al logo desde tu componente Vue.js
      const logoImage = await loadLocalImage(logoImagePath);

      if (logoImage) {
        pdf.addImage(logoImage, 'PNG', 145, 12, 50, 10, undefined,'FAST'); // Ajustar coordenadas y tamaño según sea necesario
      }
      // Agrega título al informe
      pdf.setFontSize(18);
      pdf.setFont(undefined, 'bold');
      pdf.text('Informe de Propiedad', 20, 20);
      pdf.setFontSize(12);
      pdf.setFont(undefined, 'normal');
      let valorY = 30;
      pdf.text(`El siguiente informe entrega un resumen del análisis de mercado realizado a su propiedad.`, 20, valorY);
      valorY = valorY + 5;
      pdf.text(`Toda la información es de fuentes oficiales: SII y Conservadores de bienes raices.`, 20, valorY);
      // Agrega información sobre la propiedad
      pdf.setFontSize(12);
      pdf.setFont(undefined, 'bold');
      valorY = valorY + 5;
      pdf.line(14, valorY, 196, valorY, null);
      // Agrega información básica de la propiedad
      valorY = valorY + 7;
      pdf.text(`Dirección:`, 20, valorY);
      pdf.setFont(undefined, 'normal')
      //pdf.text(`$${this.indicadores.avaluo.toLocaleString('es-CL', { maximumFractionDigits: 0 })}`, 75, valorY);
      pdf.text(this.getSelectedProperty.address, 42, valorY);
      pdf.setFont(undefined, 'bold');
      valorY = valorY + 7;
      pdf.text(`Comuna:`, 20, valorY);
      pdf.setFont(undefined, 'normal')
      pdf.text(this.getSelectedProperty.commune, 39, valorY);
      valorY = valorY + 7;
      pdf.setFont(undefined, 'bold');
      pdf.text(`Barrio:`, 20, valorY);
      pdf.setFont(undefined, 'normal')
      pdf.text(this.indicadores.barrio, 35, valorY);
      valorY = valorY + 7;
      pdf.setFont(undefined, 'bold');
      pdf.text(`Tipo de Propiedad:`, 20, valorY);
      pdf.setFont(undefined, 'normal')
      pdf.text(this.tipoPropiedad, 59, valorY);
      valorY = valorY + 5;
      pdf.line(14, valorY, 196, valorY, null);
      // Agrega información de Avalúos y Contribuciones
      valorY = valorY + 7;
      pdf.setFont(undefined, 'bold');
      pdf.text('Avalúos y Contribuciones', 20, valorY);
      pdf.setFont(undefined, 'normal');
      // Tabla para Avalúos y Contribuciones
      pdf.autoTable({
          startY: valorY + 5,
          head: [['Concepto', 'Detalle']],
          body: [
              ['Avalúo Total', formatCurrency(this.getInfoProperty.data.avaluo_total)],
              ['Avalúo Exento', formatCurrency(this.getInfoProperty.data.avaluo_exento)],
              ['Avalúo Afecto', formatCurrency(this.getInfoProperty.data.avaluo_afecto)],
              ['Contribuciones Semestrales', formatCurrency(this.getInfoProperty.data.contribuciones_semestrales)],
              // Agrega más filas según sea necesario
          ],
          headStyles: { fillColor: [0, 80, 88] }
      });

      function formatCurrency(value) {
          // Usa toLocaleString con el idioma español (es-CL)
          return `$${value.toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
      }



      // Agrega información de Construcciones y Superficies
      valorY = pdf.autoTable.previous.finalY + 5;
      pdf.setFont(undefined, 'bold');
      pdf.line(14, valorY, 196, valorY, null);
      valorY = valorY + 7;
      pdf.text('Construcciones y Superficies', 20, valorY);
      pdf.setFont(undefined, 'normal');
      // Tabla para Construcciones y Superficies
      pdf.autoTable({
        startY: valorY + 5,
        head: [['Concepto', 'Detalle']],
        body: [
          ['Superficie Terreno', this.getInfoProperty.data ? ((this.getInfoProperty.data.destino === 'Agricola' || this.getInfoProperty.data.destino === 'Agrícola') ? (this.getInfoProperty.data.superficie_terreno.slice(0, -2) + '.' + this.getInfoProperty.data.superficie_terreno.slice(-2)) + ' hect' : this.getInfoProperty.data.superficie_terreno + ' m2') : ''],
          ['Tipo de Suelo', this.getInfoProperty.data ? this.getInfoProperty.data.tipo_suelo : ''],
          ['Lineas de Construccion', this.getInfoProperty.data ? this.getInfoProperty.data.lineas_construccion + ' Lineas de Construccion' : ''],
          ['Superficie Construida', this.getInfoProperty.data ? this.getInfoProperty.data.superficie_construida + ' m2' : ''],
          ['Materialidad Construccion', this.getInfoProperty.data ? this.getInfoProperty.data.materialidades : ''],
          ['Calidad Construccion', this.getInfoProperty.data ? this.getInfoProperty.data.calidades : ''],
          ['Condiciones Especiales', this.getInfoProperty.data ? this.getInfoProperty.data.condiciones_especiales : ''],
          ['Años de las Construcciones', this.getInfoProperty.data ? this.getInfoProperty.data.anios_construcciones : ''],
          // Agrega más filas según sea necesario
        ],
        headStyles :{fillColor : [0, 80, 88]}
      });
      valorY = pdf.autoTable.previous.finalY + 5;
      pdf.setFont(undefined, 'bold');
      pdf.line(14, valorY, 196, valorY, null);
      valorY = valorY + 7;
      pdf.text('Ubicación de la propiedad', 20, valorY);
      pdf.setFont(undefined, 'normal');
      // Agrega mapa de la propiedad en la mitad derecha de la página
      let imsize = 85;
      let im1iz = 1;
      let mpsize = 60;
      // Agrega mapa de la propiedad en la mitad derecha de la página
      const latitud = this.indicadores.lat // tu latitud
      const longitud = this.indicadores.lon// tu longitud
      //const googleMapsApiKey = "AIzaSyCxSI4JA1Qk0K6DOhs_aovrvDpZuDYecD8"// tu clave API de Google Maps
      const googleMapsApiKey = "AIzaSyBWWLKu9_4ekg3WXpCYeZZjzA-uOqDUlLg"// tu clave API de Google Maps

      const staticMapUrl = generateStaticMapUrlSat(15, latitud, longitud, googleMapsApiKey);
      const staticMapUrl2 = generateStaticMapUrl(18, latitud, longitud, googleMapsApiKey);
      // Asegúrate de que la clave de API de Google Maps sea válida y que tengas permisos para usar la API Static Maps.
      valorY = valorY + 3;
      const mapImage = await fetch(staticMapUrl)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .catch(error => {
          console.error('Error fetching static map:', error);
          return null;
        });

      if (mapImage) {
        pdf.addImage(mapImage, 'PNG', 115, valorY, mpsize+10, mpsize, undefined,'FAST'); // Ajusta las coordenadas y el tamaño según sea necesario
      }
      
      const mapImage2 = await fetch(staticMapUrl2)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .catch(error => {
          console.error('Error fetching static map:', error);
          return null;
        });
      
      if (mapImage2) {
        pdf.addImage(mapImage2, 'PNG', 25, valorY, mpsize+10, mpsize, undefined,'FAST'); // Ajusta las coordenadas y el tamaño según sea necesario
      }
      //Crea una nueva hoja en el pdf
      pdf.addPage();
      if (logoImage) {
        pdf.addImage(logoImage, 'PNG', 145, 12, 50, 10, undefined,'FAST'); // Ajustar coordenadas y tamaño según sea necesario
      }
      // Agrega información sobre propiedades vecinas
      //valorY = valorY + 10;
      valorY = 20;
      pdf.setFont(undefined, 'bold');
      pdf.text(`Análisis de Mercado`, 20, valorY);
      pdf.setFont(undefined, 'normal');
      valorY = valorY + 10;
      pdf.text(`Estas son algunas de las propiedades vecinas con compraventa en el último periodo:`, 20, valorY);
      // Agrega tabla con propiedades similares
      valorY = valorY + 5;
      let endY = 0;
      pdf.autoTable({
        startY: valorY,
        head: [['Dirección', 'Valor(UF)', 'Superficie', 'Año Constr.']],
        body: this.indicadores.vecinos.map((vecino) => [
          vecino.direccion,
          vecino.valoruf,
          vecino.supconstruida,
          vecino.año,
        ]),
        headStyles :{fillColor : [0, 80, 88]}
      });

      pdf.autoTable({
        didDrawPage: function (data) {
          endY = data.cursor.y;
        }
      });
      endY = endY + 5;
      pdf.text(`Para el análisis, se ha considerado que el tipo de propiedad es ${this.tipoPropiedad} y la propiedad se`, 20, endY);
      endY = endY + 5;
      pdf.text(`encuentra en el barrio ${this.indicadores.barrio}.`, 20, endY);
      endY = endY + 5;
      pdf.text(`También se ha considerado que su superficie es de ${this.indicadores.sup_util} m2 y fué construida el año ${this.indicadores.año}.`, 20, endY);
      endY = endY + 7;
      pdf.text(`Con la información anterior, se obtiene el Valorizador de la propiedad.`, 20, endY);
      //endY = endY + 5;
      //pdf.text(`Ratio de Avalúo/Compraventa - Valores en el Barrio - Valores basado en su avalúo fiscal`, 20, endY);
      
      endY = endY + 10;
      /*
      // Assuming you have an ApexCharts container with the id "chart1"
      const chartImage1 = await captureChartImage("chart1");

      console.log(chartImage1);
      // Check if the chart image is available before adding to the PDF
      if (chartImage1) {
        // Add the captured chart image to the PDF
        pdf.addImage(chartImage1, 'JPEG', im1iz, endY, imsize, imsize, undefined,'FAST'); // Adjust coordinates and size as needed
      }
      */
      // Assuming you have an ApexCharts container with the id "chart1"
      const chartImage2 = await captureChartImage("chart2");

      console.log(chartImage2);
      // Check if the chart image is available before adding to the PDF
      if (chartImage2) {
        // Add the captured chart image to the PDF
        //pdf.addImage(chartImage2, 'JPEG', im1iz - 30 + imsize, endY, imsize+35, imsize, undefined,'FAST'); // Adjust coordinates and size as needed
        pdf.addImage(chartImage2, 'JPEG', im1iz - 54 + imsize, endY, imsize + 80, imsize * (400 / 350), undefined, 'FAST');

      }
      /*
      // Assuming you have an ApexCharts container with the id "chart1"
      const chartImage3 = await captureChartImage("chart3");

      console.log(chartImage3);
      // Check if the chart image is available before adding to the PDF
      if (chartImage3) {
        // Add the captured chart image to the PDF
        pdf.addImage(chartImage3, 'JPEG', im1iz + 10 + 2*imsize, endY, imsize, imsize, undefined,'FAST'); // Adjust coordinates and size as needed
      }
      
      endY = endY + imsize - 2;
      pdf.setFontSize(9);
      pdf.setFont(undefined, 'bold');
      //pdf.text(`Ratio de Avalúo/Compraventa`, im1iz, endY);
      pdf.text(`Valorizador de la propiedad`, im1iz + 5 + imsize, endY);
      //pdf.text(`Valor del Barrio`, im1iz + 12 + 2*imsize, endY);
      pdf.setFont(undefined, 'normal');
      endY = endY + 3;
      //pdf.text(`Este ratio indica que tan`, im1iz, endY);
      pdf.text(`Utilizando los valores del barrio`, im1iz + 5 + imsize, endY);
      //pdf.text(`Finalmente con el promedio del`, im1iz + 12 + 2*imsize, endY);
      endY = endY + 3;
      //pdf.text(`cerca del valor de mercado el`, im1iz, endY);
      pdf.text(`, el valor de oferta de portales,`, im1iz + 5 + imsize, endY);
      //pdf.text(`Ratio de Avalúo/Compraventa se obtiene `, im1iz + 12 + 2*imsize, endY);
      endY = endY + 3;
      //pdf.text(`SII evalúa las propiedades`, im1iz, endY);
      pdf.text(`la superficie, el año y la materialidad`, im1iz + 5 + imsize, endY);
      //pdf.text(`el precio en UF de cada propiedad en la zona `, im1iz + 12 + 2*imsize, endY);
      endY = endY + 3;
      //pdf.text(`del sector. Se calcula dividiendo`, im1iz, endY);
      pdf.text(`se obtiene el valor mínimo`, im1iz + 5 + imsize, endY);
      //pdf.text(`(Valor Prop. = Avalúo Prop./Ratio Promedio).`, im1iz + 12 + 2*imsize, endY);
      endY = endY + 3;
      //pdf.text(`el avaluo de cada propiedad por`, im1iz, endY);
      pdf.text(`, promedio y máximo en el que se `, im1iz + 5 + imsize, endY);
      //pdf.text(`Luego calculamos el rango (max, min) y el valor`, im1iz + 12 + 2*imsize, endY);
      endY = endY + 3;
      //pdf.text(`su valor de compraventa (solo si tiene)`, im1iz, endY);
      pdf.text(`valoriza esta propiedad`, im1iz + 5 + imsize, endY);
      //pdf.text(`promedio de las propiedades en UF en la zona.`, im1iz + 12 + 2*imsize, endY);
      /*
      endY = endY + 3;
      //pdf.text(`y luego calculando el promedio, `, im1iz, endY);
      pdf.text(`(Valor Min = Avalúo Prop./Ratio Máximo;`, im1iz + 5 + imsize, endY);
      
      endY = endY + 3;
      //pdf.text(`valor maximo y mínimo. Un valor alto `, im1iz, endY);
      pdf.text(`Valor Medio = Avalúo Prop./Ratio Medio;`, im1iz + 5 + imsize, endY);
      
      endY = endY + 3;
      //pdf.text(`sobre el promedio del vecindario indica `, im1iz, endY);
      pdf.text(`Valor Max = Avalúo Prop./Ratio Mínimo).`, im1iz + 5 + imsize, endY);
      
      
      endY = endY + 3;
      pdf.text(`Por el contrario, un valor bajo indica `, im1iz, endY);
      endY = endY + 3;
      pdf.text(`que la compraventa sobrevalúa el real `, im1iz, endY);
      endY = endY + 3;
      pdf.text(`valor al que se podría vender.`, im1iz, endY);
      */
      /*
      pdf.addImage(this.$refs.chart1.chart.getDataURL(), 'JPEG', 20, 200, 100, 100);
      pdf.autoTable({
        startY: 320,
        body: [['Ratio Avalúo/Compraventa']],
      });
      */

      // Guarda el PDF
      //pdf.save('Informe_Propiedad.pdf');

      // si el largo de arrventas es mayor que 0 o el largo de arrarriendos es mayor que 0, crear nueva hoja y agregar la tabla de ventas y la tabla de arriendos, pero a cada tabla agregar adempás el permalink de cada propiedad.
      if (this.ventas.propiedades.length > 0) {
        pdf.addPage();
        if (logoImage) {
          pdf.addImage(logoImage, 'PNG', 145, 12, 50, 10, undefined,'FAST'); // Ajustar coordenadas y tamaño según sea necesario
        }
        // Agrega información sobre propiedades vecinas
        //valorY = valorY + 10;
        pdf.setFontSize(12);
        valorY = 20;
        pdf.setFont(undefined, 'bold');
        pdf.text(`Oferta de propiedades en venta`, 20, valorY);
        pdf.setFont(undefined, 'normal');
        valorY = valorY + 10;
        pdf.text(`Estas son algunas de las propiedades vecinas que se encuentra en portales inmobiliarios:`, 20, valorY);
        // Agrega tabla con propiedades similares
        pdf.setFontSize(9);
        valorY = valorY + 5;
        let endY = 0;
        let url = '';
        pdf.autoTable({
          startY: valorY,
          head: [['Dirección', 'Valor(UF)', 'Superficie', 'UF/m2', 'Enlace', 'Abrir']],
          body: this.ventas.propiedades.map((vecino) => [
            vecino.direccion,
            vecino.valoruf,
            vecino.supconstruida,
            vecino.ufm2,
            vecino.permalink,
            ''
          ]),
          headStyles: { fillColor: [0, 80, 88] },
          columnStyles: {
            4: { // Ajusta el ancho de la quinta columna (índice 4)
              cellWidth: pdf.internal.pageSize.width / 3,
            },
          },
          didDrawCell: function(data) {
            if (data.column.index === 4 && data.cell.section === 'body') {
              url = data.cell.raw; // Almacena la URL de la columna "Enlace"
            }
            if (data.column.index === 5 && data.cell.section === 'body' && url !== '') {
              if (data.cell.x && data.cell.y) { // Verifica si las coordenadas de la celda están definidas
                pdf.setTextColor(0, 0, 255);
                pdf.textWithLink('Click', data.cell.x, data.cell.y+10, { url: url });
                //pdf.output('dataurlnewwindow');
                pdf.setTextColor(0, 0, 0); // Restablecer el color del texto a negro
              }
            }
          }
        });


        pdf.autoTable({
          didDrawPage: function (data) {
            endY = data.cursor.y;
          }
        });
      }

      if (this.arriendos.propiedades.length > 0) {
        pdf.addPage();
        if (logoImage) {
          pdf.addImage(logoImage, 'PNG', 145, 12, 50, 10, undefined,'FAST'); // Ajustar coordenadas y tamaño según sea necesario
        }
        // Agrega información sobre propiedades vecinas
        //valorY = valorY + 10;
        pdf.setFontSize(12);
        valorY = 20;
        pdf.setFont(undefined, 'bold');
        pdf.text(`Oferta de propiedades en arriendo`, 20, valorY);
        pdf.setFont(undefined, 'normal');
        valorY = valorY + 10;
        pdf.text(`Estas son algunas de las propiedades vecinas que se encuentra en portales inmobiliarios:`, 20, valorY);
        // Agrega tabla con propiedades similares
        pdf.setFontSize(9);
        valorY = valorY + 5;
        let endY = 0;
        let url = '';
        pdf.autoTable({
          startY: valorY,
          head: [['Dirección', 'Valor(CLP)', 'Superficie', 'CLP/m2', 'Enlace', 'Abrir']],
          body: this.arriendos.propiedades.map((vecino) => [
            vecino.direccion,
            vecino.valorclp,
            vecino.supconstruida,
            vecino.clpm2,
            vecino.permalink,
            ''
          ]),
          headStyles: { fillColor: [0, 80, 88] },
          columnStyles: {
            4: { // Ajusta el ancho de la quinta columna (índice 4)
              cellWidth: pdf.internal.pageSize.width / 3,
            },
          },
          didDrawCell: function(data) {
            if (data.column.index === 4 && data.cell.section === 'body') {
              url = data.cell.raw; // Almacena la URL de la columna "Enlace"
            }
            if (data.column.index === 5 && data.cell.section === 'body' && url !== '') {
              if (data.cell.x && data.cell.y) { // Verifica si las coordenadas de la celda están definidas
                pdf.setTextColor(0, 0, 255);
                pdf.textWithLink('Click', data.cell.x, data.cell.y+10, { url: url });
                pdf.setTextColor(0, 0, 0); // Restablecer el color del texto a negro
              }
            }
          }

        });
        
        pdf.autoTable({
          didDrawPage: function (data) {
            endY = data.cursor.y;
          }
        });

      }
      
      let imagenes = this.imagenes;
      console.log(imagenes);
      //si hay más de 1 imagen
      if (imagenes.length > 0) {

      
        //Agrega en una nueva hoja, las imagenes obtenidas de llamar al metodo getImages
        pdf.addPage();
        if (logoImage) {
          pdf.addImage(logoImage, 'PNG', 145, 12, 50, 10, undefined,'FAST'); // Ajustar coordenadas y tamaño según sea necesario
        }
        // Agrega información sobre propiedades vecinas
        pdf.setFontSize(12);
        valorY = 20;
        pdf.setFont(undefined, 'bold');
        pdf.text(`Fotografías de la propiedad`, 20, valorY);
        pdf.setFont(undefined, 'normal');
        valorY = valorY + 10;
        pdf.text(`Estas son las fotografías tomadas a la propiedad:`, 20, valorY);
        // Agrega tabla con propiedades similares
        valorY = valorY + 5;
        // Definir el tamaño de la imagen, la separación y el grosor del marco
        const tamanoImagen = 50; // Tamaño de la imagen (ancho y alto)
        const separacion = 10; // Separación entre imágenes
        const grosorMarco = 0.2; // Grosor del marco, más delgado
        const imagenesPorFila = 3;

        for (let i = 0; i < imagenes.length; i++) {
            // Calcular en qué fila y columna va cada imagen
            let fila = Math.floor(i / imagenesPorFila);
            let columna = i % imagenesPorFila;

            // Calcular las coordenadas x e y para la imagen y el marco
            let x = 20 + (columna * (tamanoImagen + separacion));
            let y = valorY + (fila * (tamanoImagen + separacion));

            // Añadir una nueva página si es necesario
            if (i % 9 === 0 && i !== 0) {
                pdf.addPage();
                if (logoImage) {
                    pdf.addImage(logoImage, 'PNG', 145, 12, 50, 10, undefined, 'FAST');
                }
                // Restablecer la posición y para las nuevas imágenes
                valorY = 20;
                pdf.setFont(undefined, 'bold');
                pdf.text(`Fotografías de la propiedad`, 20, valorY);
                pdf.setFont(undefined, 'normal');
                valorY += 10;
                pdf.text(`Estas son las fotografías tomadas a la propiedad:`, 20, valorY);
                valorY += 15; // Ajustar para la siguiente imagen
            }

            // Agregar la imagen
            pdf.addImage(imagenes[i], 'PNG', x, y, tamanoImagen, tamanoImagen, undefined, 'FAST');

            // Dibujar el marco alrededor de la imagen con línea más delgada
            pdf.setLineWidth(grosorMarco);
            pdf.rect(x, y, tamanoImagen, tamanoImagen);
        }



        //pdf.addImage(imagenes[0], 'PNG', 20, valorY, 80, 60, undefined,'FAST'); // Ajustar coordenadas y tamaño según sea necesario
      }

      // Guarda el PDF y obtén su contenido como base64
      const pdfContent = pdf.output('datauristring');
      console.log(pdfContent);
      // Llama a submitFile con el contenido del PDF generado
      this.submitFile(pdfContent);

    },
    sendToAppraiser () {
      console.log("sendToAppraiser");
      this.$router.push({
        name: "AppraiserRequest",
      });
    },
    sendToPublish () {
      console.log("sendToPublish");
    },
    showToast() {
      const h = this.$createElement;
      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        "Se creó correctamente",
        " el informe y se añadió.",
        " a tus documentos"
      ]);
      this.$bvToast.toast([vNodesMsg], {
        title: "¡Listo!",
        solid: true,
        variant: "success",
        autoHideDelay: 3500,
      });
    },
    async submitFile(pdfContent) {
      let mytoken = localStorage.getItem("token");
      login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
      console.log("mytoken: " + mytoken);
      console.log("pdfcontent antes de guardar: " + pdfContent);
      if (pdfContent && this.name !== "") {
        this.$store.commit("setLoading");

        // Puedes omitir la línea this.file1 = this.$refs.file.files[0];

        const property = this.$store.getters.getSelectedProperty;
        console.log("property_id: " + property.id);
        const fileType = "application/pdf"; // El tipo de archivo es PDF

        axios
          .post(Config.VUE_APP_URL_SAVE_DOCUMENT, {
            property_id: property.id,
            file: { base64: pdfContent, type: fileType },
            name: "Informe de Propiedad ROL " + property.role,
            folder: "informes",
          },
          {
            headers: {
              Authorization: `Bearer ${mytoken}`
            }
          }
          )
          .then(() => {
            this.$store.dispatch("fetchDocuments", { propertyId: property.id });
            this.$store.commit("setLoading");

            this.$bvToast.toast("Se guardó el documento con éxito.", {
              title: "¡Listo!",
              solid: true,
              variant: "success",
            });
            setTimeout(() => {
              //this.$store.commit("setLoading");
              //this.$router.go(-1);
              this.$router.push({
                name: "FolderDocuments",
                params: { unit: this.getSelectedProperty.name } // Asegúrate de que 'property.unit' tenga el valor correcto que necesitas pasar como parámetro
              }); 
            }, 2000);
            return;
          })
          .catch((e) => {
            console.error(e);
            this.$store.commit("setLoading");
            this.$bvToast.toast("No pudimos almacenar tu documento.", {
              title: "¡Hubo un problema!",
              solid: true,
              variant: "danger",
            });
          });
      } else {
        this.$bvToast.toast(
          "No pudimos almacenar tu documento. Prueba rellenando los campos.",
          {
            title: "¡Hubo un problema!",
            solid: true,
            variant: "danger",
          }
        );
      }
    }
  },
  async mounted() {
    this.series1 = [this.valueToPercent(this.value,this.maxValue,this.minValue)];
    this.series2 = [this.valueToPercent(this.indicadores.kpi_ufvalue.mean,this.indicadores.kpi_ufvalue.max,this.indicadores.kpi_ufvalue.min)];
    //this.series2 = [this.valueToPercent(this.valuePropuf,this.maxValuePropuf,this.minValuePropuf)];
    this.series3 = [this.valueToPercent(this.valueuf,this.maxValueuf,this.minValueuf)];
    
    //this.chartOptions1.labels = [this.value.toString()];
    //this.chartOptions2.labels = [this.valueuf.toString()];
    //this.chartOptions3.labels = [this.promValuePropuf.toString()];
    // Define an arbitrary region in which to compute random points.
    var lat = this.getSelectedProperty.geojson.coordinates[1];
    console.log(lat);
    var lon = this.getSelectedProperty.geojson.coordinates[0];
    console.log(lon);
    var lat1 = lat - 0.001;
    var lon1 = lon - 0.001;
    var lat2 = lat + 0.001;
    var lon2 = lon + 0.001;

    /*
    //Genera un nuevo bbox con 20 puntos aleatorios siempre fuera de lat1, lon1 y lat2, lon2
    
    // Coordenadas de los dos puntos (lat1, lon1) y (lat2, lon2)
    const point1 = turf.point([lon1, lat1]);
    const point2 = turf.point([lon2, lat2]);

    // Extender el Bounding Box para incluir ambos puntos
    const bbox = turf.bbox(turf.featureCollection([point1, point2]));

    // Generar 11 puntos aleatorios dentro del bbox extendido
    const randomPoints = turf.randomPoint(5, { bbox });

    // Crear un polígono a partir de los puntos aleatorios
    const polygon = turf.convex(randomPoints);

    // Obtener las coordenadas del polígono resultante
    const coordinates = polygon.geometry.coordinates[0];
    var bbox2 = coordinates;
    console.log(coordinates);

    var bbox2a = [coordinates];
    
    

    // Extraer los valores mínimos (lon1, lat1) y máximos (lon2, lat2) de las coordenadas del polígono.
    const minLon = Math.min(...bbox2.map(coord => coord[0]));
    const minLat = Math.min(...bbox2.map(coord => coord[1]));
    const maxLon = Math.max(...bbox2.map(coord => coord[0]));
    const maxLat = Math.max(...bbox2.map(coord => coord[1]));

    // Crear el nuevo arreglo de bounding box (bbox3) en el formato necesario.
    const bbox3 = [minLon, minLat, maxLon, maxLat];

    //var bbox3 = [lon1, lat1, lon2, lat2]; // Un arreglo con cuatro números

    this.neighborsToMap = {
    type: "FeatureCollection",
    features: [lat, lon].map(function (center) {
        // Asegúrate de que `center` sea un número válido
        if (typeof center === 'number' && !isNaN(center)) {
            const randomPoint = turf.randomPoint(10, {
                bbox: bbox3, // Pasar el arreglo de bounding box extendida
            });
            
            // Itera a través de los puntos generados y agrega el campo "uf" con valor aleatorio.
            randomPoint.features.forEach(feature => {
                feature.properties = {
                    uf: Math.random() * (120 - 80) + 80, // Valor aleatorio entre 80 y 120
                };
            });

            return randomPoint;
        } else {
            // Manejar el caso en el que `center` no sea un número válido
            console.error("Las coordenadas no son números válidos.");
            return null; // O maneja el error de alguna otra forma
        }
    }),
    };
    */
    



},
};
</script>
