<template>
  <b-tabs class="nav-tab-custom">
    <b-tab
      v-for="(type, index) in types"
      :key="index"
      :active="index === 0"
      :title="type.title"
      @click="filteredDocuments(index)"
    >
      <b-row>
        <b-col>
          <document-request-list :documents="documents"></document-request-list>
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</template>

<script>
import DocumentRequestList from "@/components/misc/DocumentRequestList.vue";
import { mapGetters } from "vuex";

export default {
  name: "TabBarRequest",
  components: { DocumentRequestList },
  data() {
    return {
      types: [
        { title: "Municipales", folder: "municipalidad" },
        { title: "Conservador", folder: "cbrs" },
        { title: "Otros", folder: "otros" },
      ],
      documents: [],
    };
  },
  computed: {
    ...mapGetters(["getSimpleDocuments"]),
  },
  async mounted() {
    await this.$store.dispatch("fetchSimpleDocuments");
    this.filteredDocuments(0);
  },

  methods: {
    filteredDocuments(index) {
      this.documents = Object.values(this.getSimpleDocuments).filter(
        (doc) => doc.folders[0] === this.types[index].folder
      );
    },
  },
};
</script>
