<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <title-back back="Solicitudes">
          <template #title>
            <h3 class="mt-2 mb-0">
              Solicitud {{ getSelectedAdminRequest.name }}
            </h3>
          </template>
        </title-back>
      </b-col>
      <b-col class="d-flex align-items-end justify-content-end">
        <b-button
          v-if="isFinished"
          size="lg"
          variant="primary"
          :disabled="enableFinishRequest"
          @click="finishRequest"
          >Finalizar solicitud</b-button
        >
        <h5 v-else><b-badge variant="success" pill>Finalizada</b-badge></h5>
      </b-col>
    </b-row>
    <request-info
      :name="getSelectedAdminRequest.property.name"
      :role="getSelectedAdminRequest.property.role"
      :region="getSelectedAdminRequest.property.region"
      :commune="getSelectedAdminRequest.property.commune"
      :petitioner="getSelectedAdminRequest.user.name"
      :date="getSelectedAdminRequest.createdAt"
    />
    <request-info-payment
      :total-documents="getSelectedAdminRequest.totalDocuments"
      :total-price="getSelectedAdminRequest.payment.total"
      :payment-method="getSelectedAdminRequest.payment.method"
    />
    <h5 class="mb-3">Documentos solicitados</h5>
    <admin-request-document
      v-for="document in getSelectedAdminRequest.simple_documents"
      :id="document.id"
      :key="document.name"
      :category="document.category"
      :name="document.name"
      :file="document.file"
      :property-id="getSelectedAdminRequest.property.id"
    />
    <!-- <b-modal id="sendFile" ref="sendFileModal" title="Enviar documento">
      <p>¿Estás seguro que deseas enviar este documento?</p>
      <p>
        Se le notificará al propietario por correo que su documento ya está
        listo para ser revisado.
      </p>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-button size="lg" variant="outline-primary" @click="cancelUpload">
            Cancelar
          </b-button>
          <b-button
            size="lg"
            variant="primary"
            class="ml-2"
            @click="uploadFile"
          >
            Enviar
          </b-button>
        </div>
      </template>
    </b-modal> -->
  </div>
</template>

<script>
import TitleBack from "@/components/misc/TitleBack.vue";
import AdminRequestDocument from "@/components/cards/AdminRequestDocument.vue";
import RequestInfo from "@/components/cards/RequestInfo.vue";
import RequestInfoPayment from "@/components/cards/RequestInfoPayment.vue";
import { mapGetters } from "vuex";

export default {
  name: "RequestDetail",
  components: {
    TitleBack,
    AdminRequestDocument,
    RequestInfo,
    RequestInfoPayment,
  },
  computed: {
    ...mapGetters([
      "getSelectedAdminRequest",
      "getAdminRequestsList",
      "getSelectedAdminRequest",
    ]),
    enableFinishRequest() {
      return this.getSelectedAdminRequest.simple_documents.find(
        (x) => x.file.name === null
      ) === undefined
        ? false
        : true;
    },
    isFinished() {
      return this.getSelectedAdminRequest.status === "pendiente" ? true : false;
    },
  },
  methods: {
    finishRequest() {
      this.$store.dispatch("finishRequest");
    },
  },
};
</script>
