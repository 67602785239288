<template>
  <div class="container">
    <b-row class="justify-content-center">
      <b-col md="8" xl="6">
        <title-back back="Solicitudes" :items="backItems" />
        <div v-if="!getFinalStep">
          <form-property-name
            v-if="getStep == 1"
            :name="form.propertyName"
            @setName="updateName"
          />
          <form-property-pictures
            v-if="getStep == 2"
            :name="form.propertyPictures"
            @setPictures="updatePictures"
          />
          
          <form-property-identification
            v-if="getStep == 5"
          />
          
          <form-select-rol v-if="getStep == 3" :property-name="form.propertyName"/>
        </div>
        <div v-if="getFinalStep">
          <div
            class="stepper-header d-flex justify-content-between align-items-center"
          >
            <div class="stepper__header-content">
              <h2 class="mb-2">{{ form.propertyName }}</h2>
              <p class="text-muted">¡Encontramos tu propiedad con éxito!</p>
            </div>
            <font-awesome-icon
              icon="fa-solid fa-check-circle "
              class="text-success"
              size="3x"
            />
          </div>
          <b-alert show variant="info">
            Si quieres volver a buscar la propiedad o no estás seguro, puedes buscar nuevamente por <b-button variant="outline-primary" @click="showSearchAddressorRole(1)">rol o dirección</b-button>.
          </b-alert>
          <MiniMap
            :property-info="formatedGeojson"
            min-height="300px"
            :zoom="18"
            :show-tooltips="false"
            :is-main-marker-dragable="false"
          />
          <b-card class="mb-3">
            <label for="" class="font-weight-bold">Dirección</label>
            <p class="text-muted">
              {{ getInfoProperty.data.address }}, {{ getComune }},
              Santiago, Region Metropolitana
            </p>
            <label for="" class="font-weight-bold">Rol</label>
            <p class="text-muted mb-0">{{ getRole }}</p>
          </b-card>

          <b-card class="mb-3">
            <template #header>
              <div class="heading mb-3 mt-3">Avalúos y Contribuciones</div>
            </template>
            <div class="card-body"> <!-- Agrega el card-body aquí -->
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="mb-3">
                    <label for="" class="font-weight-bold">
                      Avalúo Total
                    </label>
                    <p class="text-muted mb-0">{{ getInfoProperty.data ? formatCurrency(getInfoProperty.data.avaluo_total) : '' }}</p>
                  </div>
                  <div class="mb-3">
                    <label for="" class="font-weight-bold">
                      Avalúo Exento
                    </label>
                    <p class="text-muted mb-0">{{ getInfoProperty.data ? formatCurrency(getInfoProperty.data.avaluo_exento) : '' }}</p>
                  </div>
                  <div class="mb-3">
                    <label for="" class="font-weight-bold">
                      Avalúo Afecto
                    </label>
                    <p class="text-muted mb-0">{{ getInfoProperty.data ? formatCurrency(getInfoProperty.data.avaluo_afecto) : '' }}</p>
                  </div>
                  <div class="mb-3">
                    <label for="" class="font-weight-bold">
                      Contribuciones Semestrales
                    </label>
                    <p class="text-muted mb-0">{{ getInfoProperty.data ? formatCurrency(getInfoProperty.data.contribuciones_semestrales) : '' }}</p>
                  </div>
                  <!--
                  <div v-if="getInfoProperty.data ? getInfoProperty.data.valor_transaccion > 0 : false" class="mb-3">
                    <label for="" class="font-weight-bold">
                      Ratio Avaluo-CompraVenta
                    </label>
                    <p class="text-muted mb-0">{{ getInfoProperty.data && getInfoProperty.data.valor_transaccion > 0 ? ((getInfoProperty.data.avaluo_total / (36130.31 * getInfoProperty.data.valor_transaccion))).toFixed(2) : '' }}</p>
                  </div>
                  -->
                </div>
              </div>
            </div>
          </b-card>

          <b-card class="mb-3">
            <template #header>
              <div class="heading mb-3 mt-3">Construcciones y Superficies</div>
            </template>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="mb-3">
                    <label for="" class="font-weight-bold">
                      Superficie Terreno
                    </label>
                    <p class="text-muted mb-0">
                      {{ getInfoProperty.data ? ((getInfoProperty.data.destino === 'Agricola' || getInfoProperty.data.destino === 'Agrícola') ? (getInfoProperty.data.superficie_terreno.slice(0, -2) + '.' + getInfoProperty.data.superficie_terreno.slice(-2)) + ' hect' : getInfoProperty.data.superficie_terreno + ' m2') : '' }} {{ getInfoProperty.data && (getInfoProperty.data.destino === 'Agricola' || getInfoProperty.data.destino === 'Agrícola') ? getInfoProperty.data.detalle_sup_ter : '' }}
                    </p>
                  </div>
                  <div class="mb-3">
                    <label for="" class="font-weight-bold">
                      Tipo de Suelo
                    </label>
                    <p class="text-muted mb-0">{{ getInfoProperty.data ? getInfoProperty.data.tipo_suelo : '' }}</p>
                  </div>
                  <div class="mb-3">
                    <label for="" class="font-weight-bold">
                      Lineas de Construccion
                    </label>
                    <p class="text-muted mb-0">{{ getInfoProperty.data ? getInfoProperty.data.lineas_construccion + ' Lineas de Construccion' : '' }}</p>
                  </div>
                  <div class="mb-3">
                    <label for="" class="font-weight-bold">
                      Superficie Construida
                    </label>
                    <p class="text-muted mb-0">{{ getInfoProperty.data ? getInfoProperty.data.superficie_construida + ' m2' : '' }}</p>
                  </div>
                  <div class="mb-3">
                    <label for="" class="font-weight-bold">
                      Materialidad Construccion
                    </label>
                    <p class="text-muted mb-0">{{ getInfoProperty.data ? getInfoProperty.data.materialidades : '' }}</p>
                  </div>
                  <div class="mb-3">
                    <label for="" class="font-weight-bold">
                      Calidad Construccion
                    </label>
                    <p class="text-muted mb-0">{{ getInfoProperty.data ? getInfoProperty.data.calidades : '' }}</p>
                  </div>
                  <div class="mb-3">
                    <label for="" class="font-weight-bold">
                      Condiciones Especiales
                    </label>
                    <p class="text-muted mb-0">{{ getInfoProperty.data ? getInfoProperty.data.condiciones_especiales : '' }}</p>
                  </div>
                  <div class="mb-3">
                    <label for="" class="font-weight-bold">
                      Años de las Construcciones
                    </label>
                    <p class="text-muted mb-0">{{ getInfoProperty.data ? getInfoProperty.data.anios_construcciones : '' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </b-card>


          <!-- Precios de Transferencias section 
          <b-card class="mb-3">
            <template #header>
              <div class="heading mb-3 mt-3">Precios de Transferencias <span style="font-weight: 400; font-size: 14px;">Años 2017 al 2021</span></div>
            </template>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div style="font-size: 14px; font-weight: 600;">Esta propiedad</div>
                  <div class="mb-3">
                    <div class="mb-3 row align-items-center">
                      <div class="col-sm-6" style="color: var(--bs-link-color); text-decoration: underline; cursor: pointer;">
                        {{ getResumeProperty.role }}
                      </div>
                      <div class="col-sm-6" v-if="getInfoProperty.data">
                        <div class="value-container">
                          <span v-if="getInfoProperty.data" style="margin-left: 20px;">{{ getInfoProperty.data.fecha_transaccion }}</span>
                          <span v-if="getInfoProperty.data" style="margin-left: 20px;">{{ getInfoProperty.data.valor_transaccion.toFixed(2) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        -->
        
          <div class="d-flex justify-content-end">
            <b-button variant="primary" size="lg" @click="addProperty"
              >Agregar a mis propiedades</b-button
            >
          </div>

        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FormPropertyIdentification from "../../components/forms/FormPropertyIdentification.vue";
import FormPropertyName from "@/components/forms/FormPropertyName.vue";
import FormPropertyPictures from "@/components/forms/FormPropertyPictures.vue";
import FormSelectRol from "@/components/forms/FormSelectRol.vue";
import TitleBack from "@/components/misc/TitleBack.vue";

import { mapGetters } from "vuex";

import EmptyPage from "../../components/misc/EmptyPage.vue";
import MiniMap from "@/components/MiniMap.vue";

export default {
  name: "CreateUnitRealtor",
  components: {
    FormPropertyIdentification,
    FormPropertyName,
    FormPropertyPictures,
    FormSelectRol,
    EmptyPage,
    MiniMap,
    TitleBack,
  },
  data() {
    return {
      info: "proyecto-creado",
      img: "verified.svg",
      buttons: [
        {
          caption: "Ir a inicio",
          variant: "outline-secondary",
          route: "",
        },
        {
          caption: "Ver propiedad",
          variant: "primary",
          route: "Projects",
        },
      ],
      form: {
        propertyName: "",
      },
      backItems: [
        {
          text: "Propiedades",
          to: "/corredor/propiedades",
        },
        {
          text: "Crear propiedad",
          active: true,
        },
      ],
      propertiesList: null,
    };
  },
  computed: {
    ...mapGetters([
      "getResumeProperty",
      "getInfoProperty",
      "getStep",
      "getFinalStep",
      "getProperty",
      "getForm",
      "getFreeCertificate",
      "setStep",
      "getRole",
      "getComune",
      "getRegion",
      "getName",
      "getPropertiesList"
    ]),
    formatedGeojson() {
      return {
        geometry: {
          coordinates: [
            this.getResumeProperty.data.lon,
            this.getResumeProperty.data.lat,
          ],
        },
      };
    },
  },
  destroyed() {
    this.$store.commit("restoreStep");
    this.$store.commit("restoreFinalStep");
  },
  async mounted() {
    this.$store.commit("setSelectedProperty", {});
    this.$store.commit("setReport", {});
    await this.$store.dispatch("fetchPropertiesList");
    this.propertiesList = await this.getPropertiesList;
    //console.log("getInfoProperty 2: ", this.getInfoProperty);
    //console.log("getResumeProperty: ", this.getResumeProperty);
    if (this.$store.getters.getFirstLogin) {
      this.$bvModal.show("onboarding");
    }
  },
  methods: {
    /*
    async addProperty() {
      this.showToast();

      const delayToast = 2500;
      const myregion = this.getRegion;
      const myrole = this.getRole;
      const myname = this.getName;
      const mycommune = this.getComune;
      console.log(myregion, mycommune, myrole, myname);
      const myprop = myregion + ";" + mycommune + ";" + myrole + ";" + myname;
      console.log(myprop);
      setTimeout(async () => {
        await this.$store.dispatch("addPropertyToList", myprop);
        this.$store.commit("restoreStep");
        //this.propertiesList = await this.getPropertiesList;
        const propertylist = this.propertiesList;
        console.log(propertylist);
        const myproperty = propertylist[propertylist.length - 1];
        console.log(myproperty);
        this.$store.commit("setSelectedProperty", {
          id: myproperty.id,
          name: myproperty.name,
          role: myproperty.rol,
          commune: myproperty.comuna,
          region: myproperty.region,
          address: myproperty.direccion,
          geojson: myproperty.geojson,
          owner: myproperty.user_id,
          page: myproperty.page,
          number: myproperty.number,
          year: myproperty.year,
          inform: myproperty.informe,
          date: myproperty.date,
        });
      

        this.$router.push({ path: "/corredor/propiedades/"+myname });
      }, delayToast);
    },
    */
    async addProperty() {
      this.showToast();

      const delayToast = 2500;
      const myregion = this.getRegion;
      const myrole = this.getRole;
      const myname = this.getName;
      const mycommune = this.getComune;
      console.log(myregion, mycommune, myrole, myname);
      const myprop = myregion + ";" + mycommune + ";" + myrole + ";" + myname + ";" + this.getResumeProperty.data.lon + ";" + this.getResumeProperty.data.lat;
      console.log(myprop);

      try {
        await this.$store.dispatch("addPropertyToList", myprop);
        this.$store.commit("restoreStep");

        // Espera a que la acción se complete antes de continuar
        await this.$nextTick();

        await this.$store.dispatch("fetchPropertiesList");
        this.propertiesList = await this.getPropertiesList;
        const propertylist = this.propertiesList;
        console.log(propertylist);

        const myproperty = propertylist[propertylist.length - 1];
        console.log(myproperty);

        this.$store.commit("setSelectedProperty", {
          id: myproperty.id,
          name: myproperty.name,
          role: myproperty.rol,
          commune: myproperty.comuna,
          region: myproperty.region,
          address: myproperty.direccion,
          geojson: myproperty.geojson,
          owner: myproperty.user_id,
          page: myproperty.page,
          number: myproperty.number,
          year: myproperty.year,
          inform: myproperty.informe,
          date: myproperty.date,
          dest: myproperty.destino,
        });

        this.$router.push({ path: "/corredor/propiedades/"+myname });
      } catch (error) {
        console.error("Error al agregar propiedad:", error);
      }
    },
    showToast() {
      const h = this.$createElement;
      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        "Se añadió ",
        h("strong", this.form.propertyName),
        " a tus propiedades.",
      ]);
      this.$bvToast.toast([vNodesMsg], {
        title: "¡Listo!",
        solid: true,
        variant: "success",
        autoHideDelay: 2500,
      });
    },
    updateName(event) {
      this.form.propertyName = event.target[0].value;
      localStorage.setItem(
        "propertyName",
        this.form.propertyName 
      );
    },
    updatePictures(event) {
      this.form.propertyPictures = event.target[0].value;
      localStorage.setItem(
        "propertyPictures",
        JSON.stringify({ pictures: this.form.propertyPictures })
      );
    },
    buttonRoute(button) {
      if (button == 0) {
        this.$router.replace({ name: "" });
      } else {
        this.$router.replace({ name: "OwnerProperties" });
      }
    },
    formatCurrency(value) {
      if (typeof value === "number") {
        return value.toLocaleString("es-CL", {
          style: "currency",
          currency: "CLP",
        });
      } else {
        return ""; // O cualquier otro valor predeterminado que desees en caso de que 'value' sea undefined o no sea un número.
      }
    },
    //formatCurrencyUF(value) formatea el numero para que quede con 2 decimales
    formatCurrencyUF(value) {
      if (typeof value === "number") {
        return value.toLocaleString("es-CL", {
          style: "currency",
          currency: "UF",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        return ""; // O cualquier otro valor predeterminado que desees en caso de que 'value' sea undefined o no sea un número.
      }
    },
    showSearchAddressorRole(myTab) {
      console.log("showSearchAddressorRole")
      this.$store.commit("restoreFinalStep");
      this.$store.commit("setStep", 5);
      //console.log(myTab);
      this.$store.commit("setSelectedTab", myTab);
      //console.log(this.$store.commit("getStep"));
    },
  },
};
</script>
