<template>
    <div class="container">
        <b-row class="mb-4">
            <b-card-group class="justify-content-center" deck>
                <b-card
                header="Hubo un error en la suscripción al plan"
                header-tag="header"
                title="Detalle"
                >

                    <b-card-text>
                        <b-icon icon="x-circle" variant="danger" font-scale="2"></b-icon>
                        {{message}}
                        <div v-if="filteredInvoices.length">
                            <p>Facturas vencidas o pendientes de pago:</p>
                            <ul>
                            <li v-for="invoice in filteredInvoices" :key="invoice.id">
                                {{ invoice.subject }} - {{ invoice.amount }} {{ invoice.currency }} 
                                <a :href="invoice.paymentLink" target="_blank">Pagar</a>
                            </li>
                            </ul>
                        </div>
                        <div v-else>
                            Puede escribirnos un email a pagos@inciti.com
                        </div>
                    </b-card-text>
                    
                </b-card>
            </b-card-group>
        </b-row>
    </div>
</template>

<script>
import propertyService from "@/services/property.service";
export default {
    name: "PaymentReturnSuscriptionError",
    data() {
        return {
            transactionStatus: null,
            transactionDetails: [],
            tableFields: [
                { key: 'key', label: 'Item' },
                { key: 'value', label: 'Detalle' }
            ],
            response: null,
            redirecting: false,
            status: 1,
            morose: 0,
            message: '',
            invoices: []
        };
    },
    async beforeMount() {
        this.$store.commit("setLoading");
        let mybody = {
            "uuid": this.$route.params.uuid
        };
        propertyService.getSuscriptionStatus(mybody).then((response) => {
            console.log("response");
            console.log(response);
            this.response = response.response;
            //this.transactionStatus = response.data.status;
            
            this.$store.commit("setLoading");
            if (this.response === 'si' && response.suscription) {
                this.status = response.suscription.status;
                this.morose = response.suscription.morose;
                this.invoices = response.suscription.invoices;
                this.message = this.getStatusMessage(this.status, this.morose);
            }
        })
        .catch((error) => {
            console.log(error);
            this.$store.commit("setLoading");
        });
    },
    computed: {
        filteredInvoices() {
        return this.invoices.filter(invoice => invoice.status === 0);
        }
    },
    methods: {
        gotoCredits() {
            this.$router.push({ name: 'PaymentCredits' });
        },
        getStatusMessage(status, morose) {
            let message = "";
            if (status === 1) {
                if (morose === 1) {
                message = "Su suscripción está activa, pero tiene una o más facturas vencidas.";
                } else if (morose === 2) {
                message = "Su suscripción está activa, pero tiene una o más facturas pendientes de pago.";
                } else {
                message = "Su suscripción está activa. Desde ahora puede comenzar a valorizar, generar certificados y otros.";
                }
            } else if (status === 0) {
                message = "Su suscripción se encuentra inactiva.";
                if (morose === 1) {
                message += " Además, tiene una o más facturas vencidas.";
                } else if (morose === 2) {
                message += " Además, tiene una o más facturas pendientes de pago.";
                }
                message += " Si tiene alguna duda escribanos a contacto@carpetainmobiliaria.com";
            } else if (status === 4) {
                message = "Su suscripción se encuentra cancelada.";
                if (morose === 1) {
                message += " Además, tiene una o más facturas vencidas.";
                } else if (morose === 2) {
                message += " Además, tiene una o más facturas pendientes de pago.";
                }
                message += " Si tiene alguna duda escribanos a contacto@carpetainmobiliaria.com";
            }

            return message;
        }
    }
};
</script>
