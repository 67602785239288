<template>
  <b-card header-class="bg-white py-3" body-class="px-0" class="mb-4">
    <template #header>
      <header-card
        title="Resumen propiedad"
        content="Información detallada de tu propiedad"
        icon="memo-circle-info"
        :has-state="false"
      />
    </template>
    <div class="title px-3">
      <h5>Características</h5>
    </div>
    <b-list-group class="list-custom-report">
      <list-custom-item label="Región" :result="region" />
      <list-custom-item label="Comuna" :result="commune" />
      <list-custom-item label="Rol" :result="rol" />
      <list-custom-item label="Detalle" :result="propertyName" />
      <list-custom-item class="full-row" label="Dirección" :result="address" />
    </b-list-group>
  </b-card>
</template>

<script>
import HeaderCard from "./HeaderCard.vue";
import ListCustomItem from "./ListCustomItem.vue";

export default {
  name: "Resume",
  components: {
    HeaderCard,
    ListCustomItem,
  },
  props: {
    address: {
      type: String,
      required: true,
    },
    region: {
      type: String,
      required: true,
    },
    comuna: {
      type: String,
      required: true,
    },
    rol: {
      type: String,
      required: true,
    },
    propertyName: {
      type: String,
      required: true,
    },
  },
  computed: {
    commune() {
      return this.comuna
        ?.split(" ")
        ?.map(
          (word) => word[0]?.toUpperCase() + word?.toLowerCase()?.substring(1)
        )
        ?.join(" ");
    },
  },
};
</script>
