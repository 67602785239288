import axios from 'axios';
import Config from "@/../config.json";
import login from "@/services/login.service";

const pageNumberYearService = {
  async getPageNumberYear(values) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_PAGE_NUMBER_YEAR,
        values,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data;
    } catch (err) {
      return err.response.data.message;
    }
  }, 
  
  async savePageNumberYear(values) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const response = await axios.put(
        Config.VUE_APP_URL_SAVE_PAGE_NUMBER_YEAR,
        values,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export default pageNumberYearService;