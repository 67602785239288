<template>
  <b-form @submit.prevent="onSubmit">
    <b-form-group
      id="input-group-1"
      label="Correo electrónico"
      label-for="input-1"
      class="font-weight-bold"
    >
      <b-form-input
        id="input-1"
        v-model="form.email"
        type="email"
        placeholder=""
        size="lg"
        required
      />
    </b-form-group>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
          size="lg"
          :disabled="disableButton"
          >{{ textButton }}</b-button
        >
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import axios from "axios";

import Config from "@/../config.json";

export default {
  name: "FormRecoverPassword",
  data() {
    return {
      form: {
        email: null,
      },
      disableButton: false,
      textButton: "Continuar",
    };
  },

  methods: {
    async onSubmit(event) {
      event.preventDefault();

      this.textButton = "Enviando ...";
      this.disableButton = true;

      const { data } = await axios(
        `${Config.VUE_APP_URL_RECOVER_PASSWORD}?email=${this.form.email}`
      );

      this.textButton = "Continuar";
      this.disableButton = false;

      if (!data.success) {
        this.$bvToast.toast(data.message, {
          title: `Error`,
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.$router.push("/correo-enviado");
    },
  },
};
</script>
