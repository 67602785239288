<template>
    <div class="container">
      <b-row class="mb-4">
        <h4>Mis créditos disponibles y suscripciones</h4>
      </b-row>
      <b-row class="mb-4">
        <b-card-group class="justify-content-center" deck>
          <b-card
            class="custom-card-width"
            border-variant="primary"
            header-bg-variant="primary"
            header-text-variant="white"
            header="Informes disponibles"
            header-tag="header"
            title="Cantidad"
          >
            <b-card-text>
              Informes disponibles: <strong>{{ reportsMessage }}</strong>
            </b-card-text>
            <b-button @click="gotoPayment" variant="primary">Comprar</b-button>
          </b-card>
          <b-card
            class="custom-card-width"
            border-variant="primary"
            header-bg-variant="primary"
            header-text-variant="white"
            header="Plan suscrito"
            header-tag="header"
            title="Detalles"
          >
            <b-card-text v-if="subscription.plan_actual >= 1">
              Plan:<strong> {{ subscription.plan_detalle }}</strong><br>
              Informes mensuales del plan:<strong> {{ subscription.disponibles_plan }}</strong><br>
              Informes usados del plan:<strong> {{ subscription.usados_plan }}</strong>
            </b-card-text>
            <div v-else>
              <b-card-text v-if="subscription.subscriptionId == -1">
                No tiene suscripciones
              </b-card-text>
              <b-card-text v-else>
                Tiene una suscripción activa al <b> Plan {{subscription.estadoSuscripcion.plan_name}}</b>, pero tiene un cargo pendiente de pago, ya que no se pudo realizar el cobro.
                Puede cambiar la tarjeta de crédito asociada e intentar nuevamente más tarde.  
              </b-card-text>
            </div>
            <b-button v-if="subscription.plan_actual >= 1" @click="gotoPayment" variant="primary">Cambiar suscripción</b-button>
            <b-button v-if="subscription.plan_actual >= 1" @click="confirmCancelSubscription" variant="danger" class="mt-1">Cancelar suscripción</b-button>
            <b-button v-else @click="gotoSubscription" variant="primary">Suscríbete acá</b-button>
          </b-card>
          <b-card
            class="custom-card-width"
            border-variant="primary"
            header-bg-variant="primary"
            header-text-variant="white"
            header="Tarjeta de crédito asociada"
            header-tag="header"
            title="Detalles"
          >
            <b-card-text v-if="!subscription.creditCardType || subscription.creditCardType === 'S/I' || !subscription.last4CardDigits || subscription.last4CardDigits === 'S/I'">
              No tiene tarjeta asociada
            </b-card-text>
            <b-card-text v-else>
              Nombre: <strong>{{ subscription.name }}</strong><br>
              Email: <strong>{{ subscription.email }}</strong><br>
              Tipo de tarjeta: <font-awesome-icon icon="fa-solid fa-credit-card" class="icon mr-2" /> <strong>{{ subscription.creditCardType }}</strong><br>
              Últimos 4 dígitos: <strong>{{ subscription.last4CardDigits }}</strong>
            </b-card-text>
            <b-button @click="handleCreditCardAction" variant="primary">
              {{ !subscription.creditCardType || subscription.creditCardType === 'S/I' || !subscription.last4CardDigits || subscription.last4CardDigits === 'S/I' ? 'Asociar tarjeta de crédito' : 'Eliminar tarjeta de crédito' }}
            </b-button>
          </b-card>
        </b-card-group>
      </b-row>
      <b-modal ref="cancelPlanModal" @ok="cancelSubscription">
        <template v-slot:modal-title>
          Confirmación de cancelación de suscripción
        </template>
        <p>Está seguro de cancelar la suscripción al plan {{ subscription.plan_detalle }}?</p>
        <p>Al cancelar la suscripción, se perderá cualquier informe disponible en su cuenta y deberá suscribirse nuevamente o comprar informes nuevos para poder valorizar una propiedad.</p>
      </b-modal>
      <b-modal ref="removeCardModal" @ok="removeCreditCard">
        <template v-slot:modal-title>
          Confirmación de eliminación de tarjeta de crédito
        </template>
        <p>Está seguro que desea quitar la tarjeta de crédito? Si no tiene una tarjeta asociada, ya no podrá realizar pagos automáticos y se le enviarán enlaces de pago a su email para continuar con el servicio.</p>
      </b-modal>
      <b-modal ref="addCardModal" @ok="addCreditCard">
        <template v-slot:modal-title>
          Confirmación de asociación de tarjeta de crédito
        </template>
        <p>Está seguro que desea asociar una nueva tarjeta de crédito?</p>
      </b-modal>
    </div>
  </template>
  
  <script>
  import propertyService from "@/services/property.service";
  import login from "@/services/login.service";
  export default {
    name: "PaymentCredits",
    data() {
      return {
        reportsMessage: '',
        subscription: {}
      };
    },
    async beforeMount() {
      let mytoken = localStorage.getItem("token");
      login.checkToken(mytoken);
      console.log("mytoken: " + mytoken);
      await this.getCreditStatus(true);
      this.interval = setInterval(() => this.getCreditStatus(false), 5000);
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
    methods: {
      async getCreditStatus(showLoader) {
        let mytoken = localStorage.getItem("token");
        if (showLoader) {
          this.$store.commit("setLoading");
        }
        let mybody = {
          token: mytoken
        };
        try {
          let response = await propertyService.getCreditsStatus(mybody);
          console.log("response", response);
          this.reportsMessage = this.formatReportsMessage(response.informes_disponibles);
          this.subscription = response;
          if (showLoader) {
            this.$store.commit("setLoading");
          }
        } catch (error) {
          console.log(error);
          if (showLoader) {
            this.$store.commit("setLoading");
          }
        }
      },
      formatReportsMessage(reportsAvailable) {
        if (reportsAvailable > 1) {
          return `${reportsAvailable} informes disponibles`;
        } else if (reportsAvailable === 1) {
          return '1 informe disponible';
        } else {
          return 'No hay informes disponibles';
        }
      },
      gotoPayment() {
        this.$router.push({ name: 'Payment' });
      },
      gotoSubscription() {
        this.$router.push({ name: 'Payment' });
      },
      confirmCancelSubscription() {
        this.$refs.cancelPlanModal.show();
      },
      async cancelSubscription() {
        let mytoken = localStorage.getItem("token");
        this.$store.commit("setLoading");
        try {
          let response = await propertyService.cancelSubscription({ token: mytoken });
          this.$store.commit("setLoading");
          if (response.response === "si") {
            this.$bvToast.toast(`La suscripción fue cancelada correctamente. No se cobrará a contar del próximo periodo.`, {
              title: "Suscripción cancelada",
              variant: "success",
              solid: true,
              autoHideDelay: 5000
            });
          } else {
            this.$bvToast.toast("Hubo un error al cancelar la suscripción. Por favor, envíenos un email a no-reply@carpetainmobiliaria.com solicitando la cancelación de la suscripción.", {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 5000
            });
          }
        } catch (error) {
          this.$store.commit("setLoading");
          if (error.response && error.response.status === 404 && error.response.data === 'Sin permisos') {
            this.$router.push({ name: "Login" });
          } else {
            this.$bvToast.toast("Error desconocido. Por favor, intente nuevamente más tarde.", {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 5000
            });
          }
        }
      },
      async removeCreditCard() {
        let mytoken = localStorage.getItem("token");
        let mybody = {
          token: mytoken
        };
        this.$store.commit("setLoading");
        try {
          let response = await propertyService.removeCreditCard(mybody);
          this.$store.commit("setLoading");
          if (response.response === 'si') {
            this.$bvToast.toast(`La tarjeta de crédito fue eliminada correctamente.`, {
              title: "Tarjeta eliminada",
              variant: "success",
              solid: true,
              autoHideDelay: 5000
            });
            await this.getCreditStatus(false);
          } else {
            this.$bvToast.toast("Hubo un error al eliminar la tarjeta de crédito. Por favor, intente nuevamente.", {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 5000
            });
          }
        } catch (error) {
          this.$store.commit("setLoading");
          console.log(error);
          this.$bvToast.toast("Error desconocido. Por favor, intente nuevamente más tarde.", {
            title: "Error",
            variant: "danger",
            solid: true,
            autoHideDelay: 5000
          });
        }
      },
      async addCreditCard() {
        let mytoken = localStorage.getItem("token");
        let mybody = {
          token: mytoken
        };
        this.$store.commit("setLoading");
        try {
          let response = await propertyService.addCreditCard(mybody);
          this.$store.commit("setLoading");
          if (response.response === "si") {
            let myurl = response.url;
            window.location.href = myurl;
            //await this.getCreditStatus(false);
          } else {
            this.$bvToast.toast("Hubo un error al asociar la tarjeta de crédito. Por favor, intente nuevamente.", {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 5000
            });
          }
        } catch (error) {
          this.$store.commit("setLoading");
          console.log(error);
          this.$bvToast.toast("Error desconocido. Por favor, intente nuevamente más tarde.", {
            title: "Error",
            variant: "danger",
            solid: true,
            autoHideDelay: 5000
          });
        }
      },
      handleCreditCardAction() {
        if (!this.subscription.creditCardType || this.subscription.creditCardType === 'S/I' || !this.subscription.last4CardDigits || this.subscription.last4CardDigits === 'S/I') {
          this.$refs.addCardModal.show();
        } else {
          this.$refs.removeCardModal.show();
        }
      }
    }
  };
  </script>
  
  <style>
  .custom-card-width {
    min-width: 300px; /* Ajusta este valor según tus necesidades */
  }
  </style>
  