<template>
  <b-card bg-variant="white" class="mb-3">
    <b-row align-v="center">
      <b-col cols="12" sm="6" md="4" lg="4" class="mb-3 mb-md-0">
        <h4 class="fix-capitalize">{{ name }}</h4>
        <p class="text-left font-weight-bold text-muted mb-0">Rol {{ role }}</p>
      </b-col>
      <b-col cols="12" sm="6" md="4" lg="4" class="mb-3 mb-md-0">
        <div class="media">
          <font-awesome-icon
            :icon="['fas', 'map-marker-alt']"
            class="text-muted mr-2 mt-1"
          />
          <div class="media-body">
            <p class="text-muted fix-capitalize mb-0"
              >{{ address }}, {{ commune }}</p
            >
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="4" lg="4" class="text-left text-sm-right">
        <b-button
          size="lg"
          variant="secondary"
          class="ml-2"
          @click="showProperty"
        >
          Ver Propiedad
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "UnitCard",
  props: {
    propertyId: { type: Number, required: true },
    name: { type: String, required: true },
    plan: { type: String | null, required: true },
    address: { type: String, required: true },
    geojson: { type: Object, required: true },
    role: { type: String, required: true },
    region: { type: String, required: true },
    commune: { type: String, required: true },
    owner: { type: String },
    page: { type: String },
    number: { type: String },
    year: { type: String },
    requestDocuments: { type: Number, required: true },
    issuedDocuments: { type: Number, required: true },
    inform: { type: null, required: true },
    date: { type: null, required: true },
  },
  computed: {
    variant() {
      switch (this.plan) {
        case "premium":
          return "warning";
        case "full":
          return "success";
        default:
          return "primary";
      }
    },
  },
  methods: {
    showProperty() {
      this.$store.dispatch('clearPropertyData');
      this.$store.commit("setSelectedProperty", {
        id: this.propertyId,
        name: this.name,
        role: this.role,
        commune: this.commune,
        region: this.region,
        address: this.address,
        geojson: this.geojson,
        owner: this.owner,
        page: this.page,
        number: this.number,
        year: this.year,
        inform: this.inform,
        date: this.date,
      });
      this.$router.push({
        name: "PropertyDetail",
        params: { unit: this.name },
      });
    },
  },
};
</script>
