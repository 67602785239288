<template>
  <div>
  <b-row>
    <b-col sm="12" lg="4">
      <MiniMap2
        :min-height="minHeight"
        :property-info="propertyInfo"
        :show-tooltips="false"
      />
    </b-col>
    <b-col sm="12" lg="4">
      <b-card body-class="p-4" class="mb-3" :style="{ minHeight: minHeight }">
        <b-row>
          <b-col md="6">
            <small>Dirección</small>
            <p class="font-weight-bold fix-capitalize">
              {{ getSelectedProperty.address }}
            </p>
          </b-col>
          <b-col md="6">
            <small>Rol</small>
            <p class="font-weight-bold fix-capitalize">
              {{ getSelectedProperty.role }}
            </p>
          </b-col>
          <b-col md="6">
            <small>Región</small>
            <p class="font-weight-bold fix-capitalize">
              {{ getSelectedProperty.region }}
            </p>
          </b-col>
          <b-col md="6">
            <small>Comuna</small>
            <p class="font-weight-bold">{{ formatCommune }}</p>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col>
      <b-card body-class="p-4" class="mb-3" :style="{ minHeight: minHeight }">
        <p class="text-left text-muted mt-2">
          Acá puedes tomar fotos de la propiedad
        </p>
        <!-- Botón para tomar fotos y agregar a la galería-->
        <b-button size="lg" class="mb-3" variant="primary" @click="addPhotoGallery">
              <i class="fa fa-camera"></i> Captura o sube nueva foto
        </b-button>
        
        <!-- Galería de imágenes -->
        <div class="image-gallery">
          <div v-for="(photo, index) in photoGallery" :key="index" class="image-thumbnail">
            <img
              :src="photo"
              alt="Foto"
              @click="showImageModal(index)"
              class="gallery-image"
            />
            <b-button @click="removePhoto(index)" variant="danger" pill class="delete-button">
              <i class="fa fa-trash"></i>
            </b-button>
          </div>
        </div>
        <b-button size="lg" class="mt-4 mb-1" variant="success" @click="uploadImages">
          <i class="fa fa-plus"></i> Agregar a la propiedad
        </b-button>
      </b-card>
    </b-col>
    
  </b-row>
  <!-- Modal for viewing image -->
  <b-modal
    v-model="showModal"
    size="lg"
    hide-footer
    centered
  >
    <img :src="photoGallery[modalIndex]" alt="Foto en modal" class="modal-image" />
  </b-modal>
</div>
</template>
<script>
import { Camera, CameraResultType } from '@capacitor/camera';
import { mapGetters } from "vuex";
import MiniMap2 from "../MiniMap2.vue";
import propertyService from "@/services/property.service";

export default {
  name: "InfoUnit",
  components: { MiniMap2 },
  data() {
    return {
      minHeight: "200px",
      photoGallery: [], // Almacena las fotos
      isZoomed: false,     // Nuevo estado para el zoom
      zoomedPhotoIndex: 0,  // Índice de la imagen en zoom
      zIndexValue: 9000,
      showModal: false,
      modalIndex: null,
    };
  },
  computed: {
    propertyInfo() {
      return {
        geometry: this.getSelectedProperty.geojson,
      };
    },
    formatCommune() {
      return this.getSelectedProperty.commune
        ?.split(" ")
        ?.map(
          (word) => word[0]?.toUpperCase() + word?.toLowerCase()?.substring(1)
        )
        ?.join(" ");
    },
    ...mapGetters(["getSelectedProperty","getImagenes"]),
  },
  props: {
    pictures: String,
  },
  beforeMount() {
    let imagenes = [];
    this.$store.commit("setLoading");
    console.log(this.$store.getters.getImagenes);
    //si imagenes es undefined
    if (this.$store.getters.getImagenes.length > 0) {
      console.log("imagenes2");
      imagenes = this.$store.getters.getImagenes;
      this.getImages(imagenes);
      this.$store.commit("setLoading");
      //this.buttonDisabled = false;
    } else {
        propertyService.getImages(this.getSelectedProperty.id).then((response) => {
          console.log("response");
          console.log(response);
          imagenes = response;
          this.getImages(imagenes);
          this.$store.commit("setLoading");
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setLoading");
          //this.buttonDisabled = false;
        });
    }
  },
  methods: {
    async convertToBase64(url, quality = 0.5) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();

        // Crea un nuevo objeto de imagen
        let img = new Image();
        img.src = URL.createObjectURL(blob);

        // Espera a que la imagen se cargue
        await new Promise((resolve) => {
          img.onload = resolve;
        });

        // Crea un canvas
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        // Ajusta las dimensiones del canvas al 50% del tamaño original de la imagen
        canvas.width = img.width * 0.5;
        canvas.height = img.height * 0.5;

        // Dibuja la imagen en el canvas con el nuevo tamaño
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convierte el canvas a base64
        return canvas.toDataURL('image/jpeg', quality);
      } catch (error) {
        console.error('Error al convertir la imagen a base64:', error);
      }
    },
    async getImages(images) {
      try {
        // Agregar el prefijo adecuado a cada imagen y agregarlas a photoGallery
        this.photoGallery = images.map(imgBase64 => {
          const formatPrefix = imgBase64.startsWith('/9j') ? 'data:image/jpeg;base64,' : 'data:image/png;base64,';
          return formatPrefix + imgBase64;
        });

        console.log("Imágenes obtenidas:", this.photoGallery);
      } catch (error) {
        console.error("Error al obtener imágenes:", error);
      }
    },
    async addPhotoGallery() {
      await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Uri
          }).then((image) => {
              this.photoGallery.push(image.webPath);
      });
      /*
      // Simula la toma de una foto (puedes reemplazar esto con tu lógica real de captura de fotos)
      const newPhoto = "https://picsum.photos/200/300";
      // Agrega la foto a la galería
      this.photoGallery.push(newPhoto);
      */
    },
    removePhoto(index) {
      // Elimina una foto de la galería en función de su índice
      this.photoGallery.splice(index, 1);
    },
    toggleZoom(index) {
      if (this.isZoomed && this.zoomedPhotoIndex === index) {
        // Si ya está en modo de zoom, desactívalo
        this.isZoomed = false;
      } else {
        // De lo contrario, activa el modo de zoom
        this.isZoomed = true;
        this.zoomedPhotoIndex = index;
      }
    },
    showImageModal(index) {
      this.showModal = true;
      this.modalIndex = index;
    },
    hideImageModal() {
      this.showModal = false;
    },
    // Sube las imágenes a la propiedad seleccionada
    async uploadImages() {
      this.$store.dispatch('clearPropertyData');
      this.$store.commit("setLoading");
      console.log("uploadImages");
      console.log(this.getSelectedProperty);
      // Convierte las imágenes a base64
      const base64Images = await Promise.all(
        this.photoGallery.map((photo) => this.convertToBase64(photo))
      );

      // Imprime en consola las imágenes en base64
      console.log("Imágenes en base64:", base64Images);

      // Sube las imágenes a la propiedad
      try {
        const payload = {
          propertyId: this.getSelectedProperty.id,
          images: base64Images,
          token: '$a89fd6a$_l'
        };
        await propertyService.updateImage(payload);
        console.log("Imágenes subidas con éxito.");
        this.$store.commit("setLoading");
      } catch (error) {
        console.error("Error al subir imágenes:", error);
        this.$store.commit("setLoading");
      }
    },

  },
};
</script>
<style scoped>
.image-gallery {
  /* Estilos para la galería */
}
.gallery-image {
  width: 100%;
  margin-bottom: 0.5rem;
  cursor: pointer; /* Indica que es clickeable */
}
.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
}
.modal-image {
  width: 100%;
  height: auto; /* Para mantener la relación de aspecto */
}
</style>