<template>
  <b-form novalidate @submit.prevent="onSubmit">
    <b-form-group
      id="input-group-1"
      class="font-weight-bold"
      label="Nombre"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        v-model.trim="form.name"
        :state="nameState"
        type="text"
        placeholder="Ingresa tu nombre"
        required
        size="lg"
        @paste.prevent
        @keypress="isString($event)"
        @blur.native="validateName()"
      >
      </b-form-input>
      <b-form-invalid-feedback>
        {{ hasBlurred.name.content }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      id="input-group-2"
      class="font-weight-bold"
      label="Apellido"
      label-for="input-2"
    >
      <b-form-input
        id="input-2"
        v-model.trim="form.lastName"
        :state="lastNameState"
        type="text"
        placeholder="Ingresa tu apellido"
        required
        size="lg"
        @paste.prevent
        @keypress="isString($event)"
        @blur.native="validateLastName()"
      >
      </b-form-input>
      <b-form-invalid-feedback>
        {{ hasBlurred.lastName.content }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      id="input-group-3"
      class="font-weight-bold"
      label="Correo electrónico"
      label-for="input-3"
    >
      <b-form-input
        id="input-3"
        v-model="form.email"
        :state="emailState"
        type="email"
        placeholder="Ingresa tu correo"
        required
        size="lg"
        @keydown.space.prevent
        @copy.prevent
        @paste.prevent
        @blur.native="validateEmail()"
      ></b-form-input>
      <b-form-invalid-feedback>
        {{ hasBlurred.email.content }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      id="input-group-4"
      class="font-weight-bold"
      label="Confirma tu correo"
      label-for="input-4"
    >
      <b-form-input
        id="input-4"
        v-model="form.confirmEmail"
        :state="confirmEmailState"
        type="email"
        placeholder="Ingresa tu correo"
        required
        size="lg"
        @keydown.space.prevent
        @copy.prevent
        @paste.prevent
        @blur.native="validateConfirmEmail()"
      ></b-form-input>
      <b-form-invalid-feedback>
        {{ hasBlurred.confirmEmail.content }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      id="input-group-5"
      class="font-weight-bold"
      label="Teléfono"
      label-for="input-5"
    >
      <b-input-group prepend="+56">
        <b-form-input
          id="input-5"
          v-model="form.phone"
          :state="phoneState"
          type="text"
          placeholder="Ingresa tu número de teléfono"
          required
          size="lg"
          @keydown.space.prevent
          @paste.prevent
          @keypress="isNumber($event)"
          @blur.native="validatePhone()"
        >
        </b-form-input>
        <b-form-invalid-feedback>
          {{ hasBlurred.phone.content }}
        </b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>
    <!--Se agregan Radio Buttons para saber tipo de usuario-->
    <b-form-group
    id="input-group-6"
    class="font-weight-bold"
    label="Selecciona el perfil que mejor te identifica:"
    label-for="radio-1"
    >
      <b-form-radio-group
        id="radio-1"
        v-model="form.role"
        :options="form.options"
        :state="selectedState"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        required
        @blur.native="validateSelect()"
      ></b-form-radio-group>
      <b-form-invalid-feedback :state="selectedState">
          {{ hasBlurred.selected.content }}
        </b-form-invalid-feedback>
    </b-form-group>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-between">
        <b-button
          class="mt-4 mb-3"
          variant="outline-primary"
          size="lg"
          @click="$router.push({ name: 'Login' })"
          >Volver
        </b-button>
        <b-button class="mt-4 mb-3" size="lg" type="submit" variant="primary">
          Siguiente
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FormRegister",
  data() {
    return {
      regEmail:
        /^(([^<>àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœñ()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      form: {
        name: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        phone: "",
        role: null,
        options: [
          { item: 'corredor', name: 'Soy corredor' },
          { item: 'comprador', name: 'Quiero comprar' },
          { item: 'vendedor', name: 'Quiero vender'},
          { item: 'tasador', name: 'Soy tasador'}
        ]
      },
      hasBlurred: {
        name: {
          state: null,
          content: "",
        },
        lastName: {
          state: null,
          content: "",
        },
        email: {
          state: null,
          content: "",
        },
        confirmEmail: {
          state: null,
          content: "",
        },
        phone: {
          state: null,
          content: "",
        },
        selected: {
          state: null,
          content: "",
        }
      }
    };
  },
  computed: {
    ...mapGetters([
      "setEmail"
    ]),
    nameState() {
      return this.hasBlurred.name.state;
    },
    lastNameState() {
      return this.hasBlurred.lastName.state;
    },
    emailState() {
      return this.hasBlurred.email.state;
    },
    confirmEmailState() {
      return this.hasBlurred.confirmEmail.state;
    },
    phoneState() {
      return this.hasBlurred.phone.state;
    },
    selectedState() {
      return this.hasBlurred.selected.state;
    }
  },
  methods: {
    async onSubmit(event) {
      console.log(this.form.role);
      console.log(this.form.email);
      //console.log(this.form.password);
      
      event.preventDefault();
      this.validateName();
      this.validateLastName();
      this.validateEmail();
      this.validateConfirmEmail();
      this.validatePhone();
      this.validateSelect();

      if (
        this.hasBlurred.name.state === null &&
        this.hasBlurred.lastName.state === null &&
        this.hasBlurred.email.state === null &&
        this.hasBlurred.confirmEmail.state === null &&
        this.hasBlurred.phone.state === null &&
        this.hasBlurred.selected.state === null
      ) {
        this.$store.commit("setEmail", this.form.email);
        await this.$store.dispatch("emailExist", this.form);
        if (this.$store.getters.getData !== null) {
          this.$bvToast.toast(this.$store.getters.getMsg, {
            title: "¡Hubo un problema!",
            solid: true,
            variant: "danger",
          });
        }
      }
    },
    validateSelect() {
      console.log(this.form.role);
      if (this.form.role === null) {
        this.hasBlurred.selected.state = false;
        this.hasBlurred.selected.content = "Debes seleccionar una opción.";
      } 
      else {
        this.hasBlurred.selected.state = null;
        this.hasBlurred.selected.content = "";
      }
    },
    validateName() {
      if (this.form.name === "") {
        this.hasBlurred.name.state = false;
        this.hasBlurred.name.content = "Debes ingresar tu nombre.";
      } else {
        this.hasBlurred.name.state = this.form.name.length > 1 ? null : false;
        this.hasBlurred.name.content = "Nombre debe tener al menos 2 letras.";
      }
      this.form.name = this.capitalize(this.form.name);
    },
    validateLastName() {
      if (this.form.lastName === "") {
        this.hasBlurred.lastName.state = false;
        this.hasBlurred.lastName.content = "Debes ingresar tu apellido.";
      } else {
        this.hasBlurred.lastName.state =
          this.form.lastName.length > 1 ? null : false;
        this.hasBlurred.lastName.content =
          "Apellido debe tener al menos 2 letras.";
      }
      this.form.lastName = this.capitalize(this.form.lastName);
    },
    validateEmail() {
      if (this.form.email === "") {
        this.hasBlurred.email.state = false;
        this.hasBlurred.email.content = "Debes ingresar tu correo.";
      } else {
        this.hasBlurred.email.state = this.regEmail.test(this.form.email)
          ? null
          : false;
        this.hasBlurred.email.content = "Debes ingresar un correo válido.";
      }
    },
    validateConfirmEmail() {
      if (this.form.confirmEmail === "") {
        this.hasBlurred.confirmEmail.state = false;
        this.hasBlurred.confirmEmail.content = "Debes confirmar tu correo.";
      } else if (!this.regEmail.test(this.form.confirmEmail)) {
        this.hasBlurred.confirmEmail.state = false;
        this.hasBlurred.confirmEmail.content =
          "Debes ingresar un correo válido.";
      } else {
        this.hasBlurred.confirmEmail.state =
          this.form.email === this.form.confirmEmail ? null : false;
        this.hasBlurred.confirmEmail.content = "Los correos no coinciden.";
      }
    },
    validatePhone() {
      if (this.form.phone === "") {
        this.hasBlurred.phone.state = false;
        this.hasBlurred.phone.content = "Debes ingresar tu teléfono.";
      } else {
        this.hasBlurred.phone.state = /[0-9]{8}$/.test(this.form.phone)
          ? null
          : false;
        this.hasBlurred.phone.content = "Debes ingresar un teléfono válido.";
      }
    },
    capitalize(value) {
      const arr = value.replace(/\s+/g, " ").split(" ");

      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      return arr.join(" ");
    },
    isString(event) {
      if (!/[a-zA-Z\u00F1\u00D1]+$/ ^ g.test(event.key))
        return event.preventDefault();
    },
    isNumber(event) {
      if (!/\d/.test(event.key) || this.form.phone.length > 7)
        return event.preventDefault();
    },
  },
};
</script>
