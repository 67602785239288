<template>
  <b-card header-class="bg-white py-3" body-class="px-0" class="mb-4">
    <template #header>
      <header-card
        title="GP - Hipotecas, Gravámenes y Litigios"
        content="Información sobre hipotecas y litigios"
        icon="gavel"
      />
    </template>
    <div class="title px-3">
      <h5>Hipotecas y Gravámenes</h5>
    </div>
    <b-table class="mb-4" :fields="fields" :items="items" >
      
    </b-table> 
    <div class="title px-3">
      <h5>Interd. y Prohibiciones</h5>
    </div>
    <b-table class="mb-4" :fields="fields" :items="items_one"></b-table>
    <div class="title px-3">
      <h5>Interd. y Prohibiciones</h5>
    </div>
    <b-table class="mb-4" :items="items_three" show-empty>
      <template slot="empty">
        <h6>No hay</h6>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import HeaderCard from './HeaderCard.vue'

export default {
  name: "Mortgage",
  components: {
    HeaderCard
  },
  props: {
    mortgages: {
      type: Object,
      default() {
        return {};
      },
    },
    prohibitions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      items_three: [],
      items_one: [
        { tipo: 'P', foja: '48778', número: '67948', año: '2020', },
      ],
      items_two: [
        { tipo: 'Servidumbre', foja: '48778', número: '67948', año: '2020', },
        { tipo: 'R', foja: '48778', número: '67948', año: '2020', },
        { tipo: 'H', foja: '48778', número: '67948', año: '2020', },
      ],
      fields: [{
        key: 'type',
        label: 'Tipo',
        sortable: false,
      }, {
        key: 'foja',
        label: 'Foja',
        sortable: false,
      },
      {
        key: 'number',
        label: 'Número',
        sortable: false,
      },
      {
        key: 'year',
        label: 'Año',
        sortable: false,
      }]
    }
  },
  computed:{
    items(){
      return Object.entries(this.mortgages).map((item)=>item[1])
    }
  }
}
</script>