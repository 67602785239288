<template>
  <b-card>
    <h5 class="mb-5 ml-3">Historial</h5>

    <stepper
      :quantity="getDocumentStatus.length"
      :content="getDocumentStatus"
      :vertical="true"
    ></stepper>
  </b-card>
</template>
>
<script>
import { mapGetters } from "vuex";
import Stepper from "../misc/Stepper.vue";
export default {
  name: "StepperVertical",
  components: { Stepper },
  data() {
    return {
      isAdmin: true,
    };
  },
  computed: {
    ...mapGetters(["getDocumentStatus"]),
  },
};
</script>

