<template>
  <b-container>
    <b-row>
      <div id="map" :style="{ minHeight: minHeight }"></div>
    </b-row>
  </b-container>
</template>

<script>
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import reportService from "@/services/report.service";

import { mapGetters } from "vuex";
import { eventBus } from '@/main';

export default {
  name: "MiniMap2",
  props: {
    showMarkers: {
      type: Boolean,
      default: true,
    },
    minHeight: {
      type: String,
      default: "500px",
    },
    zoom: {
      type: Number,
      default: 13,
    },
    mainMakerRotation: {
      type: Number,
      default: 0,
    },
    propertyInfo: {
      type: Object,
      default: () => ({
        geometry: { coordinates: [-70.64827, -33.45694] },
        // geometry: null,
      }),
    },
    neighborsToMap: {
      type: Object,
      default() {
        return { type: "FeatureCollection", features: [] };
      },
    },
    neighborsColor: {
      type: String,
      default: "#FFB100",
    },
    showTooltips: {
      type: Boolean,
      default: true,
    },
    isMainMarkerDragable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resumeProperty: null,
      selectedProperty: null,
      reportStatus: null,
      markerColorLimit: 5500,
      colorVecino: 'green',
      map: null,
      currentMarkers: [],
      mainMarker: null
    };
  },

  computed: {
    ...mapGetters([
      "getResumeProperty",
      "getReportStatus",
      "getSelectedProperty",
      "getBoundingBox",
    ]),
  },

  async mounted() {
  try {
    this.resumeProperty = this.getResumeProperty;
    this.selectedProperty = this.getSelectedProperty;

    // Verifica que selectedProperty y selectedProperty.id estén definidos
    if (!this.selectedProperty || !this.selectedProperty.id) {
      console.error("Selected property is not defined or missing an ID.");
      return;
    }

    const { data } = await reportService.getReportStatus(this.selectedProperty.id);
    this.reportStatus = data;

    const mapboxToken = "pk.eyJ1Ijoiam9obnBpdHQiLCJhIjoiY2xoNmNkMzJrMDBuaTNybnU3MjdsZXR4aSJ9.jbLX3CSFov-doev26Lc2Aw";
    mapboxgl.accessToken = mapboxToken;

    // Verifica que propertyInfo y sus coordenadas estén definidas
    if (!this.propertyInfo || !this.propertyInfo.geometry || !this.propertyInfo.geometry.coordinates) {
      console.error("Property information or coordinates are missing.");
      return;
    }

    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/johnpitt/clh6kpvmq01il01qj9nmf5vze",
      center: [
        this.propertyInfo.geometry.coordinates[0],
        this.propertyInfo.geometry.coordinates[1],
      ],
      zoom: this.zoom,
    });

    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.FullscreenControl());

    if (this.showMarkers) {
      this.map.on("load", () => {
        this.mainMarker = new mapboxgl.Marker({
          draggable: false,
          color: "#bf9000",
          rotation: this.mainMakerRotation,
        })
          .setLngLat([
            this.propertyInfo.geometry.coordinates[0],
            this.propertyInfo.geometry.coordinates[1],
          ])
          .addTo(this.map);

        // Verifica que getBoundingBox esté definido y sea una matriz
        if (!this.getBoundingBox || !Array.isArray(this.getBoundingBox)) {
          console.error("Bounding box data is missing or invalid.");
          return;
        }

        this.map.addSource('barrio1', {
          'type': 'geojson',
          'data': {
            'type': 'Feature',
            'geometry': {
              'type': 'Polygon',
              'coordinates': this.getBoundingBox
            }
          }
        });

        // Add a new layer to visualize the polygon.
        console.log(this.getBoundingBox);
        this.map.addLayer({
          'id': 'barrio1',
          'type': 'fill',
          'source': 'barrio1', // reference the data source
          'layout': {},
          'paint': {
            'fill-color': '#21675e', // blue color fill
            'fill-opacity': 0.3
          }
        });

        // Add a black outline around the polygon.
        this.map.addLayer({
          'id': 'outline',
          'type': 'line',
          'source': 'barrio1',
          'layout': {},
          'paint': {
            'line-color': '#000',
            'line-width': 1
          }
        });

        if (this.showTooltips) {
          const popup = new mapboxgl.Popup({
            closeButton: true,
            closeOnClick: true,
          }).setHTML(`
            <div class="container">
              <div class="row mt-1 mb-2">
                <div class="col">
                  <h6>Propiedad:</h6>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="font-weight-bold">Dirección:</span>
                  <span class="text-muted">
                    ${
                      this.propertyInfo?.properties?.address &&
                      typeof this.propertyInfo?.properties?.address ===
                        "string"
                        ? this.propertyInfo?.properties?.address
                            ?.split(" ")
                            ?.map(
                              (word) =>
                                word[0]?.toUpperCase() +
                                word?.toLowerCase()?.substring(1)
                            )
                            ?.join(" ")
                        : "Sin información"
                    }</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="font-weight-bold">Comuna:</span>
                  <span class="text-muted">
                    ${
                      this.propertyInfo?.properties?.commune &&
                      typeof this.propertyInfo?.properties?.commune ===
                        "string"
                        ? this.propertyInfo?.properties?.commune
                            ?.split(" ")
                            ?.map(
                              (word) =>
                                word[0]?.toUpperCase() +
                                word?.toLowerCase()?.substring(1)
                            )
                            ?.join(" ")
                        : "Sin información"
                    }</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="font-weight-bold">Rol:</span>
                  <span class="text-muted">
                    ${
                      this.propertyInfo?.properties?.role &&
                      typeof this.propertyInfo?.properties?.role === "string"
                        ? this.propertyInfo?.properties?.role
                            ?.split(" ")
                            ?.map(
                              (word) =>
                                word[0]?.toUpperCase() +
                                word?.toLowerCase().substring(1)
                            )
                            ?.join(" ")
                        : "Sin información"
                    }</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="font-weight-bold">Propietario:</span>
                  <span class="text-muted">
                    ${
                      this.reportStatus?.content?.report?.data
                        ?.simpleRealEstate?.propietario &&
                      typeof this.reportStatus?.content?.report?.data
                        ?.simpleRealEstate?.propietario === "string"
                        ? this.reportStatus?.content?.report?.data?.simpleRealEstate?.propietario
                            ?.split(" ")
                            ?.map(
                              (word) =>
                                word[0]?.toUpperCase() +
                                word?.toLowerCase()?.substring(1)
                            )
                            ?.join(" ")
                        : "Sin información"
                    }</span>
                </div>
              </div>
            </div>
            `);

          const markerDiv = marker.getElement();

          markerDiv.addEventListener("click", () => {
            marker.setPopup(popup);
            marker.togglePopup();
          });
        }

        if (this.isMainMarkerDragable) {
          const onDragEnd = () => {
            const lngLat = marker.getLngLat();

            if (this.resumeProperty?.geojson?.coordinates) {
              this.resumeProperty.geojson.coordinates = [
                lngLat.lng,
                lngLat.lat,
              ];

              this.$store.commit("setResumeProperty", this.resumeProperty);
            }
          };

          marker.on("dragend", onDragEnd);
        }

        console.log(this.neighborsToMap);

        // Verifica que neighborsToMap y features estén definidos
        if (!this.neighborsToMap || !this.neighborsToMap.features || !this.neighborsToMap.features[0] || !this.neighborsToMap.features[0].features) {
          console.error("Neighbors to map or its features are missing.");
          return;
        }

        for (const neighbor of this.neighborsToMap.features[0].features) {
          console.log(neighbor);
          if (neighbor?.properties?.uf > this.markerColorLimit) {
            this.colorVecino = 'red';
          } else {
            this.colorVecino = 'green';
          }

          if (neighbor.geometry.coordinates[0] === this.propertyInfo?.geometry?.coordinates[0] &&
              neighbor.geometry.coordinates[1] === this.propertyInfo?.geometry?.coordinates[1]) {
            this.colorVecino = '#bf9000';
          }

          const marker = new mapboxgl.Marker({
            draggable: false,
            color: this.colorVecino,
          })
            .setLngLat([
              neighbor.geometry.coordinates[0],
              neighbor.geometry.coordinates[1],
            ])
            .addTo(this.map);

          this.currentMarkers.push(marker);

          let formattedUf = null;
          const thousands = 1000;

          if (typeof neighbor?.properties?.uf === "number" && neighbor?.properties?.uf >= thousands) {
            formattedUf = (neighbor?.properties?.uf / thousands).toFixed(3);
          } else {
            formattedUf = neighbor?.properties?.uf;
          }

          const address = neighbor?.properties?.direccion ?? "";
          const departament = neighbor?.properties?.depto ?? "";

          const formattedAddress = departament === "" ? address : `${address}, ${departament}`;

          if (this.showTooltips) {
            const popup = new mapboxgl.Popup({
              closeButton: true,
              closeOnClick: true,
            }).setHTML(`
              <div class="container">
                <div class="row mt-1">
                  <div class="col">
                    <h6>Propiedad cercana y/o similar:</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Comuna:</span>
                    <span class="text-muted">
                      ${neighbor?.properties?.comuna ?? "Sin información"}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Rol:</span>
                    <span class="text-muted">
                      ${neighbor?.properties?.rol ?? "Sin información"}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Dirección:</span>
                    <span class="text-muted">
                      ${formattedAddress ?? "Sin información"}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Propietario:</span>
                    <span class="text-muted">
                      ${neighbor?.properties?.comprador ?? "Sin información"}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Año construcción:</span>
                    <span class="text-muted">
                    ${neighbor?.properties?.anoConstruccion ?? "Sin información"}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Superficie Útil:</span>
                    <span class="text-muted">
                    ${
                      neighbor?.properties?.supCons
                        ? neighbor?.properties?.supCons + " m²"
                        : "Sin información"
                    }
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Superficie Terreno:</span>
                    <span class="text-muted">
                    ${
                      neighbor?.properties?.sutTTe
                        ? neighbor?.properties?.sutTTe + " m²"
                        : "Sin información"
                    }
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Año Última Transferencia:</span>
                    <span class="text-muted">
                      ${
                        typeof neighbor?.properties?.fecha === "string"
                          ? neighbor?.properties?.fecha
                              ?.split("T")[0]
                              ?.split("-")
                              ?.reverse()
                              ?.join("-")
                          : "Sin información"
                      }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Precio Transf:</span>
                    <span class="text-muted">
                      ${formattedUf ? formattedUf + " UF" : "Sin información"}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Precio UF/m2:</span>
                    <span class="text-muted">
                      ${
                        neighbor?.properties?.ufM2U
                          ? neighbor?.properties?.ufM2U + " UF"
                          : "Sin información"
                      }
                      </span>
                  </div>
                </div>
              </div>
            `);
            const markerDiv = marker.getElement();

            markerDiv.addEventListener("click", () => {
              marker.setPopup(popup);
              marker.togglePopup();
            });
          }
        }

        //Se actualiza el marcador principal
        console.log("Actualiza mainmarker");
        console.log(this.propertyInfo?.geometry?.coordinates[0]);
        console.log(this.propertyInfo?.geometry?.coordinates[1]);
        
        if (this.mainMarker) {
          this.mainMarker.setLngLat([
            this.propertyInfo.geometry.coordinates[0],
            this.propertyInfo.geometry.coordinates[1],
          ]);
        }
        console.log("Despues de actualizar mainmarker");
      });
    }
  } catch (error) {
    console.error("Error in mounted lifecycle hook:", error);
  }
},


  created() {
    eventBus.$on('changeMarkers', data => {
      this.markerColorLimit = data;
      //update map and markers
      if (this.currentMarkers!==null) {
            for (var i = this.currentMarkers.length - 1; i >= 0; i--) {
            this.currentMarkers[i].remove();
            }
        }
      this.currentMarkers = [];
      for (const neighbor of this.neighborsToMap.features[0].features) {
          console.log(neighbor);
          if (neighbor?.properties?.uf > this.markerColorLimit) {
            this.colorVecino = 'red';
          }
          else {
            this.colorVecino = 'green';
          }
          
          // crea nuevos marcares con el color correspondiente
          const marker = new mapboxgl.Marker({
            draggable: false,
            color: this.colorVecino,
          })
            .setLngLat([
              neighbor.geometry.coordinates[0],
              neighbor.geometry.coordinates[1],
            ])
            .addTo(this.map);
        
          this.currentMarkers.push(marker);

          
        
      }

      //Se actualiza el marcador principal
      console.log("Actualiza mainmarker");
      console.log(this.propertyInfo?.geometry?.coordinates[0]);
      console.log(this.propertyInfo?.geometry?.coordinates[1]);
      
      if (this.mainMarker) {
          this.mainMarker.setLngLat([
            this.propertyInfo?.geometry?.coordinates[0],
            this.propertyInfo?.geometry?.coordinates[1],
          ]);
        }
      console.log("Despues de actualizar mainmarker");

    });
  }
};
</script>

<style>
#map {
  top: 0%;
  bottom: 0%;
  left: 0%;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;

  border-radius: 0.5rem;
  border: 1px solid #dee2e6;
}

.mapboxgl-popup {
  min-width: 320px;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.mapboxgl-marker {
  cursor: pointer;
}
</style>
