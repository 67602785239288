<template>
  <div class="d-flex">
    <div class="vh-100 bg-white auth-content d-flex align-items-center">
      <b-container class="mt-5">
        <b-row class="justify-content-between">
          <b-col cols="10" lg="6" class="mx-auto text-center text-lg-left">
            <img src="../../assets/logo-iprop-green.svg" alt="" class="mb-5"/>
            <div class="icon-circle bg-secondary mb-4">
              <font-awesome-icon
                class="fa-lg text-white"
                icon="fa-solid fa-paper-plane"
              />
            </div>
            <h2 class="mb-2">Ya falta poco</h2>
            <p class="text-muted mt-3 mb-4">Te hemos enviado un correo para que puedas verificar tu cuenta.</p>
            <p class="text-muted mt-3 mb-4"><b>Valida tu cuenta y luego vuelve a la aplicación.</b></p>
            <!--
            <b-button variant="primary" size="lg" @click="
              $router.push({name:'Login'})
            ">Ir a inicio de sesión</b-button>
            -->
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="vh-100 bg-login d-none d-lg-flex align-items-center">
      <b-container class="mt-5">
        <b-row>
          <b-col lg="10" class="mx-auto">
            <h4 class="text-white mb-5">Tu propiedad en tus manos</h4>
            <h1 class="text-white">Desbloqueamos lo complejo del <span class="text-terciary">sector inmobiliario</span></h1>
            <img src="../../assets/img-login.png" alt="" class="d-block img-fluid">
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "VerificationMessage",
  computed: {
    ...mapGetters([
      "getEmail",
      "getPassword"
    ])
  },
  mounted() {
    this.form = {
      email: this.$store.getters.getEmail,
      password: this.$store.getters.getPassword,
      status: "not_accepted",
      isRegistering: true,
    };
    console.log(this.form);
    this.intervalid1 = setInterval(() => {
      if (this.$store.getters.getForm != null) {
        this.$store.dispatch("loginUserFromRegister", this.form);
        console.log("loginUserFromRegister");
      } else {
        console.log("no login");
      }
    }, 3000);
  },
  beforeRouteLeave(to, from, next) {
    // Antes de abandonar la vista, detén el intervalo si está activo
    if (this.intervalid1) {
      clearInterval(this.intervalid1);
      console.log("stop interval");
    }
    next();
  },
};
</script>
