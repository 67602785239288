<template>
  <b-form @submit="submitForm">
    <h2>Selecciona tus documentos</h2>
    <p class="text-muted">
      Ahora selecciona los documentos que necesitas para tu propiedad:
    </p>
    <card-info-property
      :name="getRequestSelectedProperty.name"
      :commune="getRequestSelectedProperty.commune"
      :region="getRequestSelectedProperty.region"
      :role="getRequestSelectedProperty.role"
    />
    <tab-bar-request />
    <b-card class="mb-3">
      <b-row>
        <b-col>
          <h5 class="mb-0">Total</h5>
        </b-col>
        <b-col class="text-right">
          <h5 class="mb-0">$ {{ totalSale }}</h5>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col class="d-flex justify-content-between align-items-center">
        <b-button variant="outline-primary" size="lg" @click="reduceStep">
          Volver
        </b-button>
        <b-button variant="primary" size="lg" type="submit">
          Solicitar
        </b-button>
        <!--
        <b-button disabled variant="primary" size="lg" type="submit">
          Siguiente
        </b-button>
        -->
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import TabBarRequest from "@/components/misc/TabBarRequest.vue";
import CardInfoProperty from "../cards/CardInfoProperty.vue";
import { mapGetters } from "vuex";

export default {
  name: "FormSelectDocuments",
  components: {
    TabBarRequest,
    CardInfoProperty,
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    ...mapGetters([
      "getRequestSelectedProperty",
      "getSimpleDocumentsSelected",
      "getDocumentsTotalSale",
      "getSelectedProperty",
      "getUserData",
      "requestSelectedProperty",
    ]),
    totalSale() {
      const values = this.getSimpleDocumentsSelected;
      const initialValue = 0;
      const total = values.reduce(
        (accumulator, currentValue) =>
          accumulator + parseInt(currentValue.price_sale.replace(".", "")),
        initialValue
      );
      // this.$store.commit("setDocumentsTotalSale",new Intl.NumberFormat('es-CL').format(total))
      this.$store.commit("setDocumentsTotalSale", total);

      return new Intl.NumberFormat("es-CL").format(total);
    },
  },
  mounted() {
    this.$store.commit("setDocumentsTotalSale", 0);
    this.$store.commit("setSimpleDocumentsSelected", []);

    localStorage.setItem(
      "documentsRequest",
      JSON.stringify({
        documentsList: [],
        totalPrice: 0,
        property: null,
        user: null,
      })
    );
  },
  methods: {
    submitForm(event) {
      event.preventDefault();

      localStorage.setItem(
        "documentsRequest",
        JSON.stringify({
          documentsList: this.getSimpleDocumentsSelected,
          totalPrice: this.getDocumentsTotalSale,
          property: JSON.parse(localStorage.getItem("vuex")).property
            .requestSelectedProperty,
          user: JSON.parse(localStorage.getItem("vuex")).user.owner,
        })
      );

      if (this.getSimpleDocumentsSelected.length === 0) {
        this.$bvToast.toast(
          "Debes selecionar al menos un documentos para avanzar al siguiente paso.",
          {
            title: "¡Hubo un problema!",
            solid: true,
            variant: "danger",
          }
        );
      } else {
        this.$store.commit("incrementStep");
      }
    },
    reduceStep() {
      this.$store.commit("decrementStep");
    },
  },
};
</script>
