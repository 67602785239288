<template>
  <b-list-group-item id="id">
    <b-row>
      <b-col md="6" lg="8">
        <b-row>
          <b-col
            cols="12"
            lg="6"
            class="d-flex align-items-center mb-2 mb-lg-0"
          >
            <h6 class="mb-0">{{ name }}</h6>
          </b-col>
          <b-col
            cols="12"
            lg="6"
            class="d-flex align-items-center mb-2 mb-lg-0"
          >
            <span class="text-muted">Subido el {{ date }}</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          v-if="type === 'application/pdf'"
          @click="viewDocument(id, source)"
          variant="outline-secondary"
          size="sm"
          class="mr-2"
          >Ver documento</b-button
        >
        
        <b-button
          v-if="type != 'application/pdf'"
          variant="outline-secondary"
          size="sm"
          class="mr-2"
          :download="name"
          :href="document.content"
          title="Descargar documento pdf"
        >
          Descargar
        </b-button>
        
      </b-col>
    </b-row>
    <b-modal :id="`viewDocument${id}${source}`" :title="name" size="lg" lazy>
      <vue-pdf-embed :source="document.content" />
      <template #modal-footer>
        <b-button
          variant="primary"
          size="sm"
          class="mr-2"
          @click="downloadPdf"
          title="Descargar documento pdf"
        >
          Descargar
        </b-button>
          Descargar
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel(`viewDocument${id}${source}`)"
        >
          Salir
        </b-button>
      </template>
    </b-modal>
  </b-list-group-item>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "DocumentItem",
  components: { VuePdfEmbed },
  props: {
    id: {
      type: String,
      default: "",
    },
    source: {
      type: String,
      default: "",
    },
    file: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "Certificado",
    },
    type: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "01-01-2021",
    },
  },
  computed: {
    document() {
      return this.$store.getters.getDocument !== undefined
        ? this.$store.getters.getDocument
        : null;
    },
  },
  methods: {
    viewDocument() {
      this.$store
        .dispatch("viewDocument", { documentId: this.id, source: this.source })
        .then(() => {
          this.$bvModal.show(`viewDocument${this.id}${this.source}`);
        });
    },
    cancel(id) {
      this.$bvModal.hide(id);
    },
    downloadPdf() {
      // Si el documento ya está en formato base64, simplemente crea el blob y descarga
      const blob = this.b64toBlob(this.document.content, 'application/pdf');
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Libera la memoria utilizada por el objeto URL
    },

    b64toBlob(b64Data, contentType='', sliceSize=512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }

  },
};
</script>
