import axios from 'axios';
import Config from "@/../config.json";
import login from "@/services/login.service";

const document = {
  async saveDocument(values) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_SAVE_DOCUMENT,
        values,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data;
    } catch (err) {
      return err.response.data.message;
    }
  },
  async viewDocument(values) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_VIEW_DOCUMENT,
        values,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data;
    } catch (err) {
      return err.response.data.message;
    }
  },
  async deleteDocument(values) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const data = await axios.delete(
        Config.VUE_APP_URL_DELETE_DOCUMENT,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          },
          data: values
        }
      );
      return data;
    } catch (err) {
      return err.response.data.message;
    }
  }  
};
export default document;