<template>
  <b-form @submit="submitForm">
    <h2 class="mb-2">Selecciona modo de pago</h2>
    <p class="text-muted">
      Ahora selecciona el método de pago que más te acomode para realizar esta transacción.
    </p>
    <!-- <div class="custom-control custom-radio">
      <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
      <label class="custom-control-label" for="customRadio1">
        <b-card class="mb-3">
          <b-row class="justify-content-center">
            <b-col cols="4">
              <img src="../../assets/logo-webpay.png" alt="" class="img-fluid">
            </b-col>
          </b-row>
        </b-card>
      </label>
    </div>
    <div class="custom-control custom-radio">
      <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input">
      <label class="custom-control-label" for="customRadio2">
        <b-card class="mb-3">
          <b-row class="justify-content-center">
            <b-col cols="4">
              <img src="../../assets/logo-webpay.png" alt="" class="img-fluid">
            </b-col>
          </b-row>
        </b-card>
      </label>
    </div> -->
    <b-form-group v-slot="{ ariaDescribedby }" class="checkbox-payment w-100 mb-0">
      <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="A">
        <b-card class="mb-3">
          <b-row class="justify-content-center">
            <b-col cols="4">
              <img src="../../assets/logo-webpay.png" alt="" class="img-fluid">
            </b-col>
          </b-row>
        </b-card>
      </b-form-radio>
    </b-form-group>
    <b-row>
      <b-col class="d-flex justify-content-between">
        <b-button size="lg" class="mb-3" variant="outline-primary" @click="reduceStep()">
          Volver
        </b-button>
        <b-button size="lg" class="mb-3" variant="primary" type="submit">
          Siguiente
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>
  
<script>
import imgWp from "@/assets/logo-webpay.png";

export default {
  name: "FormPayment",
  data() {
    return {
      selected: 'A'
    }
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      if (this.selected === 'A') {
        this.$store.commit("incrementStep");
        this.selected = '';
      } else {
        this.$bvToast.toast('Debes seleccionar método de pago.', {
          title: "¡Hubo un problema!",
          solid: true,
          variant: "danger",
        });
      }
    },
    reduceStep() {
      this.$store.commit("decrementStep");
      this.selected = '';
    },
  }
}
</script>
