<template>
  <b-container>
    <b-row>
      <b-col md="6">
        <title-back back="Propiedad"></title-back>
        <h2>Certificados gratuitos</h2>
        <p class="text-muted mb-4">
          Mientras te encuentres suscrito a nuestro servicio, podrás obtener información y algunos certificados de la propiedad.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-3 mb-lg-0" md="6" offset="0">
        <report-prices
          id-modal="basic-example"
          :has-premium="true"
          option="basic"
          price="Gratis"
          :disabled="false"
        />
      </b-col>
      <!--
      <b-col md="6" offset="0">
        <report-prices
          id-modal="premium-example"
          :has-premium="hasPremium"
          option="premium"
          price="$ 10.000"
          :show-warning-or-button="showWarningOrButton"
        />
      </b-col>
      -->
    </b-row>
  </b-container>
</template>

<script>
import TitleBack from "@/components/misc/TitleBack.vue";
import ReportPrices from "@/components/cards/ReportPrices.vue";

import { mapGetters } from "vuex";

import axios from "axios";
import Config from "@/../config.json";
import login from "@/services/login.service";

export default {
  name: "ReportChoices",
  components: {
    ReportPrices,
    TitleBack,
  },
  data() {
    return {
      hasPremium: false,
      showWarningOrButton: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedProperty"]),
  },
  async beforeMount() {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);

    const { data } = await axios.get(
      `${Config.VUE_APP_URL_GET_ALLOWED_PROPERTIES}?comuna=${this.getSelectedProperty.commune}&rol=${this.getSelectedProperty.role}`,
      {
        headers: {
          Authorization: `Bearer ${mytoken}`
        }
      }
    );

    this.hasPremium = data?.content?.isAllowedCommune ?? false;
    this.showWarningOrButton = true;
  },
};
</script>
