<template>
  <div id="app">
    <b-overlay
      id="overlay-background"
      :show="loading"
      variant="light"
      opacity="1"
    >
      <router-view></router-view>
      <template #overlay>
        <div class="d-flex flex-column align-items-center">
          <b-spinner variant="primary" class="mb-3"></b-spinner>
          <span v-if="isHome" class="text-muted text-center mb-2"
            >Buscando...</span
          >
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      currentUser: null,
    };
  },
  beforeCreate() {},
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(["loading"]),
    isHome() {
      return this.$route.path == "/corredor/propiedades/ingresar-propiedad";
    },
  },
};
</script>
