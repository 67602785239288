import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

import dpa from "./dpa";
import stepper from "./stepper";
import user from "./user";
import BD from "./BD";
import report from "./report";
import auth from "./auth";
import property from "./property";
import admin from "./admin";

Vue.use(Vuex);
const ls = new SecureLS({ isCompression: false });
export default new Vuex.Store({
  modules: {
    dpa,
    stepper,
    user,
    BD,
    report,
    auth,
    property,
    admin,
  },
  plugins: [
    createPersistedState({
      paths: [
        "user", 
        "report.report", 
        "report.reportType",
        "property.selectedProperty", 
        "property.propertyDetail", 
        "property.pageNumberYear", 
        "property.requestSelectedProperty",
        "property.selectedRequest",
        "admin.selectedAdminRequest",
      ],
      /* storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      }, */
    }),
  ],
});
