import axios from "axios";
import Config from "@/../config.json";
import login from "@/services/login.service";

const requestService = {
  async getRequests(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const { data } = await axios.post(
        Config.VUE_APP_URL_REQUESTS_LIST,
        {
          user_id: payload,
        },
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data.requestList.content;
    } catch (error) {
      throw error;
    }
  },
  
  async registerRequest(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const { data } = await axios.post(
        Config.VUE_APP_URL_REGISTER_REQUEST,
        payload,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }, 
  
  async adminRequestsList() {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const { data } = await axios.get(
        Config.VUE_APP_URL_ADMIN_REQUEST_LIST,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data.adminRequestList.content;
    } catch (error) {
      throw error;
    }
  },

  async adminUploadFileRequest(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_ADMIN_UPLOAD_FILE,
        payload,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  
  async adminFinishRequest(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_ADMIN_FINISH_REQUEST,
        payload,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};
export default requestService;
