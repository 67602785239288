<template>
  <b-card class="mb-3">
    <b-form-radio
      class="custom-radio-rol"
      name="some-radios"
      :value="value"
      @change="updateSelected"
    >
      <b-row>
        <b-col sm="6">
          <h6>{{ address }}</h6>
          <p class="text-muted mb-0">{{ rol }}</p>
        </b-col>
        <b-col sm="6" class="text-right">
          <p>{{ detail }}</p>
        </b-col>
      </b-row>
    </b-form-radio>
  </b-card>
</template>

<script>
export default {
  name: "PropertyRolItem",
  props: {
    address: {
      type: String,
      default: "",
    },
    rol: {
      type: String,
      default: "",
    },
    detail: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },

  methods: {
    updateSelected(checked) {
      this.$emit("updateSelected", { rol: checked });
    },
  },
};
</script>
