<template>
  <b-form novalidate @submit.prevent="onSubmit">
    <b-form-group
      id="input-group-1"
      class="font-weight-bold mb-4"
      label="Contraseña"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        v-model="form.password"
        :state="passwordState"
        type="password"
        placeholder="Ingresa tu contraseña"
        required
        size="lg"
        @keydown.space.prevent
        @copy.prevent
        @paste.prevent
        @blur.native="validatePassword(form.password, hasBlurred.password)"
      ></b-form-input>
      <b-form-invalid-feedback>
        {{ hasBlurred.password.content }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      id="input-group-2"
      class="font-weight-bold mb-4"
      label="Repite tu contraseña"
      label-for="input-2"
    >
      <b-form-input
        id="input-2"
        v-model="form.confirmPassword"
        :state="confirmPasswordState"
        type="password"
        placeholder="Confirma tu contraseña"
        required
        size="lg"
        @keydown.space.prevent
        @copy.prevent
        @paste.prevent
        @blur.native="
          validatePassword(form.confirmPassword, hasBlurred.confirmPassword),
            validatePasswordEquality()
        "
      >
      </b-form-input>
      <b-form-invalid-feedback>
        {{ hasBlurred.confirmPassword.content }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-between">
        <b-button
          class="mt-4 mb-3"
          variant="outline-primary"
          size="lg"
          @click="decrementRegisterStep()"
          >Volver
        </b-button>
        <b-button class="mt-4 mb-3" size="lg" type="submit" variant="primary">
          Siguiente
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "FormSetPassword",
  data() {
    return {
      form: {
        password: "",
        confirmPassword: "",
        isRegistering: true,
      },
      hasBlurred: {
        password: {
          state: null,
          content: "",
        },
        confirmPassword: {
          state: null,
          content: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "setPassword"
    ]),
    passwordState() {
      return this.hasBlurred.password.state;
    },
    confirmPasswordState() {
      return this.hasBlurred.confirmPassword.state;
    },
  },
  methods: {
    ...mapMutations(["decrementRegisterStep"]),
    async onSubmit(event) {
      event.preventDefault();

      this.validatePassword(this.form.password, this.hasBlurred.password);
      this.validatePassword(
        this.form.confirmPassword,
        this.hasBlurred.confirmPassword
      );
      this.validatePasswordEquality();
      if (
        this.hasBlurred.password.state === null &&
        this.hasBlurred.confirmPassword.state === null
      ) {
        this.$store.commit("setPassword", this.form.password);
        await this.$store.dispatch("registerUser", this.form);
        this.$router.push("/verificacion");
      }
    },
    validatePassword(value, obj) {
      if (value === "") {
        obj.state = false;
        obj.content = "Debes ingresar tu contraseña.";
      } else {
        obj.state = value.length > 7 ? null : false;
        obj.content = "Contraseña debe tener al menos 8 caracteres.";
      }
    },
    validatePasswordEquality() {
      if (this.form.password !== this.form.confirmPassword) {
        this.hasBlurred.confirmPassword.state = false;
        this.hasBlurred.confirmPassword.content = "La contraseña no coincide.";
      }
    },
  },
};
</script>
