<template>
  <b-card class="mb-3">
    <b-row>
      <b-col lg="4">
        <h6 class="mb-3 mb-lg-2">{{ name }}</h6>
        <h6 class="mb-3 mb-lg-0">Rol {{ role }}</h6>
      </b-col>
      <b-col lg="4">
        <h6 class="mb-3 mb-lg-2">{{ commune }}</h6>
        <h6 class="mb-3 mb-lg-0">{{ region }}</h6>
      </b-col>
      <b-col lg="4">
        <h6 class="mb-3 mb-lg-2">Solicitado por: {{ petitioner }}</h6>
        <h6 class="mb-3 mb-lg-0">Fecha: {{ date }}</h6>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "RequestInfo",
  props: {
    name: {
      type: String,
      default: ""
    },
    role: {
      type: String,
      default: ""
    },
    region: {
      type: String,
      default: ""
    },
    commune: {
      type: String,
      default: ""
    },
    petitioner: {
      type: String,
      default: ""
    },
    date: {
      type: String,
      default: ""
    }
  }
}
</script>