<template>
  <b-sidebar 
    id="sidebar-1" 
    width="280px" 
    bg-variant="dark" 
    :backdrop="$device.isIOS || $device.os.android || $device.isMobile" 
    :visible="$device.isDesktop" 
    :no-close-on-route-change="$device.isDesktop"
    text-variant="white"
    :no-header-close="$device.isDesktop"
    header-class="p-0"
    sidebar-class="p-4"
  >
    <router-link :to="routerLink">
      <img
        src="../../assets/logo-iprop-white.svg"
        alt=""
        class="logo-sidebar mb-5"
      />
    </router-link>
    <nav-items></nav-items>
  </b-sidebar>
</template>
<script>
import NavItems from "./NavItems.vue";
import store from "@/store";

export default {
  name: "SideBar",
  components: { NavItems },
  data() {
    return {
      routerLink: "",
    };
  },
  created() {
    if (store.getters.role === 1) {
      this.routerLink = "/corredor/propiedades";
    }
    if (store.getters.role === 4) {
      this.routerLink = "/admin/solicitudes";
    }
  }
};
</script>
