<template>
  <b-list-group-item>
    <b-row>
      <b-col > 
        <b-form-checkbox
          v-model="checkedFromList"
          :name="`checkbox-${id}`"     
          @change="selected()" 
        >
          <h6 class="mb-0 text-muted">
            {{ title }}
          </h6> 
        </b-form-checkbox>
      </b-col> 
      <b-col md="4">
        <h6 class=" text-right ml-4"> 
          <div v-if="price > 0">$ {{ price }}</div>
          <div v-else>Gratis</div>
        </h6>
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DocumentRequestItem",
  props: ["title", "price", "id"],
  data() {
    return {
      checked: false, 
    };
  },
  computed: {
    ...mapGetters([ 
      "getSimpleDocumentsSelected"
    ]),
    checkedFromList: { 
      get() { 
        const foundDocument = this.getSimpleDocumentsSelected.find(doc => doc.id === this.id);
        return foundDocument !== undefined ? true : false; 
      },
      set(newValue) { this.checked = newValue }
    }
  }, 
  methods:{ 
    selected(){ 
      if(this.checked){
        this.$store.dispatch("addSimpleDocumentList", {id: this.id})
      }
      if(!this.checked){
        this.$store.dispatch("deleteSimpleDocumentList", {id: this.id}) 
      }
    }
  }
};
</script>
