<template>
  <b-card header-class="bg-white py-3" body-class="px-0" class="mb-4">
    <template #header>
      <header-card
        title="Propietarios"
        content="Información sobre propietarios"
        icon="user-group"
      />
    </template>
    <b-table class="table-custom mb-0" :items="proprietary"></b-table>
  </b-card>
</template>

<script>
import HeaderCard from "./HeaderCard.vue";

export default {
  name: "OwnersCard",
  components: {
    HeaderCard,
  },
  props: {
    proprietary: {
      type: Array,
      required: true,
      default() {
        return [{ nombre: "-" }];
      },
    },
  },
};
</script>
