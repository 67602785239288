import axios from 'axios';
import Config from "@/../config.json";
import login from "@/services/login.service";

const documents = {
  async obtainDocuments(values) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_OBTAIN_DOCUMENTS,
        values,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data.data.documents;
    } catch (err) {
      return err.response.data.message;
    }
  },

};
export default documents;