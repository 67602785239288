<template>
  <b-container class="mt-5">
    <b-row class="justify-content-center">
      <img src="../../assets/logo-iprop-green.svg" alt="" class="mb-4">
    </b-row>
    <b-row class="justify-content-center">
      <b-col md="8" lg="6">
        <b-card body-class="px-5 py-4">
          <h2>Crea tu contraseña</h2>
          <p class="text-muted">Ahora debes crear tu nueva contraseña, recuerda que debe tener un <b>mínimo de 8 caracteres</b> para que se válida.</p>
          <form-new-password />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FormNewPassword from '../../components/forms/FormNewPassword.vue'

export default {
  name: "NewPassword",
  components: {
    FormNewPassword
  },
  data() {
    return {
      password: null,
      type: "password",
      icon:'fa-eye'
    }
  },
  methods: {
    showPassword() {
      if(this.type === 'password') {
        this.type = 'text'
        this.icon = 'fa-eye-slash'
      } else {
        this.type = 'password'
        this.icon = 'fa-eye'
      }
     }
  }
}
</script>
