<template>
  <b-list-group-item id="id">
    <b-row>
      <b-col md="6" lg="8">
        <b-row>
          <b-col cols="12" lg="6" class="d-flex align-items-center mb-2 mb-lg-0">
            <h6 class="mb-0">{{ name }}</h6>
          </b-col>
          <b-col cols="12" lg="6" class="d-flex align-items-center mb-2 mb-lg-0">
            <span class="text-muted">Subido el {{ date }}</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="6" lg="4" class="d-flex align-items-center justify-content-end">
        <b-button v-if="(type === 'application/pdf')" @click="viewDocument(id, source)" variant="outline-secondary" size="sm" class="mr-2">Abrir
          documento</b-button>
        
        <b-button v-if="(type != 'application/pdf')" variant="outline-secondary" size="sm" class="mr-2" 
          :download="name" :href="document.content" @click="handleDownloadClick" title='Descargar documento pdf' >
          Descargar
        </b-button>
        
        <b-button v-b-modal="`deleteDocument${id}`" variant="outline-secondary" size="sm">Eliminar</b-button>
      </b-col>
    </b-row> 
    <b-modal :id="`viewDocument${id}${source}`" :title="name" size="lg" lazy>
      <vue-pdf-embed :source="document.content"/>
      <template #modal-footer>
        
        <b-button variant="primary" size="sm" class="mr-2" 
          :download="name" :href="document.content" title='Descargar documento pdf'>
          Descargar
        </b-button>
        
        <b-button variant="outline-primary" @click="cancel(`viewDocument${id}${source}`)">
          Salir
        </b-button>
      </template>
    </b-modal>
    <b-modal :id="`deleteDocument${id}`" title="Eliminar documento">
      <p class="text-center">¿Estás seguro que deseas eliminar el documento?</p>
      <template #modal-footer>
        <b-button variant="outline-primary" @click="cancel(`deleteDocument${id}`)">
          Cancelar
        </b-button>
        <b-button variant="primary" @click="deleteDocument(id, source)">
          Eliminar
        </b-button>
      </template>
    </b-modal>
  </b-list-group-item>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { Share } from '@capacitor/share';
import { mapGetters } from "vuex";

export default {
  name: "DocumentItem",
  components: { VuePdfEmbed },
  props: {
    id: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    },
    file: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'Certificado'
    },
    type: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: '01-01-2021'
    }
  },
  computed: {
    document() {
      return this.$store.getters.getDocument !== undefined ? this.$store.getters.getDocument : null;
    },
    ...mapGetters(["getSelectedProperty"]),
  },
  methods: {
    async viewDocument() {
      this.$store.dispatch('viewDocument', { documentId: this.id, source: this.source }).then(() => {
        //console.log("this.id: ");
        //console.log(this.id);
        this.$emit('viewed', this.id);
        this.$bvModal.show(`viewDocument${this.id}${this.source}`);
      });
      await this.$store.dispatch('fetchDocuments', { propertyId: this.$store.getters.getSelectedProperty.id });
    },
    async deleteDocument() {
      try {
        this.cancel(`deleteDocument${this.id}`);
        await this.$store.dispatch('deleteDocument', { documentId: this.id, source: this.source });
        //this.$emit('removed', this.id);
        await this.$store.dispatch('fetchDocuments', { propertyId: this.$store.getters.getSelectedProperty.id });
        this.$bvToast.toast('Se eliminó el documento con éxito.', {
          title: "¡Listo!",
          solid: true,
          variant: "success",
        });
      } catch (e) {
        console.log("error: "+e)
        this.$bvToast.toast('No pudimos eliminar tu documento.', {
          title: "¡Hubo un problema!",
          solid: true,
          variant: "danger",
        });
      }
    },
    cancel(id) {
      this.$bvModal.hide(id)
    },
    async shareDocument(source) {
      try {
        console.log(source);
        const fileUri = source;
       
        const shareResult = await Share.share({
          dialogTitle: "Compartir documento",
          url: fileUri // I also tried using the files property and passing the value as [fileUri] but the same thing happens
        });

        console.log(shareResult.activityType);
      } catch (error) {
        console.log(error);
      }
    },
    handleDownloadClick() {
      // Llama a tu función shareDocument con la URI del documento
      this.shareDocument(this.document);
    }
  },
}
</script>
