<template>
  <b-card class="mb-4">
    <b-row>
      <b-col>
        <h6>Documentos solicitados</h6>
        <p class="text-muted mb-0">{{ totalDocuments }}</p>
      </b-col>
      <b-col>
        <h6>Valor total</h6>
        <p class="text-muted mb-0">$ {{ totalPrice }}</p>
      </b-col>
      <b-col>
        <h6>Método de pago</h6>
        <p class="text-muted mb-0">Webpay</p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "RequestInfoPayment",
  props: {
    totalDocuments: {
      type: Number
    },
    totalPrice: {
      type: String,
      default: ''
    },
    paymentMethod: {
      type: String,
      default: ''
    }
  }
}
</script>