<template>
  <b-modal id="onboarding" hide-header hide-footer >
    <div class="text-center">
      <!-- screen 1 -->
      <div v-for="(panel, index) in panels" :key="index">
        <div v-if="index + 1 == step">
          <img class="py-4" :src="require(`../../assets/svg/${panel.src}`)" />
          <h4 class="py-2">{{ panel.title }}</h4>
          <p class="m-auto col-12 col-sm-11">
            {{ panel.subtitle }}
          </p>
        </div>
      </div>
    </div>
    <div class="text-center my-4">
      <b-button
        class="col-7 col-sm-5"
        variant="primary"
        size="lg"
        @click="nextStep"
        >{{ 3 > step ? "Siguiente" : "Comenzar" }}
      </b-button>
      <div class="step-dots">
        <div
          v-for="index in 3"
          :key="index"
          class="step-dot"
          :class="{ active: index == step }"
          @click="clickChange(index)"
        ></div>
      </div>
    </div>

    <div class="mb-sm-4 mb-4 mb-sm-5">
      <b-button @click="nextStep" variant="primary" size="lg"
        ><small>Comenzar</small></b-button
      >
     
      <!-- <div class="step-dot" :class="{ active: 1 == step }"></div>
      <div class="step-dot" :class="{ active: 2 == step }"></div>
      <div class="step-dot" :class="{ active: 3 == step }"></div> -->
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "OnboardingModal",
  data() {
    return {
      step: 1,
      currentPage: 5,
    };
  },
  methods: {
    nextStep() {
      if (this.step != 3) {
        this.step++;
      } else {
        this.step = 1;
      }
    },
  },
};
</script>

<style></style>
