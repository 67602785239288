<template>
  <div> <!-- Elemento raíz que envuelve todo lo demás -->
    <b-container>
      <b-row>
        <b-col md="6">
          <b-button variant="link" class="mb-4" @click="$router.push('/corredor/propiedades')">
              ← Volver a la lista de propiedades
          </b-button>
        </b-col>
        <!--
        <b-col md="6">
          <b-button variant="info" class="mb-4" @click="$router.push('corredor/infocheck')">
              Buscar otra propiedad →
          </b-button>
        </b-col>
        -->
      </b-row>
      <b-row>
        <b-col md="6">
          <h2>Agendar Experto</h2>
          <p class="text-muted mb-4">
            Selecciona un experto inmobiliario de la lista para agendar una visita a tu propiedad en {{ getSelectedProperty.address }}. Una vez agendado, nuestro experto se pondrá en contacto para coordinar la visita.
          </p>
          <b-list-group>
            <b-list-group-item 
              v-for="appraiser in appraisers" 
              :key="appraiser.id"
              class="d-flex align-items-center">
              <!--
              <b-button variant="outline-secondary" class="btn-icon" @click="showModal(appraiser)">
                <font-awesome-icon :icon="['fas', 'chart-bar']" />
              </b-button>
              -->
              <div class="appraiser-info mr-1">
                {{ appraiser.name }}
                <b-badge :variant="getBadgeVariant(appraiser.rating)" class="ml-2">{{ appraiser.rating }}</b-badge>
              </div>
              <b-button variant="primary" class="ml-auto" @click="scheduleAppointment(appraiser)">Agendar</b-button>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>

    <!-- Modal for ApexChart -->
    <b-modal
      v-model="modalShow"
      :title="`Tasaciones de ${selectedAppraiser && selectedAppraiser.name ? selectedAppraiser.name : ''}`"
      size="lg"
      hide-footer
    >
      <apexchart
        type="bar"
        :options="chartOptions"
        :series="chartSeries"
      ></apexchart>
    </b-modal>
    <!--Modal for vue-calendly-->
    <b-modal
      v-model="modalShow2"
      :title="`Agenda de experto inmobiliario`"
      size="lg"
      hide-footer
     >
      <vue-calendly url="https://calendly.com/tasacion-inciti/tasacion-de-propiedad" :height="600"></vue-calendly>
    </b-modal>
  </div>
</template>


<style scoped>
.btn-icon {
margin-right: 0.5rem;
}
.appraiser-info {
flex-grow: 1;
display: flex;
align-items: center;
}
</style>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ApexCharts from 'apexcharts';
import VueApexCharts from 'vue-apexcharts';
import Vue from 'vue';
import VueCalendly from 'vue-calendly';

Vue.use(VueCalendly);

export default {
  components: {
    'font-awesome-icon': FontAwesomeIcon,
    apexchart: VueApexCharts
  },
  data() {
    return {
      appraisers: [
      // Simulación de tasadores
      { id: 1, name: "Experto inmobiliario de Inciti", rating: 4.5, url: "https://calendly.com/tasacion-inciti/tasacion-de-propiedad" },
      //{ id: 2, name: "Tasador María García", rating: 4.7 },
      //{ id: 3, name: "Tasador Carlos Sánchez", rating: 4.2 },
      // Agregar más tasadores
    ],
    selectedAppraiser: null,
    modalShow: false,
    modalShow2: false,
    chartOptions: {
      // ...opciones del gráfico
    },
    chartSeries: [
      // ...series del gráfico
    ]
  };
},
methods: {
  goBack() {
    this.$router.go(-1);
  },
  getBadgeVariant(rating) {
    if (rating >= 4.5) return "success";
    if (rating >= 4.0) return "warning";
    return "secondary";
  },
  scheduleAppointment(appraiser) {
    this.selectedAppraiser = appraiser;
    this.modalShow2 = true;
    // Código para integrar Calendly y pasar la información del tasador y la propiedad
  },
  showModal(appraiser) {
    this.selectedAppraiser = appraiser;
    this.modalShow = true;
    this.generateChartData(appraiser.id);
  },
  generateChartData(appraiserId) {
    // Simula datos de tasaciones mensuales para el tasador seleccionado
    this.chartSeries = [{
      name: 'Tasaciones',
      data: this.simulateMonthlyAppraisals(appraiserId)
    }];
    // ...configura `this.chartOptions` si es necesario
  },
  simulateMonthlyAppraisals(appraiserId) {
    // Genera datos aleatorios para el gráfico
    return Array.from({ length: 6 }, () => Math.floor(Math.random() * 10) + 1);
  }
},
computed: {
  getSelectedProperty() {
    // Obtener la información de la propiedad seleccionada
    return this.$store.getters.getSelectedProperty;
  }
}
};
</script>

<style scoped>
/* Estilos adicionales si son necesarios */
/* Para un botón de 'volver' fijo en la parte superior izquierda */
.fixed-back-button {
position: fixed;
top: 1rem; /* Ajusta la posición según tu cabecera o navbar */
left: 1rem;
z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
}
</style>
