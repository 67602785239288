<template>
  <div class="d-flex flex-column align-items-center">
    <b-row>
      <div class="loader-container">
        <img :src="href" class="imagen img-fluid mb-3" />
      </div>
    </b-row>
    <b-row>
      <progress-bar
        :title="'Estamos generando tus certificados... '"
        :variant="'primary'"
        :height="8"
        :items="progress"
        :quantity="100"
      />
    </b-row>
  </div>
</template>
<script>
import progressBar from "../misc/ProgressBar.vue";
import image from "@/assets/loading-files.gif";
export default {
  name: "LoaderReport",
  components: {
    progressBar,
  },
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      href: image,
    };
  },
};
</script>
