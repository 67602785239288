<template>
  <b-form @submit="submitForm">
    <h2>Confirmar documentos</h2>
    <p class="text-muted">
      Este es el resumen de tu solicitud para la propiedad:
    </p>
    <card-info-property
      :name="getRequestSelectedProperty.name"
      :commune="getRequestSelectedProperty.commune"
      :region="getRequestSelectedProperty.region"
      :role="getRequestSelectedProperty.role"
    />
    <checkout-list />
    <b-row>
      <b-col>
        <b-button variant="outline-primary" size="lg" @click="reduceStep"
          >Volver</b-button
        >
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button variant="primary" size="lg" type="submit">
          Comprar
        </b-button>
      </b-col>
    </b-row>

    <form id="myForm" method="post" :action="url">
      <input type="hidden" name="token_ws" :value="token" />
    </form>
  </b-form>
</template>

<script>
import axios from "axios";
import Config from "@/../config.json";
import CheckoutList from "../misc/CheckoutList.vue";
import CardInfoProperty from "../cards/CardInfoProperty.vue";
import { mapGetters } from "vuex";

export default {
  name: "FormDocumentsConfirmation",
  components: {
    CheckoutList,
    CardInfoProperty,
  },
  data() {
    return {
      token: "",
      url: "",
    };
  },
  computed: {
    ...mapGetters(["getRequestSelectedProperty", "getDocumentsTotalSale"]),
  },

  methods: {
    async submitForm(event) {
      event.preventDefault();

      let amount = this.getDocumentsTotalSale;

      // await this.tbk(amount);
      await this.tbk(amount).then(
        localStorage.setItem("requestDone", JSON.stringify({ request: true }))
      );

      this.$router.push({
        //path: "/corredor/solicitudes/solicitud-completada",
        //query: {
          //validation: "skipped",
        //}, // compra
        path: "/corredor/solicitudes/solicitud-completada",
        query: {
        token_ws:
        "11317698421f1db9fbd9ffaf8966f05aaf5d6739b781be53f95211da83f30392",
        }, // compra
        // query: {
        //   token_ws:
        //     "11317698421f1db9fbd9ffaf8966f05aaf5d6739b781be53f95211da83f30392-asd",
        // }, // compra rechazada
        // query: {
        //   generated: false,
        //   TBK_ID_SESION: "some-fake-value",
        //   TBK_ORDEN_COMPRA: "another-fake-value",
        // }, // timeout
        // query: {
        //   generated: false,
        //   TBK_TOKEN: "some-value-different-from-undefined",
        // }, // compra cancelada por el usuario
      });

      this.$store.commit("isFinalStep");
      // //this.$store.commit("restoreStep");
      // // this.$store.commit("restoreFinalStep");
      this.$store.dispatch("registerRequestDocument");
    },
    reduceStep() {
      this.$store.commit("decrementStep");
    },

    tbk(amount) {
      return new Promise((resolve, reject) => {
        axios({
          url: Config.VUE_APP_URL_BASE_TRANSACTION,
          method: "POST",
          data: {
            amount: amount,
            returnUrl:
              "http://127.0.0.1:8081/corredor/solicitudes/solicitud-completada",
          },
        })
          .then((response) => {
            resolve(response);
            this.url = response.data.transaction.url;
            this.token = response.data.transaction.token;

            setTimeout(() => {
              document.getElementById("myForm").submit();
            }, 50);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
</script>
