<template>
  <div>
    <h5>{{ title }}</h5>
    <b-card class="p-3 mb-2">
      <progress-bar
        v-for="(doc, index) in documents"
        :key="index"
        :title="doc.title"
        :items="doc.items"
        :variant="bars[index]"
        :quantity="doc.quantity"
      ></progress-bar>
    </b-card>
  </div>
</template>

<script>
import ProgressBar from "./ProgressBar.vue";
export default {
  name: "ProgressDocument",
  components: { ProgressBar },
  props: {
    title: { type: String, required: true },
    documents: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      bars: ["success", "info", "warning", "danger"],
    };
  },
};
</script>
