<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col sm="8" md="5" lg="4" class="text-center">
        <img src="../../assets/svg/empty.svg" alt="" class="d-block mx-auto mb-4">
        <h5>¡Oops!</h5>
        <p>No pudimos encontrar resultados para la propiedad que buscabas. Inténtalo nuevamente.</p>
        <b-button variant="primary" size="lg" block>Volver al buscador</b-button>
        <b-button  href="/corredor/propiedades" variant="outline-primary" size="lg" block>Ir a inicio</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "PropertiesNotFound"
}
</script>