export const formatCertificate = (certificate, typeId) => {
  let pdf = [];

  if (certificate?.debtContribution) pdf.push(certificate?.debtContribution);

  if (certificate?.certificateNonExpropriation)
    pdf.push(certificate?.certificateNonExpropriation);

  if (certificate?.detailedRealEstateModule)
    pdf.push(certificate?.detailedRealEstateModule);

  let tempLastTransfer = certificate?.propertyTransferHistory?.lastTransfer
    ? [certificate?.propertyTransferHistory?.lastTransfer]
    : [];
  let tempOlderTransfers = certificate?.propertyTransferHistory?.olderTransfers
    ? [...certificate?.propertyTransferHistory?.olderTransfers]
    : [];
  const tempTransferHistory = [...tempLastTransfer, ...tempOlderTransfers];

  let certificateType = "basic";

  if (typeId === 1) certificateType = "basic";

  if (typeId === 2) certificateType = "premium";

  if (typeId === 3) certificateType = "full";

  const transferHistory = tempTransferHistory.map((transfer) => ({
    fecha: transfer.fecha,
    precioCompra: transfer.uf,
    foja: transfer.foja,
    numero: transfer.numero,
    año: transfer.ano,
    direccion: transfer.direccion,
    ufm2: transfer.ufM2U,
  }));

  const transfersNeighbors =
    certificate?.neighborsFromPropertyInfo?.neighbors?.map((transfer) => ({
      fecha: transfer.fecha,
      año: transfer.ano,
      precioCompra: transfer.uf,
      direccion: transfer.direccion,
      foja: transfer.foja,
      numero: transfer.numero,
      superficie: transfer.supCons,
      ufm2: transfer.ufM2U,
    }));

  const mapData = {
    propertyToMap: {
      type: "Feature",
      geometry: {
        ...certificate?.neighborsFromPropertyInfo?.propertyInfo.geojson,
      },
      properties: {
        ...certificate?.neighborsFromPropertyInfo?.propertyInfo,
      },
    },
    neighborsToMap: certificate?.neighborsFromPropertyInfo?.neighborsToMap
      ? [...certificate?.neighborsFromPropertyInfo?.neighborsToMap]
      : [],
  };

  const report = {
    data: {
      lastTransfer: certificate?.propertyTransferHistory?.lastTransfer,
      simpleRealEstate:
        certificate?.getSiiBDData?.simpleRealEstate !== undefined
          ? JSON.parse(
              JSON.stringify(certificate?.getSiiBDData.simpleRealEstate)
            )
          : undefined,
      neighbors:
        certificate?.neighbors !== undefined
          ? JSON.parse(JSON.stringify(certificate?.neighbors))
          : undefined,
      siiBbData:
        certificate?.siiBbDataFromFiles?.dataSII !== undefined
          ? JSON.parse(JSON.stringify(certificate?.siiBbDataFromFiles.dataSII))
          : undefined,
      pdf: pdf.length > 0 ? pdf : [],
      debtContributionData:
        certificate?.debtContributionData?.debtContributionData !== undefined
          ? JSON.parse(
              JSON.stringify(
                certificate?.debtContributionData?.debtContributionData
              )
            )
          : undefined,

      transferHistory: transferHistory ? transferHistory : [],
      transfersNeighbors: transfersNeighbors ? transfersNeighbors : [],
      mapData,
    },
    type: certificateType,
  };

  return report;
};

export const getNeighborsToMap = (certificate) => {
  return certificate?.neighborsFromPropertyInfo?.neighborsToMap;
};
