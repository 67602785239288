<template>
  <div v-if="hasRequests" class="container">
    <b-row class="mb-3">
      <b-col>
        <h2 class="mb-0">Solicitudes</h2>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button variant="primary" size="lg" :to="{ name: 'CreateRequest' }">
          <font-awesome-icon icon="fa-solid fa-plus" class="mr-md-2" />
          <span class="d-none d-md-inline-block">Crear solicitud </span>
        </b-button>
      </b-col>
    </b-row>
    <b-list-group>
      <card-request
        v-for="(request, index) in getRequestsList"
        :id="request.id"
        :key="index"
        :name="request.name"
        :date="request.createdAt"
        :ready-documents="request.readyDocuments"
        :total-documents="request.totalDocuments"
        :status="request.status"
        :property="request.property"
        :payment="request.payment"
        :documents="request.simple_documents"
      ></card-request>
    </b-list-group>
  </div>
  <b-container v-else>
    <b-row class="justify-content-center">
      <b-col md="8" lg="5">
        <h2 class="mb-3">Solicita documentos para tu propiedad</h2>
        <p class="text-muted mb-4">
          Accede a diferentes certificados de distintas instituciones en un solo
          lugar; tales como Municipalidades, Conservadores, Serviu, entre otros.
        </p>
        <div class="media mb-4">
          <h3>
            <font-awesome-icon
              icon="fa-solid fa-check-circle"
              class="text-success mr-3"
            />
          </h3>
          <div class="media-body">
            <h6>
              Conoceras el estado de tus documentos desde el momento que generes
              tu solicitud.
            </h6>
          </div>
        </div>
        <div class="media mb-4">
          <h3>
            <font-awesome-icon
              icon="fa-solid fa-check-circle"
              class="text-success mr-3"
            />
          </h3>
          <div class="media-body">
            <h6>
              Todos tus documentos en un solo lugar, podrás verlos cuando
              quieras.
            </h6>
          </div>
        </div>
        <div class="media mb-4">
          <h3>
            <font-awesome-icon
              icon="fa-solid fa-check-circle"
              class="text-success mr-3"
            />
          </h3>
          <div class="media-body">
            <h6>
              Proceso 100% seguro, toda tu información estará protegida por
              nosotros.
            </h6>
          </div>
        </div>
        <b-button variant="primary" size="lg" :to="{ name: 'CreateRequest' }">
          <font-awesome-icon icon="fa-solid fa-plus" class="mr-2" />
          <span>Crear solicitud </span>
        </b-button>
      </b-col>
      <b-col class="d-none d-lg-block" lg="7">
        <img
          src="../../assets/img-request.svg"
          alt=""
          class="d-block mx-auto"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CardRequest from "../../components/cards/CardRequest.vue";
import { mapGetters } from "vuex";

export default {
  name: "RealtorRequests",
  components: { CardRequest },
  computed: {
    ...mapGetters(["getRequestsList"]),
    hasRequests() {
      return this.getRequestsList.length === 0 ? false : true;
    },
  },
  async mounted() {
    this.$store.commit("setStep", 1);
    this.$store.dispatch("fetchRequests");
  },
};
</script>
