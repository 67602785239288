<template>
  <div v-if="!getFinalStep" class="container">
    <b-row class="justify-content-center">
      <b-col lg="7" xl="6">
        <title-back back="Solicitudes" :items="backItems"></title-back>
        <form-select-property v-if="getStep === 1" />
        <form-select-documents v-if="getStep === 2" />
        <!-- <form-payment-method v-if="getStep === 3" /> -->
        <form-documents-confirmation v-if="getStep === 3" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TitleBack from "@/components/misc/TitleBack.vue";
import FormSelectProperty from "@/components/forms/FormSelectProperty.vue";
import FormSelectDocuments from "@/components/forms/FormSelectDocuments.vue";
import FormDocumentsConfirmation from "@/components/forms/FormDocumentsConfirmation.vue";

import { mapGetters } from "vuex";

export default {
  name: "CreateRequest",
  components: {
    TitleBack,
    FormSelectProperty,
    FormSelectDocuments,
    FormDocumentsConfirmation,
  },
  data() {
    return {
      requestDone: false,
      backItems: [
        {
          text: "Solicitudes",
          to: "/corredor/solicitudes",
        },
        {
          text: "Crear solicitud",
          active: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getStep", "getFinalStep"]),
  },
  mounted() {
    this.requestDone = JSON.parse(localStorage.getItem("requestDone"));
    if (this.getFinalStep) {
      this.$store.commit("restoreStep");
      this.$store.commit("restoreFinalStep");
    }
  },
  methods: {
    restoreFlow() {
      this.$router.push({ path: "/corredor/solicitudes" });
      this.$store.commit("restoreStep");
      this.$store.commit("restoreFinalStep");
    },
  },
};
</script>
