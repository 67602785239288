<template>
  <b-row class="justify-content-center">
    <b-col md="6" class="text-center">
      <img :src="require(`../../assets/svg/report-error.svg`)" class="d-block mx-auto mb-4" alt="">
      <h2>¡Oops!</h2>
      <p class="text-muted">Hubo un problema al intentar generar tu informe, intenta nuevamente</p>
      <b-button variant="primary">Reintentar</b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ReportError"
}
</script>