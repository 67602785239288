import axios from 'axios';
import Config from "@/../config.json";

const emailExist = {
  async getEmailExist(values) {
    try { 
      console.log(values);
      const data = await axios.post(
        Config.VUE_APP_URL_EMAIL_EXIST,
        values
      );
      console.log(data);
      return data;
    } catch (error) {
      throw error
    }

  },

};
export default emailExist;