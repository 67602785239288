/* eslint-disable */
export default {
  state: {
    owner: null,
    firstLogin: false,
  },
  mutations: {
    setUserData(state, user) {
      state.owner = user;
    },
    setFirstLogin(state, firstLogin) {
      state.firstLogin = firstLogin;
    },
    setLogout(state) {
      state.owner = null;
    },
  },

  getters: {
    getIsLoggedIn(state) {
      return state.owner ? true : false;
    },
    getUserData(state) {
      return state.owner;
    },
    getFirstLogin(state) {
      return state.firstLogin;
    },
    getUserDataName(state) {
      return state.owner.firstName + " " + state.owner.lastName;
    },
    getInitialsName(state) {
      return state.owner.firstName.charAt(0) + state.owner.lastName.charAt(0);
    },
    role(state) {
      return state.owner.role;
    },
  },
};
