<template>
  <b-card header-class="bg-white py-3" body-class="px-0" class="mb-4">
    <template #header>
      <header-card
        title="Precio Transferencia Vecinos"
        content="Información de transferencias de vecinos con superficie similiar de los últimos años"
        icon="arrow-right-arrow-left"
      />
    </template>
    <b-table
      responsive
      class="text-nowrap mb-0"
      :fields="fields"
      :items="transfNeighbor"
      show-empty
      ><template #empty>
        <p class="w-100 mt-3">
          No existen transferencias de vecinos similares en nuestros registros.
        </p>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import HeaderCard from "./HeaderCard.vue";

export default {
  name: "TransferPrice",
  components: {
    HeaderCard,
  },
  props: {
    transfNeighbor: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      fields: [
        {
          key: "fecha",
          label: "Fecha",
          sortable: false,
          formatter: (value) => {
            if (typeof value !== "string") return "";

            const date = new Date(value);
            return date
              .toISOString()
              .slice(0, 10)
              .split("-")
              .reverse()
              .join("-");
          },
        },
        {
          key: "direccion",
          label: "Dirección",
          sortable: false,
        },
        {
          key: "precioCompra",
          label: "Precio",
          sortable: false,
          formatter: (value) => {
            return `UF ${value}`;
          },
        },
        {
          key: "superficie",
          label: "Superficie",
          sortable: false,
          formatter: (value) => {
            return `${value} m²`;
          },
        },
        {
          key: "ufm2",
          label: "UF/m²",
          sortable: false,
        },
      ],
    };
  },
};
</script>
