<template>
  <div>
    <b-row class="mb-3">
      <b-col cols="12">
        <title-back back="Solicitudes" :items="backItems"> </title-back>
      </b-col>
      <b-col cols="7">
        <h3 class="mt-2 mb-0">{{ getSelectedRequest.name }}</h3>
      </b-col>
      <b-col cols="5" class="d-flex align-items-end justify-content-end">
        <h5>
          <b-badge v-if="isFinished" variant="success" pill>Finalizada</b-badge>
          <b-badge v-else variant="info" pill>En curso</b-badge>
        </h5>
      </b-col>
    </b-row>
    <request-info
      :name="getSelectedRequest.property.name"
      :role="getSelectedRequest.property.role"
      :region="`Región ${getSelectedRequest.property.region}`"
      :commune="getSelectedRequest.property.commune"
      :petitioner="getUserDataName"
      :date="getSelectedRequest.date"
    />
    <request-info-payment
      :total-documents="getSelectedRequest.totalDocuments"
      :total-price="getSelectedRequest.payment.total"
      payment-method="Webpay"
    />
    <h5 class="mb-3">Documentos solicitados</h5>
    <request-document
      v-for="(document, index) in getSelectedRequest.documents"
      :key="index"
      :document="document"
    />
  </div>
</template>

<script>
import TitleBack from "@/components/misc/TitleBack.vue";
import RequestDocument from "@/components/cards/RequestDocument.vue";
import RequestInfo from "@/components/cards/RequestInfo.vue";
import RequestInfoPayment from "@/components/cards/RequestInfoPayment.vue";
import { mapGetters } from "vuex";

export default {
  name: "RequestDetail",
  components: {
    TitleBack,
    RequestDocument,
    RequestInfo,
    RequestInfoPayment,
  },
  data() {
    return {
      documents: [
        {
          category: "Municipal",
          name: "Certificado",
          file: "certificado.pdf",
          idFile: "modal-1",
          hasFile: true,
          isAdmin: true,
        },
      ],
      backItems: [
        {
          text: "Solicitudes",
          to: "/corredor/solicitudes",
        },
        {
          text: "",
          active: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getSelectedRequest", "getUserDataName"]),
    isFinished() {
      return this.getSelectedRequest.status === "finalizado" ? true : false;
    },
  },
  mounted() {
    this.backItems[1].text = `Solicitud ${this.getSelectedRequest.name}`;
  },
};
</script>
