<template>
  <div v-if="hasRequests">
    <h2 class="mt-4">Solicitudes</h2>
    <b-tabs class="mt-3 nav-tab-custom">
      <b-tab title="Recibidas" active>
        <b-list-group class="mt-3">
          <admin-card-request
            v-for="req in receivedRequest"
            :key="req.id"
            :request-id="req['id']"
            :total-documents="req.totalDocuments"
            :ready-documents="req.readyDocuments"
            :name="req.name"
            :status="req.status"
            :created-at="req.createdAt"
            :payment="req.payment"
            :user="req.user"
            :property="req.property"
            :simple_documents="req.simple_documents"
          />
        </b-list-group>
      </b-tab>
      <b-tab title="Terminadas">
        <b-list-group class="mt-3">
          <admin-card-request
            v-for="req in finishedRequest"
            :key="req.id"
            :request-id="req['id']"
            :total-documents="req.totalDocuments"
            :ready-documents="req.readyDocuments"
            :name="req.name"
            :status="req.status"
            :created-at="req.createdAt"
            :payment="req.payment"
            :user="req.user"
            :property="req.property"
            :simple_documents="req.simple_documents"
          />
        </b-list-group>
      </b-tab>
    </b-tabs>
  </div>
  <empty-page
    v-else
    img="empty.svg"
    :buttons="[
      {
        caption: 'Solicitar documentos',
        variant: 'primary',
        isModal: false,
        route: 'CreateRequest',
      },
    ]"
  >
    <template #title>
      <h2 class="mb-5 text-left text-md-center pl-2">Solicitudes</h2>
    </template>
    <template #subtitle>
      <p class="mt-4 col-9 col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto">
        No hay documentos solicitados de momento, prueba creando una solicitud
      </p>
    </template>
  </empty-page>
</template>

<script>
import EmptyPage from "../../components/misc/EmptyPage.vue";
import AdminCardRequest from "@/components/cards/AdminCardRequest.vue";
import { mapGetters } from "vuex";

export default {
  name: "AdminRequests",
  components: { EmptyPage, AdminCardRequest },
  data() {
    return {
      hasRequests: true,
      receivedRequest: [],
      finishedRequest: [],
    };
  },
  computed: {
    ...mapGetters(["getAdminRequestsList"]),
    hasRequestsComputed() {
      this.hasRequests = this.getAdminRequestsList === null ? false : true;
    },
    receivedRequestComputed() {
      return Object.values(this.getAdminRequestsList).filter(
        (request) => request.status === "pendiente"
      );
    },
    finishedRequestComputed() {
      return Object.values(this.getAdminRequestsList).filter(
        (request) => request.status === "finalizado"
      );
    },
  },
  async mounted() {
    this.$store.commit("setLoading");
    await this.$store.dispatch("fetchAdminRequestsList");
    this.hasRequestsComputed;
    this.receivedRequest = this.receivedRequestComputed;
    this.finishedRequest = this.finishedRequestComputed;
  },
};
</script>
