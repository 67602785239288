<template>
  <div class="px-0">
    <div v-if="hasProperty">
      <b-row class="mb-3">
        <b-col>
          <h2>Propiedades</h2>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button size="lg" variant="primary" @click="createProperty">
            <font-awesome-icon icon="fa-solid fa-plus" />
            <span class="ml-2 d-none d-sm-inline-block" >
              Nueva propiedad</span
            >
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-0 mt-sm-4">
        <b-col>
          <unit-card
            v-for="(property, index) in propertiesList"
            :key="property['id']"
            :property-id="property['id']"
            :name="property['name']"
            :plan="property['informe']"
            :region="property['region']"
            :commune="property['comuna']"
            :address="property['direccion']"
            :geojson="property['geojson']"
            :role="property['rol']"
            :inform="property['informe']"
            :owner="property['owner']"
            :page="property['pages']"
            :number="property['number']"
            :year="property['year']"
            :date="property['fecha_informe']"
            :request-documents="getRequestDocuments"
            :issued-documents="getIssuedDocuments"
          >
          </unit-card>
        </b-col>
      </b-row>
    </div>
    <b-container v-else>
      <b-row class="justify-content-center">
        <b-col md="8" lg="5">
          <h2 class="mb-3">Bienvenido a iProp</h2>
          <p class="text-muted mb-4">
            En iProp <b>simplificamos el acceso a la información inmobiliaria</b> para que todos podamos acceder a ella.
          </p>
          <div class="media mb-4">
            <h5 class="mb-0">
              <font-awesome-icon
                icon="fa-solid fa-check-circle"
                class="text-success mr-3"
              />
            </h5>
            <div class="media-body">
              <small><b>Encuentra la propiedad</b> usando la dirección, el rol o la geolocalización de tu teléfono</small>
            </div>
          </div>
          <div class="media mb-4">
            <h5>
              <font-awesome-icon
                icon="fa-solid fa-check-circle"
                class="text-success mr-3"
              />
            </h5>
            <div class="media-body">
              <small>
                <b>Obtén información básica oficial de la propiedad</b>, tales como Avalúo, Superficie, Líneas de construcción, entre otros. (Fuente: SII)
              </small>
            </div>
          </div>
          <div class="media mb-4">
            <h5>
              <font-awesome-icon
                icon="fa-solid fa-check-circle"
                class="text-success mr-3"
              />
            </h5>
            <div class="media-body">
              <small>
                <b>Valoriza la propiedad en el barrio</b> donde se encuentra. Obtén una valorización gratuita a partir de avalúos promedio para el sector o valoriza la propiedad a partir de ofertas de venta, ofertas de arriendo y valores de transacciones reales entre otros. (fuente: Compraventas oficiales en CBR)
              </small>
            </div>
          </div>
          <div class="media mb-4">
            <h5>
              <font-awesome-icon
                icon="fa-solid fa-check-circle"
                class="text-success mr-3"
              />
            </h5>
            <div class="media-body">
              <small>
                <b>Genera informes, obtén certificados o solicita uno de nuestros expertos inmobiliarios</b>. También puedes sacar fotografías de la propiedad y utilizarlas en el informe de la propiedad. 
              </small>
              <!--
              <small>
                <b>Genera informes, solicita tasadores o publica automáticamente a los distintos portales</b> más utilizados con un solo click. También puedes sacar fotografías de la propiedad y utilizarlas en la publicación. 
              </small>
              -->
            </div>
          </div>
          <div class="media mb-4">
            <h5>
              <font-awesome-icon
                icon="fa-solid fa-check-circle"
                class="text-success mr-3"
              />
            </h5>
            <div class="media-body">
              <small>
                <b>Conviértete en un experto inmobiliario</b> con iProp. seleccionamos los mejores corredores
disponibles en tu sector.
              </small>
            </div>
          </div>
          <b-button size="lg" variant="primary" @click="createProperty">
            <font-awesome-icon icon="fa-solid fa-search" />
            <span class="ml-2" >
              Busca tu propiedad</span
            >
          </b-button>
        </b-col>
        <b-col class="d-none d-lg-block" lg="7">
          <img
            src="../../assets/img-home.svg"
            alt=""
            class="d-block mx-auto"
          />
        </b-col>
      </b-row>
    </b-container>

    <!-- <onboarding-modal></onboarding-modal> -->
  </div>
</template>

<script>
import EmptyPage from "../../components/misc/EmptyPage.vue";
import UnitCard from "../../components/cards/UnitCard.vue";
import OnboardingModal from "../../components/misc/OnboardingModal.vue";
import login from "@/services/login.service"
import { mapGetters } from "vuex";
export default {
  name: "RealtorProperties",
  components: {
    UnitCard,
    EmptyPage,
    OnboardingModal,
  },
  data() {
    return {
      percentage: 0,
      propertiesList: null,
      hasProperties: true,
      filterSelected: "a",
      filterOptions: [
        { value: null, text: "Filtrar por" },
        { value: "a", text: "Todas" },
        { value: "b", text: "filtro tipo B" },
      ],
      orderSelected: null,
      orderOptions: [
        { value: null, text: "Ordenar por" },
        { value: "a", text: "filtro tipo A" },
        { value: "b", text: "filtro tipo B" },
      ],
      showOnboarding: false,
      isAdmin: true,
    };
  },
  async beforeMount() {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
  },
  computed: {
    ...mapGetters([
      "getPropertiesList",
      "getRequestDocuments",
      "getIssuedDocuments",
      "getShared",
    ]),
    hasProperty() {
      return this.getPropertiesList.length === 0 ? false : true;
    },
  },
  async mounted() {
    this.$store.commit("setSelectedProperty", {});
    this.$store.commit("setReport", {});
    await this.$store.dispatch("fetchPropertiesList");
    this.propertiesList = await this.getPropertiesList;

    if (this.$store.getters.getFirstLogin) {
      this.$bvModal.show("onboarding");
    }
  },
  methods: {
    createProperty() {
      this.$store.dispatch('clearPropertyData');
      this.$router.replace({
        path: "/corredor/propiedades/ingresar-propiedad",
      });
    },
  },
};
</script>
