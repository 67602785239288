<template>
  <b-nav vertical pills>
    <router-link class="my-nav" to="/corredor/infocheck">
      <font-awesome-icon icon="fa-solid fa-search" class="icon mr-2" />
      <p class="d-inline">Valoriza cualquier propiedad en todo Chile</p>
    </router-link>
    <router-link v-if="routes.admin" class="my-nav" to="/corredor/propiedades">
      <font-awesome-icon icon="fa-solid fa-house" class="icon mr-2" />
      <p class="d-inline">Propiedades</p>
    </router-link>
    <router-link class="my-nav" to="/corredor/pago">
      <font-awesome-icon icon="fa-solid fa-shopping-cart" class="icon mr-2" />
      <p class="d-inline">Comprar o suscribirse</p>
    </router-link>
    <!--
    <router-link class="my-nav" :to="routes.requests">
      <font-awesome-icon icon="fa-solid fa-envelope" class="icon mr-2" />
      <p class="d-inline">Solicitudes</p>
    </router-link>
    -->
    <router-link
      v-if="!routes.admin"
      class="my-nav"
      to="/admin/mapa-transferencias"
    >
      <font-awesome-icon icon="fa-solid fa-map" class="icon mr-2" />
      <p class="d-inline">Mapa de transferencias</p>
    </router-link>
  </b-nav>
</template>

<script>
import ToggleSidebar from "../../helpers/toggleSidebar";
import { mapGetters } from "vuex";

export default {
  name: "NavItems",
  extends: ToggleSidebar,
  computed: {
    ...mapGetters(["role"]),
    routes() {
      return {
        requests:
          this.role === 4 ? "/admin/solicitudes" : "/corredor/solicitudes/",
        admin: this.role === 1 ? true : false,
      };
    },
  },
};
</script>
