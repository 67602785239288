<template>
  <div class="py-2 mt-5 text-center">
    <slot name="title"> </slot>
    <img :src="require(`../../assets/svg/${img}`)" class="mb-4" />

    <slot name="subtitle"> </slot>

    <b-row align-h="center">
      <b-col
        v-for="(button, index) in buttons"
        :key="index"
        :cols="[button.length < 2 ? '12' : ''][0]"
      >
        <router-link v-if="!button.isModal" :to="button.route" replace>
          <b-button
            size="lg"
            class="mt-4"
            type="submit"
            :variant="button.variant"
          >
            {{ button.caption }}
          </b-button>
        </router-link>
        <div v-else>
          <b-button
            v-b-modal="button.route"
            size="lg"
            class="mt-4"
            type="submit"
            :variant="button.variant"
          >
            <small>
              {{ button.caption }}
            </small>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "EmptyPage",
  props: {
    img: {
      type: String,
      default: "empty.svg",
    },

    buttons: {
      type: Array,
      default() {
        return [];
      },
    },
  },
 
};
</script>
