<template>
  <b-modal
    id="modal-help"
    title="Encuentra el rol de tu propiedad"
    size="lg"
  >
    <b-row>
      <b-col lg="12">
        <p class="text-muted">Para poder encontrar el rol de tu propiedad, puedes hacerlo de dos formas</p>
      </b-col>
      <b-col lg="6">
        <b-card class="text-center mb-3">
          <img :src="require(`../../assets/logo-sii.png`)" class="mb-4" alt="">
          <h6>Servicio de Impuestos Internos (SII)</h6>
          <p class="small text-muted">Ingresando al SII con tu Clave Única o Clave Tributaria e ingresando  la dirección de tu propiedad, podrás conocer el número de rol.</p>
          <b-button target="_blank" href="https://www4.sii.cl/busquedarolesinternetui/#!/busquedaroles" variant="primary" size="lg">Ir a SII</b-button>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card class="text-center mb-3">
          <img :src="require(`../../assets/logo-tgr.png`)" class="mb-4" alt="">
          <h6>Tesorería General de la República (TGR)</h6>
          <p class="small text-muted">Ingresa a la página del TGR, y una vez allí puedes ingresar la dirección de tu propiedad para conocer el número de rol.</p>
          <b-button target="_blank" href="#" variant="primary" size="lg">Ir a TGR</b-button>
        </b-card>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button variant="secondary" size="lg" @click="cancel()">
        Entendido
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ModalRol"
}
</script>