<template>
  <div>
    <b-row class="mb-3">
      <b-col cols="12">
        <title-back back="Propiedades" :items="backItems"> </title-back>
      </b-col>
      <b-col cols="7" sm="7" class="d-flex align-items-center">
        <h2 class="text-capitalize property-title mb-0">
          {{ unit }}
        </h2>
      </b-col>
      <b-col class="d-flex align-items-center justify-content-end">
        <b-button
          v-b-modal="'modal-edit'"
          variant="outline-secondary"
          size="lg"
        >
          <font-awesome-icon icon="fa-solid fa-pen" class="mr-none mr-md-2" />
          <span class="d-none d-md-inline-block">Editar nombre</span>
        </b-button>
      </b-col>
    </b-row>
    <info-unit />
    <b-card v-if="showLastCertificate" class="bg-primary mb-3">
      <b-row class="justify-content-between">
        <b-col md="6" lg="6">
          <div class="media text-white">
            <font-awesome-icon
              icon="fa-solid fa-exclamation-circle "
              class="text-terciary fa-3x mr-3"
            />
            <div class="media-body">
              <h4>¡Estamos generando tus certificados!</h4>
              <p class="mb-3 mb-lg-0">
                Puedes consultar el estado de los certificados en tiempo real, este
                proceso dura entre 5 a 15 minutos.
              </p>
            </div>
          </div>
        </b-col>
        <b-col
          md="6"
          lg="6"
          xl="3"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button variant="terciary" size="lg" block @click="sendToReport"
            >Ver estado</b-button
          >
        </b-col>
      </b-row>
    </b-card>
    <short-cuts
      v-if="showcert"
      title="Certificados de la propiedad"
      subtitle="Genera certificados gratuitos para obtener información valiosa de tu propiedad (incluidos en cualquier plan de suscripción que tenga informes disponibles)"
      :button="{ caption: report.caption, variant: 'secondary' }"
      :has-inform="hasInform"
      :report="getSelectedProperty"
      :is-last-certificate-done="isLastCertificateDone"
      :show-penultimate-certificate="showPenultimateCertificate"
    />
    <short-cuts
      v-if="(getSelectedProperty.commune == 'PROVIDENCIA' || getSelectedProperty.commune == 'VITACURA' || getSelectedProperty.commune == 'LAS CONDES' || getSelectedProperty.commune == 'LO BARNECHEA' || getSelectedProperty.commune == 'ÑUÑOA' || getSelectedProperty.commune == 'NUNOA' || getSelectedProperty.commune == 'SANTIAGO' || getSelectedProperty.commune == 'LA REINA' || getSelectedProperty.commune == 'LA FLORIDA' || getSelectedProperty.commune == 'PEÑALOLEN' || getSelectedProperty.commune == 'PENALOLEN' || getSelectedProperty.commune == 'PEÑALOLÉN' || getSelectedProperty.commune == 'PENALOLÉN' || getSelectedProperty.commune == 'HUECHURABA' || getSelectedProperty.commune == 'SAN BERNARDO' || getSelectedProperty.commune == 'MAIPU' || getSelectedProperty.commune == 'MAIPÚ' || getSelectedProperty.commune == 'PUENTE ALTO' || getSelectedProperty.commune == 'MACUL' || getSelectedProperty.commune == 'MACÚL' || getSelectedProperty.commune == 'LA CISTERNA' || getSelectedProperty.commune == 'INDEPENDENCIA' || getSelectedProperty.commune == 'ESTACION CENTRAL' || getSelectedProperty.commune == 'ESTACIÓN CENTRAL' || getSelectedProperty.commune == 'COLINA' || getSelectedProperty.commune == 'RECOLETA' || getSelectedProperty.commune == 'CONCHALÍ' || getSelectedProperty.commune == 'CONCHALI' || getSelectedProperty.commune == 'QUILICURA' || getSelectedProperty.commune == 'PUDAHUEL' || getSelectedProperty.commune == 'SAN MIGUEL' || getSelectedProperty.commune == 'BUIN' || getSelectedProperty.commune == 'CERRILLOS' || getSelectedProperty.commune == 'EL BOSQUE' || getSelectedProperty.commune == 'CERRO NAVIA' || getSelectedProperty.commune == 'LAMPA' || getSelectedProperty.commune == 'RENCA')"
      title="Valorización de la propiedad"
      subtitle="Compara el valor real de tu propiedad respecto a propiedades similares en el mismo barrio (actualmente solo disponible para propiedades habitacionales de la RM). RECUERDA QUE CADA VEZ QUE VALORICES, SE GASTA UN CREDITO, POR LO QUE UNA VEZ OBTENIDA LA VALORIZACION, DEBES PRESIONAR EL BOTON GENERAR INFORME PARA QUE SIEMPRE PUEDAS ACCEDER A ESTA VALORIZACION EN TUS DOCUMENTOS SIN TENER QUE GASTAR CREDITOS REVALORIZANDO."
      :button="{ caption: 'Valoriza la propiedad', variant: 'secondary' }"
      icon="chart-line"
    />
    <short-cuts
      title="Documentos de la propiedad"
      subtitle="Respalda y ordena todos los documentos de tu propiedad. Además de subir documentos de la propiedad, puedes encontrar los informes y certificados generados."
      :button="{
        caption: 'Ver documentos',
        pathName: 'FolderDocuments',
        unit: unit,
        variant: 'success',
      }"
      icon="folder"
      disabled
    />
    <!--
    <short-cuts
      title="Solicitud de documentos"
      subtitle="Solicita todo tipo de certificados: Municipales, CBRS, entre otros"
      :button="{
        caption: 'Solicitar documentos',
        pathName: 'CreateRequest',
        variant: 'primary',
      }"
      icon="file-import"
    />
    -->
    <b-row>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button v-b-modal="'modal-delete'" variant="outline-danger" size="lg"
          >Eliminar propiedad</b-button
        >
      </b-col>
    </b-row>
    <b-modal
      id="modal-edit"
      title="Editar nombre de propiedad"
      title-class="text-center"
      footer-border-variant="white"
      @ok="handleOk"
    >
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group class="mb-0">
          <b-input
            v-model="input_update_property"
            :value="unit"
            size="lg"
          ></b-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ cancel, ok }">
        <b-button variant="outline-primary" size="lg" @click="cancel()">
          Cancelar
        </b-button>
        <b-button variant="primary" size="lg" @click="updateProperty">
          Guardar
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-delete"
      title="Eliminar propiedad"
      title-class="text-center"
      footer-class="justify-content-center"
      footer-border-variant="white"
      body-class="text-center px-5"
    >
      <p>
        ¿Estás seguro que deseas eliminar la propiedad <b>{{ unit }}</b
        >?
      </p>
      <p class="mb-0">
        Esta propiedad se eliminará de tu listado de propiedades junto con todos
        sus documentos.
      </p>
      <template #modal-footer="{ cancel, ok }">
        <b-button variant="outline-primary" size="lg" @click="cancel()">
          Cancelar
        </b-button>
        <b-button variant="primary" size="lg" @click="deleteProperty">
          Eliminar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TitleBack from "@/components/misc/TitleBack.vue";
import InfoUnit from "@/components/cards/InfoUnit.vue";
import ShortCuts from "@/components/cards/ShortCuts.vue";
import propertyService from "@/services/property.service";
import login from "@/services/login.service";
import { mapGetters } from "vuex";

import reportService from "@/services/report.service";

export default {
  name: "PropertyDetail",
  components: { TitleBack, InfoUnit, ShortCuts },
  props: ["unit", "back"],
  data() {
    return {
      requests: [
        {
          title: "Cert. Matrimonio Propietario",
          subtitle: "RUT 16.208.888-2",
          state: "Recolectado",
          date: "Solicitado el 01-03-2022",
        },
        {
          title: "Certificado de número domiciliario",
          subtitle: "ROL 3397 - 24",
          state: "Faltan 2 días",
          date: "Solicitado el 01-03-2022",
        },
      ],
      isAdmin: true,
      input_update_property: this.unit,
      backItems: [
        {
          text: "Propiedades",
          to: "/corredor/propiedades",
          icon: true,
        },
        {
          text: this.unit,
          active: true,
        },
      ],
      isLastCertificateDone: null,
      lastCertificate: null,
      penultimateCertificate: null,
      isDoneLastCertificate: false,
      showcert: false,
      showval: false,
    };
  },
  computed: {
    ...mapGetters(["getFolders", "getSelectedProperty"]),
    hasInform() {
      return this.getSelectedProperty.inform === null ? false : true;
    },
    report() {
      return {
        caption: this.hasInform ? "Ver resumen generado" : "Generar certificados",
      };
    },
    showPenultimateCertificate() {
      return this.penultimateCertificate === null ||
        this.penultimateCertificate === undefined
        ? false
        : true;
    },
    showLastCertificate() {
      if (this.lastCertificate === null || this.lastCertificate === undefined)
        return false;
      else {
        return !this.isLastCertificateDone;
      }
    },
  },

  async beforeMount() {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    
    let mybody = {
      token: mytoken
    };
    
    try {
      let response = await propertyService.getCreditsStatus(mybody);
      console.log("response", response);
      // Si no está suscrito muestra el infocheck base sin botón de informes
      /*
      if (!response.creditCardType || response.creditCardType === 'S/I' || !response.last4CardDigits || response.last4CardDigits === 'S/I') {
        this.showcert = false; 
      }
      */
      if (response.informes_disponibles > 0 || response.disponibles_plan > 0) {
        this.showcert = true; 
      }
      // Si está suscrito muestra el infocheck base con botón de informes 
      else {
        this.showcert = false; 
      }
      console.log(this.showcert);
      /*
      if (this.getSelectedProperty.dest === 'Habitacional') {
        this.showval = true;
      } else {
        this.showval = false;
      }
      */
    } catch (error) {
      console.log(error);
      this.showcert = false; 
      this.showval = false;
    }
    console.log("this.getSelectedProperty: ");
    console.log(this.getSelectedProperty);
    this.$store.commit("setReport", {});

    const { data } = await reportService.getReportStatus(
      this.getSelectedProperty.id     
    );

    this.isLastCertificateDone = data.content?.content?.petition?.is_done;
    this.lastCertificate = data.content?.content?.lastCertificate;

    if (data.content?.content?.penultimateCertificate !== null) {
      this.penultimateCertificate =
        data.content?.content?.penultimateCertificate;
    } else {
      if (data.content?.content?.petition?.is_done) {
        this.penultimateCertificate = data.content?.content?.lastCertificate;
      }
    }
  },
  methods: {
    sendToReport() {
      this.$router.push({
        name: "Report",
        query: {
          last: true,
        },
      });
    },
    async deleteProperty() {
      const propertyId = await this.getSelectedProperty.id;
      await propertyService.deleteProperty({ property_id: propertyId });
      this.$router.push({
        path: "/corredor/propiedades",
      });
    },
    async updateProperty() {
      const propertyId = await this.getSelectedProperty.id;
      const newName = this.input_update_property;
      await propertyService.updateProperty({
        property_id: propertyId,
        name: newName,
      });
      this.$router.push({
        path: "/corredor/propiedades",
      });
    },
    handleOk() {
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.$bvToast.toast(`El cambio fue guardado con éxito.`, {
        title: "¡Listo!",
        variant: "success",
        autoHideDelay: 3000,
      });
    },
  },
};
</script>
