import axios from "axios";
import Config from "@/../config.json";
import login from "@/services/login.service";

const propertyService = {
  async continueSuscription(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_CONTINUE_SUSCRIPTION_FLOW, payload);
      return data;
    } catch (error) {
      console.log("error en continueFlowSuscription");
      console.log(error);
      return error;
      //throw error;
    }
  },
  async openFlowSuscription(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_USER_SUSCRIPTION_FLOW, payload);
      return data;
    } catch (error) {
      console.log("error en openFlowSuscription");
      console.log(error);
      return error;
      //throw error;
    }
  },
  async checkFlowSuscription(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_USER_SUSCRIPTION_FLOW_CHECK, payload);
      return data;
    } catch (error) {
      console.log("error en checkFlowSuscription");
      console.log(error);
      return error;
      //throw error;
    }
  },
  async cancelSubscription(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_USER_SUSCRIPTION_FLOW_CANCEL, payload);
      return data;
    } catch (error) {
      console.log("error en cancelSuscription");
      console.log(error);
      return error;
      //throw error;
    }
  },
  async removeCreditCard(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_USER_CREDIT_CARD_FLOW_REMOVE, payload);
      return data;
    } catch (error) {
      console.log("error en removeCreditCard");
      console.log(error);
      return error;
      //throw error;
    }
  },
  async addCreditCard(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_USER_CREDIT_CARD_FLOW_ADD, payload);
      return data;
    } catch (error) {
      console.log("error en addCreditCard");
      console.log(error);
      return error;
      //throw error;
    }
  },
  async getCreditsStatus(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_GET_USER_CREDITS_FLOW, payload);
      return data;
    } catch (error) {
      console.log("error en getCreditsFlow");
      console.log(error);
      return error;
      //throw error;
    }
  },
  async getOrderStatus(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_GET_ORDER_STATUS_FLOW, payload);
      return data;
    } catch (error) {
      console.log("error en getStatusFlowOrder");
      console.log(error);
      return error;
      //throw error;
    }
  },
  async getSuscriptionStatus(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_GET_SUSCRIPTION_STATUS_FLOW, payload);
      return data;
    } catch (error) {
      console.log("error en getStatusFlowSuscription");
      console.log(error);
      return error;
      //throw error;
    }
  },
  async openFlowOrder(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_OPEN_ONESHOT_FLOW, payload);
      return data;
    } catch (error) {
      console.log("error en openFlowOrder");
      console.log(error);
      return error;
      //throw error;
    }
  },
  async checkIndicators(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_CHECK_INDICATORS, payload);
      return data;
    } catch (error) {
      console.log("error en checkIndicators");
      console.log(error);
      return error;
      //throw error;
    }
  },

  async getIndicators(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_INDICATORS_LIST, payload);
      return data;
    } catch (error) {
      console.log("error en getIndicators");
      console.log(error);
      return error;
      //throw error;
    }
  },

  async getProperties(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    
    try {
      const { data } = await axios.post(
        Config.VUE_APP_URL_PROPERTIES_LIST,
        {
          user_id: payload,
        },
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data.properties;
    } catch (error) {
      throw error;
    }
  },
  async saveProperty(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
    
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_SAVE_PROPERTY,
        payload,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  async deleteProperty(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const data = await axios.delete(
        Config.VUE_APP_URL_DELETE_PROPERTY,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          },
          data: payload
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  async updateProperty(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_UPDATE_PROPERTY,
        payload,
        {
          headers: {
            Authorization: `Bearer ${mytoken}`
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  async updateImage(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const response = await axios.post(Config.VUE_APP_URL_UPDATE_PROPERTY_IMAGES, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },  
  async getImages(propertyId) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      const body = {
        token: '$a89fd6a$_l',
        propertyId: propertyId
      };
      console.log(body);
      const response = await axios.post(Config.VUE_APP_URL_GET_PROPERTY_IMAGES, body);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async deleteImages(propertyId) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    try {
      await axios.delete(`${Config.VUE_APP_URL_DELETE_PROPERTY_IMAGES}/${propertyId}`);
    } catch (error) {
      throw error;
    }
  },
  async rolSearch(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken); // Asegúrate de que esta función exista y esté disponible
    console.log("mytoken: " + mytoken);
  
    const url = `${Config.VUE_APP_URL_GET_PROPERTY_BY_ADDRESS}?region=${payload.region}&comuna=${payload.comuna}&street=${payload.street}&number=${payload.number}&department_number=${payload.department_number}`;
    
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${mytoken}`
        }
      });
      return data;
    } catch (error) {
      throw error;
    }
  },

  async infoSearch(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    const url = `${Config.VUE_APP_URL_GET_PROPERTY_INFO_BY_ROLE}`;
    try {
      const { data } = await axios.post(url, payload);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async rolSearchByCoords(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    const url = `${Config.VUE_APP_URL_GET_PROPERTY_BY_COORDS}`;
    try {
      const { data } = await axios.post(url, payload);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async rolSearchByAddr(payload) {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    const url = `${Config.VUE_APP_URL_GET_PROPERTY_BY_DIRE}`;
    try {
      const { data } = await axios.post(url, payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
export default propertyService;
