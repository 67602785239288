import reportService from "@/services/report.service";

export default {
  state: {
    report: {},
    reportType: {},
    isLoading: false,
    error: null,
  },
  actions: {
    async fetchRequestReport(_, { id, user_id, region, comuna, rol }) {
      const { data } = await reportService.requestReport(
        user_id,
        id,
        region,
        comuna,
        rol
      );

     
    },
    async fetchReportStatus({ commit }, { id }) {
      const { data } = await reportService.getReportStatus(id);

      commit("setReportStatus", data);
    },
    //Fetch Data on scrappers and save it on DB
    async fetchReport(
      { commit, dispatch, getters },
      { id, region, commune, role, user_id }
    ) {
      let type = getters.getReportType;
      const formattedPayload = {
        property_id: id,
        region,
        commune,
        role,
        type,
        user_id,
      };
      const { data } = await reportService.getReport(formattedPayload);
      if (type === "premium" || type === "full") {
        await dispatch("pageNumberYear");
      }
      commit("setReport", data);
    },

    //Fetch data stored on DB
    async fetchStoredReport({ commit }, payload) {
      const formatedPayload = {
        property_id: payload,
      };
      const { data } = await reportService.getStoredReport(formatedPayload);
      commit("setReport", data.data);
    },
  },
  mutations: {
    setLoading(state) {
      state.isLoading = !state.isLoading;
    },
    setReport(state, payload) {
      state.report = {};
      state.report = payload;
    },
    setReportType(state, payload) {
      state.reportType = payload;
    },
    setReportStatus(state, payload) {
      state.reportStatus = payload;
    },
  },
  getters: {
    loading(state) {
      return state.isLoading;
    },
    getReport(state) {
      return state.report;
    },
    getReportType(state) {
      return state.reportType;
    },
    getReportStatus(state) {
      return state.reportStatus;
    },
  },
};
