<template>
  <div style="width:50%">
  <stepper-vertical></stepper-vertical>
  <!-- <b-button v-b-modal="'modal-asign-scrapper'">Modal</b-button>
  <modal-asign-scrapper></modal-asign-scrapper> -->
  </div>
</template>

<script> 
// import ModalAsignScrapper from '@/components/modals/ModalAsignScrapper.vue';
import StepperVertical from '@/components/cards/StepperVertical.vue';

export default {
  name: "TestView",
  components: {
    // ModalAsignScrapper 

    StepperVertical
   },
  
};
</script>
