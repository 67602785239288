import axios from "axios";

const url = "https://apis.digital.gob.cl/dpa";

export default {
  state: {
    regions: [],
    idRegion: null,
    commune: [],  
  },
  mutations: {
    setRegions(state, regions) {
      state.regions = regions;
    },
    setCommunes(state, commune) {
      state.commune = commune;
    },


  },
  actions: {
    async getRegions({ commit }) {
      const response = await axios.get(url + "/regiones");
      commit("setRegions", response.data);
    },
   
    async getCommunes({ commit }, id) {
      axios
        .get(url + "/regiones/" + id + "/comunas")
        .then((response) => {
          commit("setCommunes", response.data);
        });
    },
  },
  getters: {
    getterRegions(state) {
      const regions = state.regions.map((item) => {
        return {
          value: item.codigo,
          text: item.nombre,
        };
      });
      return regions;
    },
    getterCommunes(state) {
      const communes = state.commune.map((item) => {
        return {
          value: item.codigo,
          text: item.nombre,
        };
      });
      return communes;
    },
    getRegionId(state) {
      return state.idRegion;
    },
  },
};
