<template>
  <b-card class="mb-3">
    <b-row>
      <b-col md="8">
        <h6>{{ name }}</h6>
        <p class="mb-0 text-muted">{{ commune }}, {{ region }}</p>
      </b-col>
      <b-col md="4" class="text-right">
        <h6>Rol {{ role }}</h6>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "CardInfoProperty",
  props: {
    name: {
      type: String,
      default: ''
    },
    region: {
      type: String,
      default: ''
    },
    commune: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    }
  }
}
</script>