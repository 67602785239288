<template>
  <div>
    <DocumentsSuccessfullyRequested v-if="showConfirmationAfterPayment" />
    <PaymentRejected v-if="showPaymentRejected" />
    <TimeoutTransaction v-if="showTimeoutTransaction" />
    <TransactionCanceledByUser v-if="showTransactionCanceledByUser" />
  </div>
</template>

<script>
import DocumentsSuccessfullyRequested from "@/views/misc/DocumentsSuccessfullyRequested.vue";
import PaymentRejected from "@/views/misc/PaymentRejected.vue";
import TimeoutTransaction from "@/views/misc/TimeoutTransaction.vue";
import TransactionCanceledByUser from "@/views/misc/TransactionCanceledByUser.vue";

import { mapGetters } from "vuex";

import axios from "axios";

import Config from "@/../config.json";
import requestService from "@/services/request.service";

export default {
  name: "CompletedDocumentsRequest",
  components: {
    DocumentsSuccessfullyRequested,
    PaymentRejected,
    TimeoutTransaction,
    TransactionCanceledByUser,
  },
  data() {
    return {
      showConfirmationAfterPayment: false,
      showPaymentRejected: false,
      showTimeoutTransaction: false,
      showTransactionCanceledByUser: false,
      showCertificateRequestedAfterPayment: false,
    };
  },

  computed: {
    ...mapGetters(["getSelectedProperty", "getUserData"]),
  },

  async mounted() {
    const token = this.$route.query.token_ws;
    const TBK_TOKEN = this.$route.query.TBK_TOKEN;
    const TBK_ID_SESION = this.$route.query.TBK_ID_SESION;
    const TBK_ORDEN_COMPRA = this.$route.query.TBK_ID_SESION;
    const validation = this.$route.query.validation;

    if (token !== undefined || validation === "skipped") {
      const response = await axios({
        url: `${Config.VUE_APP_URL_BASE_TRANSACTION}${token}/commit`,
        method: "POST",
      });

      const AUTHORIZED = "AUTHORIZED";
      const FAILED = "FAILED";
      const errorName = "FAILED";

      if (
        response.data.transaction.status === AUTHORIZED ||
        validation === "skipped"
      ) {
        let documentsRequest = JSON.parse(
          localStorage.getItem("documentsRequest")
        );

        if (documentsRequest.documentsList.length === 0) {
          return;
        }

        const formattedPayload = {
          property_id: documentsRequest.property.id,
          user_id: documentsRequest.user.id,
          document_id: documentsRequest.documentsList.map((doc) => doc.id),
          total_price: documentsRequest.totalPrice,
        };

        const responseRequest = await requestService.registerRequest(
          formattedPayload
        );

        if (responseRequest.data?.registerRequestDocument?.success) {
          this.showConfirmationAfterPayment = true;
        }

        this.cleanStorage();

        return;
      }

      if (
        response.data.transaction.status === FAILED ||
        response.data.transaction.error.name === errorName ||
        response.data.transaction.success === false
      ) {
        this.showPaymentRejected = true;
      }
    }

    if (token === undefined && TBK_TOKEN !== undefined) {
      this.showTransactionCanceledByUser = true;
    }

    if (
      token === undefined &&
      TBK_TOKEN === undefined &&
      TBK_ID_SESION !== undefined &&
      TBK_ORDEN_COMPRA !== undefined
    ) {
      this.showTimeoutTransaction = true;
    }
  },
  beforeDestroy() {
    this.cleanStorage();
  },
  methods: {
    cleanStorage() {
      localStorage.setItem(
        "documentsRequest",
        JSON.stringify({
          documentsList: [],
          totalPrice: 0,
          property: null,
          user: null,
        })
      );
    },
  },
};
</script>
