<template>
  <b-list-group-item
    button
    @click="
      showRequest()
    "
  >
    <b-row>
      <b-col md="4">
        <h6 class="mb-2 mb-md-0">{{ name }}</h6>
      </b-col>
      <b-col md="4" class="text-left text-md-center">
        <p class="text-muted mb-2 mb-md-0">Solicitado el {{ date }}</p>
      </b-col>
      <b-col md="4" class="text-left text-md-right">
        <b-badge v-if="!isFinish" pill>{{ readyDocuments }} / {{ totalDocuments }} documentos</b-badge>
        <b-badge v-else variant="success" pill>Finalizado</b-badge>
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
export default {
  name: "CardRequest",
  props: { 
    id: { type: Number, required: true },
    name: { type: String, required: true },
    date: { type: String, required: true },
    readyDocuments: { type: Number, required: true },
    totalDocuments: { type: Number, required: true },
    status: { type: String, required: true },
    property: { type: Object, required: true },
    payment: { type: Object, required: true },
    documents: { type: Array, required: true },
  },
  computed: {
    isFinish() {
      return this.status === "finalizado" ? true : false;
    }
  },
  methods: {
    showRequest() {
      this.$store.commit("setSelectedRequest", {
        id: this.id,
        name: this.name,
        date: this.date, 
        status: this.status,
        payment: this.payment,  
        totalDocuments: this.totalDocuments,
        property: this.property,
        documents: this.documents, 
      });
      this.$router.push({
        name: "RequestDetail",
        // params: { unit: this.name },
      });
    },
  }
};
</script>
