<template>
  <b-form @submit="submitForm">
    <h2 class="mb-2">Ingresa un nombre</h2>
    <p class="text-muted">
      Para empezar, crea un nombre que te ayude a identificar esta propiedad.
    </p>
    <b-card class="mb-3">
      <div class="text-left m-sm-3">
        <b-form-group
          id="input-group-1"
          label="Nombre"
          label-for="input-1"
          class="font-weight-bold"
        >
          <b-form-input
            id="input-1"
            type="text"
            :value="value"
            placeholder="Ej: Mi casa, Mi Hogar ..."
            required
          ></b-form-input>
        </b-form-group>
      </div>
    </b-card>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button size="lg" class="mb-3" variant="primary" type="submit">
          Siguiente
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
export default {
  name: "FormPropertyName",
  components: {},
  props: {
    name: String,
  },
  data() {
    return {
      value: this.name,
    };
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      this.$emit("setName", event);
      localStorage.setItem("propertyName", this.value);
      this.$store.commit("incrementStep");
    },
  },
};
</script>
