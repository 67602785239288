export default {
    state: {
        step: 2,
        isFinalize: false,
        selectedTab: 1,
        comune: '',
        role: '',
        region: '',
        name: ''
    },
    actions: {

    },
    mutations: {
        incrementStep(state) {
            state.step++;
        },
        decrementStep(state) {
            state.step--;
        },
        restoreStep(state) {
            state.step = 2;
        },
        isFinalStep(state) {
            state.isFinalize = true;  
        },
        restoreFinalStep(state) {
            state.isFinalize = false;
        },
        setStep(state, payload) {
            state.step = payload;
        }
        ,
        setSelectedTab(state, payload) {
            state.selectedTab = payload;
            console.log(state.selectedTab);
        },
        getSelectedTab(state) {
            console.log(state.selectedTab);
            return state.selectedTab;
        },
        setComune(state, payload) {
            state.comune = payload;
        }
        ,
        setRole(state, payload) {
            state.role = payload;
        }
        ,
        setRegion(state, payload) {
            state.region = payload;
        }
        ,
        setName(state, payload) {
            state.name = payload;
        }
    },
    getters: {
        getStep(state) {
            return state.step;
        },
        getFinalStep(state) {
            return state.isFinalize;
        },
        getComune(state) {
            return state.comune;
        },
        getRole(state) {
            return state.role;
        },
        getRegion(state) {
            return state.region;
        },
        getName(state) {
            return state.name;
        }
        

    },
};