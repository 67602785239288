<template>
  <b-form @submit="submitForm">
    <h2 class="mb-2">Confirma tu compra</h2>
    <p class="text-muted">
      Ahora confirma que tanto la información de la inscripción, el producto que
      estás comprando y el método de pago sean correctos.
    </p>

    <b-card body-class="p-4" class="mb-3">
      <h5 class="mb-4">Informe {{ report.title }}</h5>
      <ul v-for="content in report.options" class="check-list-custom ml-3">
        <li class="mb-2">{{ content.content }}</li>
      </ul>
      <h6 class="mb-4">Método de pago: Transbank</h6>
      <hr />
      <div class="d-flex justify-content-between">
        <h4 class="mb-0">Total</h4>
        <h4 class="mb-0">$ {{ report.price }}</h4>
      </div>
    </b-card>
    <b-row>
      <b-col class="d-flex justify-content-between">
        <b-button
          size="lg"
          class="mb-3"
          variant="outline-primary"
          @click="reduceStep()"
        >
          Volver
        </b-button>
        <b-button size="lg" class="mb-3" variant="primary" type="submit">
          Continuar
        </b-button>
      </b-col>
    </b-row>
    <form id="myForm" method="post" :action="url">
      <input type="hidden" name="token_ws" :value="token" />
    </form>
  </b-form>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import Config from "@/../config.json";

export default {
  name: "FormConfirmation",
  data() {
    return {
      items: [
        {
          report: "basic",
          title: "Básico",
          variantPill: "primary",
          options: [
            { content: "Propietarios" },
            { content: "Información Municipal" },
            { content: "Avalúos y Contribuciones" },
          ],
        },
        {
          report: "premium",
          title: "Premium",
          variantPill: "warning",
          options: [
            { content: "Propietarios" },
            { content: "Información Municipal" },
            { content: "Avalúos y Contribuciones" },
            { content: "Historial de transferencias" },
            { content: "Precios de Transferencias" },
            { content: "Inscripciones de dominio" },
          ],
          price: "10000",
        },
        {
          report: "full",
          title: "Full",
          variantPill: "success",
          options: [
            { content: "Propietarios" },
            { content: "Información Municipal" },
            { content: "Avalúos y Contribuciones" },
            { content: "Historial de transferencias" },
            { content: "Precios de Transferencias" },
            { content: "Inscripciones de dominio" },
            { content: "GP - Hipotecas" },
          ],
          price: "30000",
        },
      ],
      token: "",
      url: "",
    };
  },
  computed: {
    ...mapGetters([
      "getPageNumberYear",
      "getSelectedProperty",
      "getReportType",
    ]),
    report() {
      return this.items.find((report) => report.report === this.getReportType);
    },
  },
  methods: {
    tbk(amount) {
      return new Promise((resolve, reject) => {
        axios({
          url: Config.VUE_APP_URL_BASE_TRANSACTION,
          method: "POST",
          data: {
            amount: amount,
            returnUrl:
              "https://front.dev.iprop.cl/corredor/propiedades/informe?generated=false",
          },
        })
          .then((response) => {
            resolve(response);
            this.url = response.data.transaction.url;
            this.token = response.data.transaction.token;

            setTimeout(() => {
              document.getElementById("myForm").submit();
            }, 50);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async submitForm(event) {
      event.preventDefault();

      this.$router.push({
        path: "/corredor/propiedades/informe",
        query: {
          generated: false,
          // token_ws:
          //   "11317698421f1db9fbd9ffaf8966f05aaf5d6739b781be53f95211da83f30392",
        }, // compra rechazada
        // query: {
        //   generated: false,
        //   TBK_ID_SESION: "some-fake-value",
        //   TBK_ORDEN_COMPRA: "another-fake-value",
        // }, // timeout
        // query: {
        //   generated: false,
        //   TBK_TOKEN: "some-value-different-from-undefined",
        // }, // compra cancelada por el usuario
      });

      setTimeout(() => {
        this.$store.commit("isFinalStep");
      }, 50);
    },
    reduceStep() {
      this.$store.commit("decrementStep");
    },
  },
};
</script>
