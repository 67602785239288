<template>
  <b-modal
    id="modal-share"
    title="Compartir documentos"
    header-class="justify-content-center"
    footer-border-variant="white"
    hide-footer
    centered
    size="md"
  >
    <b-row>
      <b-col>
        <p class="text-muted">Copia y pega este link para compartir los documentos de tu propiedad.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="9" class="mb-2 mb-lg-0">
        <a class="text-break" :href="shareLink" target="_blank" rel="noreferrer">{{
          shareLink
        }}</a>
      </b-col>
      <b-col cols="12" md="3">
        <b-button size="lg" variant="secondary" block @click="copy()">
          Copiar
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Config from "@/../config.json";

export default {
  name: "ModalShare",
  data() {
    return {
      shareLink: "Enlace",
      isActive: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedProperty"]),
  },
  mounted() {
    this.fetchShareUuid();
    /*
    axios
      .post(Config.VUE_APP_URL_BASE + "/bff/v1/iproperty-bff/obtainShareUuid", {
        property_id: this.getSelectedProperty.id,
      })
      .then(({ data }) => {
        const uuid = data?.content?.content?.uuid;
        this.shareLink = Config.VUE_APP_URL_BASE + `/compartido/${uuid}`;
      });
    */
  },
  methods: {
    async fetchShareUuid() {
      try {
        const response = await axios.post(Config.VUE_APP_URL_BASE + "/bff/v1/iproperty-bff/obtainShareUuid", {
          property_id: this.getSelectedProperty.id,
        });
        const uuid = response.data?.content?.content?.uuid;
        this.shareLink = Config.VUE_APP_URL_BASE + `/compartido/${uuid}`;
      } catch (error) {
        if (error.response) {
          // El servidor respondió con un estado distinto de 2xx
          console.error('Error en la respuesta del servidor:', error.response.data);
        } else if (error.request) {
          // La solicitud se hizo pero no hubo respuesta
          console.error('No se recibió respuesta del servidor:', error.request);
        } else {
          // Algo pasó al configurar la solicitud
          console.error('Error en la solicitud:', error.message);
        }
      }
    },
    async copy() {
      try {
        await navigator.clipboard.writeText(this.shareLink);
        this.$bvToast.toast(`Enlace copiado en tu portapapeles`, {
          title: "¡Copiado!",
          variant: "success",
          autoHideDelay: 3000,
        });
      } catch (error) {
        this.$bvToast.toast("No pudimos copiar el enlace.", {
          title: "¡Hubo un problema!",
          solid: true,
          variant: "danger",
        });
      }
    },
  },
};
</script>
