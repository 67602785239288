<template>
  <b-modal
    id="modal-new-document"
    ref="modal-new-document"
    header-class="justify-content-center"
    hide-footer
    centered
    body-class="px-5"
  >
    <template #modal-header>
      <div class="text-center w-100 p-0">
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="hideModal"
        >
          ×
        </button>
        <h5>Nuevo documento</h5>
      </div>
    </template>

    <b-row align-h="center" class="my-2">
      <b-col cols="11" class="mb-1 pr-md-1"
        ><b-form-group id="input-group-1" label="Nombre documento" label-for="input-1" class="font-weight-bold">
          <b-form-input
            id="input-1"
            v-model="form.name"
            placeholder="Ej: Certificado Matrimonio"
            required
            size="lg"
          ></b-form-input></b-form-group
      ></b-col
      >
      <b-col cols="11" class="mb-1 pr-md-1"
        ><b-form-group id="input-group-2" label="Valor documento" label-for="input-2" class="font-weight-bold">
          <b-form-input
            id="input-2"
            v-model="form.value"
            placeholder="Ej: $ 9.990"
            required
            size="lg"
          ></b-form-input></b-form-group
      ></b-col
      >
      <b-col cols="11" class="mb-2 pr-md-1"
        ><b-form-group id="input-group-3" label="Tiempo de entrega" label-for="input-3" class="font-weight-bold">
          <b-form-input
            id="input-3"
            v-model="form.deliveryTime"
            placeholder="Ej: 4 días"
            required
            size="lg"
          ></b-form-input></b-form-group
      ></b-col
      >  
    </b-row>
    <b-row align-h="end" class="mb-4">
      <b-col cols="4" class="pr-1"
        ><b-button block size="lg" variant="outline-primary" @click="hideModal"
          >Cancelar</b-button
        ></b-col
      > 
      <b-col cols="4" class="pl-1"
        ><b-button block size="lg" variant="primary" @click="add"
          >Crear</b-button
        ></b-col
      > 
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "ModalNewDocument",
  data() {
    return {
      form: {
        name: "",
        value: null,
        deliveryTime: null, 
      }, 
    };
  },
  methods: {
    showToast() {
        const h = this.$createElement
        this.count++
        const vNodesMsg = h(
          'p',
          { class: ['text-left', 'mb-0'] },
          [
            // h('b-spinner', { props: { type: 'grow', small: true } }),
            ' El documento fue creado con éxito. ',
            // h('strong', 'toast'),
            // ` message #${this.count} `,
            // h('b-spinner', { props: { type: 'grow', small: true } })
          ]
        ) 

        this.$bvToast.toast([vNodesMsg], {
          title: "¡Listo!",
          solid: true,
          variant: 'success'
        })
      }
    ,
    hideModal() {
      this.$refs["modal-new-document"].hide();
    },
    add() {
      this.$refs["modal-new-document"].hide();
      this.showToast();
    },
  },
};
</script>

<style></style>
