<template>
  <b-container>
    <b-row class="mt-4">
      <b-col>
        <h3 class="mt-2">Documentos de {{ propertyName }}</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-tabs v-model="tabIndex" class="nav-tab-custom" content-class="mt-3">
          <b-tab
            v-for="(folder, indexTab) in folders"
            :key="indexTab"
            :title="folder.title"
            lazy
          >
            <b-list-group class="mb-4">
              <share-document-item
                v-for="(document, indexItem) in filteredDocuments"
                :id="String(document.id)"
                :key="indexItem"
                :name="document.name"
                :source="document.source"
                :date="document.createAt"
                :type="document.type"
              />
              <b-card v-if="filteredDocuments.length === 0">
                <img
                  :src="require(`../../assets/svg/empty.svg`)"
                  alt="Empty state"
                  class="my-4 mx-auto d-block"
                />
                <p class="text-center text-muted">
                  No hay documentos para mostrar
                </p>
              </b-card>
            </b-list-group>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import shareDocumentItem from "@/components/cards/shareDocumentItem.vue";

export default {
  name: "ShareDocument",
  components: { shareDocumentItem },
  data() {
    return {
      documents: [],
      folders: [
        { title: "Propietarios" },
        { title: "Legales" },
        { title: "Comerciales" },
        { title: "Postventa" },
        { title: "Informes Generados" },
        { title: "Otros" },
      ],
      fields: [
        { key: "name", label: "Nombre" },
        { key: "date", label: "Subido el" },
        {
          key: "options",
          label: "",
          thClass: "text-right",
          tdClass: "text-right",
        },
      ],
      items: [
        {
          name: "Certificado Municipal",
          date: "15-08-2022",
        },
        {
          name: "Certificado Municipal",
          date: "15-08-2022",
        },
        {
          name: "Certificado Municipal",
          date: "15-08-2022",
        },
        {
          name: "Certificado Municipal",
          date: "15-08-2022",
        },
      ],
      tabIndex: 0,
      propertyName: "prueba",
    };
  },
  computed: {
    filteredDocuments() {
      return Object.values(this.documents).filter(
        (doc) =>
          doc.folders[0] ===
          this.folders[this.tabIndex].title.toLowerCase().split(" ")[0]
      );
    },
  },
  async mounted() {
    const { data } = await axios.post(
      "https://bff.dev.iprop.cl/bff/v1/iproperty-bff/obtainPropertyByUuid",
      {
        uuid: this.$route.params.uuid,
      }
    );
    this.propertyName = data.content.content.propertyData.name;
    this.documents = data.content.content.propertyDocuments;
  },
};
</script>
